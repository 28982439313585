import React, { FC } from 'react';
import { Cell, Legend, Pie, PieChart } from 'recharts';

import { Row } from '../../types';

interface Props {
  data: Row[];
}

const LoginMethodsChart: FC<Props> = ({ data }) => {
  if (!data) {
    return null;
  }

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <PieChart height={240} width={200}>
      <Pie cx={100} cy={100} data={data} dataKey="value" fill="#8884d8" innerRadius={60} outerRadius={80} paddingAngle={5}>
        {data.map((_, index: number) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend height={36} verticalAlign="bottom" />
    </PieChart>
  );
};

export default LoginMethodsChart;
