import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Popover, Typography } from 'antd';
import React, { FC, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../Logo';
import MainNavigation from '../MainNavigation';
import MobileMenu from '../MobileMenu';
import Search from '../Search';
import UserMenu from '../UserMenu';
import styles from './styles.module.less';

const Header: FC = () => {
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);

  const faqContent: ReactNode = (
    <div>
      Watch the Getting <br /> Started Video and <br />
      read FAQs
    </div>
  );

  return (
    <div className={styles.header}>
      <MobileMenu />
      <Link className={styles.logo} to="/">
        <Logo size="small" />
      </Link>
      <MainNavigation className={styles.mainNav} />
      {isSearchVisible && <Search className={styles.search} />}
      <div className={styles.userMenu}>
        <Typography.Link
          className={`${styles.searchButton} ${styles.button}`}
          onClick={() => setIsSearchVisible(!isSearchVisible)}
        >
          <SearchOutlined />
        </Typography.Link>
        <Popover content={faqContent} placement="bottomLeft" trigger="hover">
          <Link className={styles.button} to="/faq">
            <QuestionCircleOutlined />
          </Link>
        </Popover>
        <UserMenu />
      </div>
    </div>
  );
};

export default Header;
