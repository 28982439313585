import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/interface';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

import { LanguageEnum } from '@okapi/graphql/types.generated';

import { Book } from '../../BookDetails/types';

interface Props {
  book: Book;
}

const PairedTextsDropdown: FC<Props> = ({ book }) => {
  const { language, pairedText1, pairedText2, textType } = book;

  const isEnglishBook: boolean = language?.key === LanguageEnum.ENGLISH;

  const items: ItemType[] = [
    { key: 'first-perspective', label: <Link to={`/books/${pairedText1?.id}`}>{pairedText1?.title}</Link> },
    { key: 'second-perspective', label: <Link to={`/books/${pairedText2?.id}`}>{pairedText2?.title}</Link> }
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Typography.Link>
        {(textType === 'Perspectives' || textType === 'Perspectivas') && <>{isEnglishBook ? 'Perspectives' : 'Perspectivas'} </>}
        Paired Texts <DownOutlined />
      </Typography.Link>
    </Dropdown>
  );
};

export default PairedTextsDropdown;
