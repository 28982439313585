import { Button, Col, Form, Input, Row } from 'antd';
import React, { FC, useState } from 'react';

import { FormButtons } from '@okapi/components/Form';
import { User } from '@okapi/graphql/types.generated';

import { Role } from '../types';
import styles from './styles.module.less';
import { validationRules } from './validationRules';

interface Props {
  initialValues?: User;
  onCancel: () => void;
  onSubmit: (values: User) => void;
  role: Role;
}

const UserForm: FC<Props> = ({ initialValues, onCancel, onSubmit, role }) => {
  const [loginPath, setLoginPath] = useState<string>(initialValues?.loginPath || '');

  return (
    <Form initialValues={initialValues} layout="vertical" onFinish={onSubmit} validateTrigger="onBlur">
      <Form.Item hidden={true} name="id" noStyle={true}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item hidden={true} name={['assignedRole', 'role']} noStyle={true}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item hidden={true} name={['assignedRole', 'unitId']} noStyle={true}>
        <Input type="hidden" />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="First name" name="firstName" rules={validationRules.firstName}>
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Last name" name="lastName" rules={validationRules.lastName}>
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Email address" name="email" rules={validationRules.email}>
        <Input disabled={!!initialValues} size="large" />
      </Form.Item>
      {role === Role.TEACHER && (
        <>
          <div className={styles.wrapper}>
            <Form.Item label="Login path" name="loginPath">
              <Input onChange={(e) => setLoginPath(e.target.value)} size="large" />
            </Form.Item>
            {loginPath && <div className={styles.hint}>Login url: https://okapi-digital-literacy.com/login/{loginPath}</div>}
          </div>
          <Form.Item label="School Building" name="schoolBuilding">
            <Input size="large" />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Classroom" name="classroom">
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Grade Level" name="gradeLevel">
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <FormButtons>
        <Button onClick={onCancel}>Cancel</Button>
      </FormButtons>
    </Form>
  );
};

export default UserForm;
