import { Form, Input, message } from 'antd';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import Alert from '@okapi/components/Alert';
import { FormButtons, FormHeadline } from '@okapi/components/Form';

import AuthWrapper from '../components/AuthWrapper';
import { useResetPasswordMutation } from '../graphql';
import { validationRules } from './validationRules';

const ForgotPassword: FC = () => {
  const [form] = Form.useForm();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [resetPassword] = useResetPasswordMutation();

  const handleSubmit = async ({ email }: { email: string }) => {
    setShowSuccessMessage(false);

    try {
      await resetPassword({ variables: { email } });
    } catch (err) {
      message.error('Something went wrong');
    }
    setShowSuccessMessage(true);

    // reset field after submitting the form
    form.resetFields();
  };

  return (
    <AuthWrapper>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <Form form={form} onFinish={handleSubmit}>
        {showSuccessMessage && (
          <Alert
            message="If there is an account associated with this email address, we will send an email with a link to reset password."
            type="success"
          />
        )}
        <FormHeadline
          tagline="Don't worry. Recovering the password is easy. Just tell us the email you have registered with us."
          text="Forgot your password ?"
        />
        <Form.Item name="email" rules={validationRules.email} validateTrigger="onBlur">
          <Input placeholder="Email" size="large" />
        </Form.Item>
        <FormButtons>
          <Link to="/login">Back to login</Link>
        </FormButtons>
      </Form>
    </AuthWrapper>
  );
};

export default ForgotPassword;
