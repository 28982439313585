import { MenuOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { FC, useState } from 'react';

import { Book } from '../../BookDetails/types';
import RelatedLinks from '../RelatedLinks/RelatedLinks';
import styles from './styles.module.less';

interface Props {
  book: Book;
  onShareButtonClick: (bookId: string, resourceType: 'book' | 'video') => void;
}

const RelatedLinksMenu: FC<Props> = ({ book, onShareButtonClick }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const showDrawer = (): void => {
    setVisible(true);
  };
  const closeDrawer = (): void => {
    setVisible(false);
  };

  return (
    <>
      <MenuOutlined className={styles.menu} data-testid="menu-icon" onClick={showDrawer} />
      <Drawer onClose={closeDrawer} open={visible} placement="right" title="Links">
        <div className={styles.links}>
          <RelatedLinks book={book} onShareButtonClick={onShareButtonClick} />
        </div>
      </Drawer>
    </>
  );
};

export default RelatedLinksMenu;
