import { ValidationRules } from '@okapi/types';

export const validationRules: ValidationRules = {
  name: [
    {
      required: true,
      message: 'Please enter name'
    }
  ],
  email: [
    {
      type: 'email',
      required: true,
      message: 'Please enter valid email address'
    }
  ],
  subject: [
    {
      required: true,
      message: 'Please enter subject'
    }
  ],
  description: [
    {
      required: true,
      message: 'Please enter description'
    }
  ]
};
