import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import React, { FC, useState } from 'react';

import MainNavigation from '../MainNavigation';
import styles from './styles.module.less';

const MobileMenu: FC = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);

  const toggleDrawer = () => setIsDrawerVisible(!isDrawerVisible);

  return (
    <>
      <Button className={styles.button} onClick={toggleDrawer}>
        <MenuOutlined />
      </Button>
      <Drawer classNames={{ body: styles.drawerBody }} onClose={toggleDrawer} open={isDrawerVisible} placement="left">
        <MainNavigation mode="inline" onMenuItemClick={toggleDrawer} />
      </Drawer>
    </>
  );
};

export default MobileMenu;
