import { createContext } from 'react';

const initialAppContext = {
  isDemoVersion: false
};

interface AppContextType {
  isDemoVersion: boolean;
}

export const AppContext = createContext<AppContextType>(initialAppContext);
