import 'antd/dist/reset.css';

import { ConfigProvider } from 'antd';
import React, { FC, ReactElement } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useRoutes } from 'react-router-dom';

import TrackingProvider from '@okapi/components/TrackingProvider';
import GraphqlProvider from '@okapi/graphql/GraphqlProvider';

import AppProvider from './AppProvider';
import { routes } from './routes';
import { theme } from './theme';

const App: FC = () => {
  const element: ReactElement | null = useRoutes(routes);

  return (
    <ConfigProvider theme={theme}>
      <HelmetProvider>
        <AppProvider>
          <TrackingProvider>
            <GraphqlProvider>{element}</GraphqlProvider>
          </TrackingProvider>
        </AppProvider>
      </HelmetProvider>
    </ConfigProvider>
  );
};

export default App;
