import { FC, useEffect } from 'react';

interface Props {
  dependency?: unknown;
}

const ScrollToTop: FC<Props> = ({ dependency }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dependency]);

  return null;
};

export default ScrollToTop;
