import { CreateUserInput } from '@okapi/graphql/types.generated';

import { getUnitId } from '../helpers';
import { UserFormValues } from '../UserForm/types';

export const preparePayload = (values: UserFormValues, adminUnitId: string): CreateUserInput => {
  const { firstName, lastName, role, email } = values;

  return {
    assignedRole: {
      role,
      unitId: getUnitId(values, adminUnitId)
    },
    email,
    firstName,
    lastName
  };
};
