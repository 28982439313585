import { App, Modal } from 'antd';
import { get } from 'lodash';
import { FC, useState } from 'react';

import Alert from '@okapi/components/Alert';
import useAccount from '@okapi/domains/Auth/hooks/useAccount';
import { useCreateUserMutation } from '@okapi/domains/Users/graphql';
import { CreateUserInput } from '@okapi/graphql/types.generated';

import UserForm from '../UserForm';
import { UserFormValues } from '../UserForm/types';
import { preparePayload } from './helpers';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

const AddUser: FC<Props> = ({ onClose, onSuccess }) => {
  const { notification } = App.useApp();
  const { unitId: adminUnitId } = useAccount();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [createUser] = useCreateUserMutation();

  const handleSubmit = async (values: UserFormValues) => {
    const user: CreateUserInput = preparePayload(values, adminUnitId);

    try {
      setErrorMessage('');

      await createUser({ variables: { user }, refetchQueries: ['allUsers'] });

      notification.success({ message: 'Success', description: 'User has been added.' });

      onSuccess();
    } catch (err) {
      setErrorMessage(get(err, 'message', 'Something went wrong.'));
    }
  };

  return (
    <Modal data-testid="add-user" footer={null} onCancel={onClose} open={true} title="Add a new user">
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <UserForm onCancel={onClose} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default AddUser;
