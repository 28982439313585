import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AllUsersQueryVariables = GraphQLTypes.Exact<{
  page: GraphQLTypes.GetPage;
  filters: GraphQLTypes.UserFilters;
}>;

export type AllUsersQueryResult = { __typename?: 'Query' } & {
  users: { __typename?: 'PaginatedUser' } & Pick<GraphQLTypes.PaginatedUser, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          { __typename?: 'User' } & Pick<
            GraphQLTypes.User,
            'id' | 'email' | 'firstName' | 'lastName' | 'gradeLevel' | 'schoolBuilding' | 'classroom' | 'status'
          > & {
              externalId?: GraphQLTypes.Maybe<{ __typename?: 'ExternalId' } & Pick<GraphQLTypes.ExternalId, 'id' | 'system'>>;
              assignedRole: { __typename?: 'AssignedRole' } & Pick<GraphQLTypes.AssignedRole, 'role' | 'unitId'>;
            }
        >
      >;
    };
};

export const AllUsersDocument = /* #__PURE__ */ gql`
  query allUsers($page: GetPage!, $filters: UserFilters!) {
    users(page: $page, filters: $filters) {
      nodes {
        id
        email
        externalId {
          id
          system
        }
        firstName
        lastName
        gradeLevel
        schoolBuilding
        classroom
        status
        assignedRole {
          role
          unitId
        }
      }
      totalCount
    }
  }
`;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAllUsersQuery(
  baseOptions: Apollo.QueryHookOptions<AllUsersQueryResult, AllUsersQueryVariables> &
    ({ variables: AllUsersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AllUsersQueryResult, AllUsersQueryVariables>(AllUsersDocument, options);
}
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQueryResult, AllUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AllUsersQueryResult, AllUsersQueryVariables>(AllUsersDocument, options);
}
export function useAllUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AllUsersQueryResult, AllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<AllUsersQueryResult, AllUsersQueryVariables>(AllUsersDocument, options);
}
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersSuspenseQueryHookResult = ReturnType<typeof useAllUsersSuspenseQuery>;
