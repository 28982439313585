import { ValidationRules } from '../../../types';

export const validationRules: ValidationRules = {
  firstName: [
    {
      required: true,
      message: 'Please enter first name'
    },
    {
      min: 2,
      message: 'Fist name must be at least two characters'
    }
  ],
  lastName: [
    {
      min: 2,
      message: 'Last name must be at least two characters'
    },
    {
      required: true,
      message: 'Please enter last name'
    }
  ],
  email: [
    {
      type: 'email',
      required: true,
      message: 'Please enter valid email address'
    }
  ],
  role: [
    {
      required: true,
      message: 'Please select role'
    }
  ],
  districtId: [
    {
      required: true,
      message: 'Please select district'
    }
  ],
  schoolId: [
    {
      required: true,
      message: 'Please select school'
    }
  ]
};
