import React, { FC, useState } from 'react';

import Spinner from '../Spinner';
import styles from './styles.module.less';

interface Props {
  url: string;
  title: string;
}

const BookViewer: FC<Props> = ({ title, url }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleLoad = (): void => {
    setIsLoading(false);
  };

  return (
    <div className={styles.iframeWrapper}>
      {isLoading && <Spinner />}
      <iframe
        key={url}
        allowFullScreen={true}
        className={styles.iframe}
        data-testid="iframe"
        height="100%"
        onLoad={handleLoad}
        src={url}
        title={title}
        width="100%"
      />
    </div>
  );
};

export default BookViewer;
