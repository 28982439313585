import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';

const Schools: FC = () => (
  <>
    <Helmet>
      <title>Schools</title>
    </Helmet>
    <Outlet />
  </>
);

export default Schools;
