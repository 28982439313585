import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import React, { FC, ReactNode } from 'react';

import { staticConfig } from '@okapi/utils/staticConfig';

const uri = staticConfig().graphQLEndpointUrl;

const httpLink = createHttpLink({ uri });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (
    graphQLErrors &&
    graphQLErrors.some(({ message }) => message === 'Unauthorized') &&
    graphQLErrors.some(({ path }) => !path?.includes('login'))
  ) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRole');
    localStorage.removeItem('districtId');
    localStorage.removeItem('districtName');
    localStorage.removeItem('schoolId');
    localStorage.removeItem('schoolName');

    window.location.href = '/login';
  }
});

const link = from([authLink, errorLink, httpLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

interface Props {
  children: ReactNode;
}

const GraphqlProvider: FC<Props> = ({ children }) => <ApolloProvider client={client}>{children}</ApolloProvider>;

export default GraphqlProvider;
