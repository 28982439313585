import { Result, Typography } from 'antd';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

import BookViewer from '@okapi/components/BookViewer/BookViewer';
import VideoBook from '@okapi/domains/Books/BookDetails/VideoBook';
import { getBookUrl } from '@okapi/domains/Books/helpers';
import useTracking from '@okapi/useTracking';

import { useBookLinkQuery } from '../graphql';
import styles from './styles.module.less';

interface UrlParams {
  resourceType: string;
  token: string;
}

const Book: FC = () => {
  const { trackEvent } = useTracking();
  const { resourceType, token } = useParams<{ resourceType: string; token: string }>() as UrlParams;
  const { data, error } = useBookLinkQuery({ variables: { id: token } });
  const isVideoPage: boolean = resourceType === 'video';

  const book = data?.link?.book;

  useEffect(() => {
    if (book) {
      trackEvent({
        category: 'Book',
        action: 'View',
        label: book.title,
        properties: {
          bookSeries: book.series?.value
        }
      });
    }
  }, [book, trackEvent]);

  if (error) {
    return (
      <div className={styles.wrapper}>
        <Result status={404} subTitle="Ups! Sorry, We can not find the page you're looking for." title="404" />
      </div>
    );
  }

  if (!book) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{book.title}</title>
      </Helmet>
      <Typography.Title level={3}>{book.title}</Typography.Title>
      {isVideoPage && <VideoBook videoId={book.videoAsset || ''} />}
      {!isVideoPage && <BookViewer title={book.title} url={getBookUrl(book.asset || '')} />}
    </div>
  );
};

export default Book;
