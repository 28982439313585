import { Button } from 'antd';
import React, { FC, ReactNode } from 'react';

import styles from './styles.module.less';

interface Props {
  children?: ReactNode;
  isSubmitting?: boolean;
  text?: string;
}

const FormButtons: FC<Props> = (props) => {
  const { children, isSubmitting = false, text = 'Submit' } = props;

  return (
    <div className={styles.actionButtons}>
      <Button htmlType="submit" loading={isSubmitting} type="primary">
        {text}
      </Button>
      {children}
    </div>
  );
};

export default FormButtons;
