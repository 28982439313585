import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UserByPathQueryVariables = GraphQLTypes.Exact<{
  path: GraphQLTypes.Scalars['String']['input'];
}>;

export type UserByPathQueryResult = { __typename?: 'Query' } & {
  findByPath: { __typename?: 'User' } & Pick<GraphQLTypes.User, 'firstName' | 'lastName' | 'email' | 'termsAccepted'>;
};

export const UserByPathDocument = /* #__PURE__ */ gql`
  query userByPath($path: String!) {
    findByPath(path: $path) {
      firstName
      lastName
      email
      termsAccepted
    }
  }
`;

/**
 * __useUserByPathQuery__
 *
 * To run a query within a React component, call `useUserByPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByPathQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useUserByPathQuery(
  baseOptions: Apollo.QueryHookOptions<UserByPathQueryResult, UserByPathQueryVariables> &
    ({ variables: UserByPathQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UserByPathQueryResult, UserByPathQueryVariables>(UserByPathDocument, options);
}
export function useUserByPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserByPathQueryResult, UserByPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UserByPathQueryResult, UserByPathQueryVariables>(UserByPathDocument, options);
}
export function useUserByPathSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserByPathQueryResult, UserByPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UserByPathQueryResult, UserByPathQueryVariables>(UserByPathDocument, options);
}
export type UserByPathQueryHookResult = ReturnType<typeof useUserByPathQuery>;
export type UserByPathLazyQueryHookResult = ReturnType<typeof useUserByPathLazyQuery>;
export type UserByPathSuspenseQueryHookResult = ReturnType<typeof useUserByPathSuspenseQuery>;
