import { Table } from 'antd';
import React, { FC } from 'react';

import { Row } from '../../types';

interface Props {
  data: Row[];
}

const DistrictsTable: FC<Props> = ({ data }) => {
  const columns = [
    {
      title: 'District name',
      dataIndex: 'name'
    },
    {
      title: 'Count',
      dataIndex: 'value'
    }
  ];

  return <Table columns={columns} dataSource={data} pagination={false} rowKey="name" tableLayout="auto" />;
};

export default DistrictsTable;
