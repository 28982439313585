import { ValidationRules } from '../../../types';

export const validationRules: ValidationRules = {
  from: [
    {
      required: true,
      message: 'Please enter from date'
    }
  ],
  to: [
    ({ getFieldValue }) => ({
      validator: (_, value) => {
        if (getFieldValue('from') > value) {
          return Promise.reject('End date can not be earlier than the start date.');
        }

        return Promise.resolve();
      }
    }),
    {
      required: true,
      message: 'Please enter to date'
    }
  ]
};
