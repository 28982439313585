import { Checkbox, CheckboxOptionType, Form } from 'antd';
import cx from 'classnames';

import styles from './styles.module.less';

interface Props<T> {
  className?: string;
  name?: string;
  onChange?: (values: T[]) => void;
  options: CheckboxOptionType<T>[];
}

const CheckboxGroup = <T extends unknown>({ className, name, onChange, options }: Props<T>) => {
  const classNames = cx(styles.group, className);

  return (
    <Form.Item name={name} noStyle={true}>
      <Checkbox.Group className={classNames} name={name} onChange={onChange} options={options} />
    </Form.Item>
  );
};

export default CheckboxGroup;
