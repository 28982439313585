import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import NotAuthorized from '@okapi/components/NotAuthorized';
import Spinner from '@okapi/components/Spinner';
import { useUserByPathQuery } from '@okapi/domains/Users/graphql';
import { UserByPathQueryResult } from '@okapi/domains/Users/graphql/userByPath.generated';

import { useLogInMutation } from '../graphql';

const hasGwinnettCountyReferrer = (): boolean => {
  const { referrer } = window.document;

  return referrer.includes('gwinnett.k12.ga.us') || referrer.includes('myeclassgcps.com');
};

interface UrlParams {
  customerId: string;
}

const AutoLogin: FC = () => {
  const { customerId } = useParams<{ customerId: string }>() as UrlParams;
  const [hasError, setHasError] = useState<boolean>(false);
  const [logIn] = useLogInMutation();
  const navigate = useNavigate();

  const isGwinnettCounty: boolean = customerId === 'gwinnett-county';

  const handleLogin = (response: UserByPathQueryResult): void => {
    const { email } = response.findByPath;

    // THIS IS A TEMPORARY SOLUTION
    // AS SOON AS WE ONBOARD GC PROPERLY THIS WILL BE REMOVED
    if (hasGwinnettCountyReferrer()) {
      login(email || '');
    } else {
      setHasError(true);
    }
  };

  const handleError = (): void => setHasError(true);

  const { loading } = useUserByPathQuery({
    variables: { path: customerId },
    onCompleted: handleLogin,
    onError: handleError,
    skip: !isGwinnettCounty
  });

  const login = async (email: string) => {
    try {
      const { data } = await logIn({
        variables: {
          email,
          password: customerId
        }
      });

      const accessToken = data?.login?.accessToken || '';

      localStorage.setItem('accessToken', accessToken);

      navigate('/', {
        state: {
          loginMethod: 'password'
        }
      });
    } catch (err) {
      setHasError(true);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (!isGwinnettCounty || hasError) {
    return <NotAuthorized />;
  }

  return null;
};

export default AutoLogin;
