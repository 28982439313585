/* eslint-disable max-len */
import React, { FC } from 'react';

import LandingPage from '@okapi/components/LandingPage';

const EvaluationAgreement: FC = () => (
  <LandingPage title="Evaluation Agreement for Okapi Digital Literacy">
    <div>
      <p>
        OKAPI EDUCATIONAL PUBLISHING, INC. (“<strong>OKAPI</strong>”) IS WILLING TO GRANT TO YOU, AS THE INDIVIDUAL, COMPANY, OR
        OTHER LEGAL ENTITY THAT WILL USE THE OKAPI EVALUATION SERVICE IDENTIFIED ABOVE (COLLECTIVELY, “<strong>YOU</strong>” OR “
        <strong>YOUR</strong>”), THE RIGHT TO ACCESS AND USE SUCH OKAPI EVALUATION SERVICE ONLY ON THE CONDITION THAT YOU ACCEPT
        ALL TERMS OF THIS OKAPI EVALUATION AGREEMENT (“<strong>AGREEMENT</strong>”). THIS AGREEMENT IS MADE AND ENTERED INTO AS OF
        THE DATE OKAPI FIRST GIVES YOU ACCESS TO THE EVALUATION SERVICE <strong>(“EFFECTIVE DATE</strong>”). BY ACCESSING OR USING
        THE EVALUATION SERVICE, YOU CONFIRM THAT YOU HAVE READ AND ACCEPT ALL TERMS OF THIS AGREEMENT. CAPITALIZED TERMS HAVE THE
        MEANINGS INDICATED IN SECTION 8 AND ELSEWHERE IN THIS AGREEMENT.
      </p>

      <p>
        <strong>1. Grants of Rights.</strong>
      </p>

      <p>
        Subject to the terms and conditions of this Agreement, OKAPI hereby grants You a non-exclusive, non-transferable,
        non-assignable, worldwide, limited right (without the right to sublicense) to access and use the OKAPI Evaluation service
        identified above, including its associated documentation and information (collectively, the “
        <strong>Evaluation Service</strong>”), during the Agreement&nbsp; Term (defined below) solely for Your internal evaluation
        of the Evaluation Service (the “<strong>Purpose</strong>”). All rights not expressly granted to You are reserved by OKAPI
        and its licensors.
      </p>

      <p>
        <strong>2. Limitations on Use and Your Responsibilities.</strong>
      </p>

      <p>
        2.1 You may not disclose to any third party the results of any evaluation of the Evaluation Service performed by or on
        behalf of You (e.g., any monitoring of its availability, performance or functionality, or any other benchmarking) without
        the prior written approval of OKAPI (email is sufficient).
      </p>

      <p>
        2.2 You must not: (a) commercially exploit the Evaluation Service by licensing, sublicensing, selling, reselling,
        transferring, assigning or distributing it or otherwise making it available to any third party in any way; (b) modify or
        make &nbsp; any derivative works based upon the Evaluation Service; (c) reverse&nbsp; engineer the Evaluation Service; (d)
        use any information regarding the Evaluation Service to build a product or service that competes with the Evaluation
        Service (or with OKAPI’s Literacy Service); or (e) submit any PII to&nbsp; or store any PII in the Evaluation Service.
      </p>

      <p>
        2.3 You also must not access or use the Evaluation Service to: (a) send spam or otherwise duplicative or unsolicited
        messages in violation of applicable laws; (b) send or store infringing, obscene, threatening, libelous, or otherwise
        unlawful or tortious material, including material harmful to children or violative of third party privacy rights; (c) send
        or store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents
        or programs; (d) interfere with or disrupt the integrity or performance of the Evaluation Service or, if applicable, of
        the Literacy Service or the data contained therein; or (e) attempt to gain unauthorized access to the Evaluation Service,
        the Literacy Service or their related systems or networks.
      </p>

      <p>
        2.4 You are responsible for all activity occurring under Your User account. You must: (a) notify OKAPI promptly of any
        unauthorized use of any password or account or any other known or suspected breach of security with respect to the
        Evaluation Service; and (b) report to OKAPI promptly, and use reasonable efforts to promptly stop, any copying,
        distribution or other misuse of the Evaluation Service or any OKAPI Technology of which You or Your Users become aware.
      </p>

      <p>
        <strong>3. Term and Termination.</strong>
      </p>

      <p>
        The term of this Agreement (“<strong>Agreement Term</strong>”) commences on the Effective Date and ends on December 31,
        2020 , or any earlier date OKAPI chooses in its sole discretion, unless OKAPI agrees to extend Your access&nbsp; to the
        Evaluation Service (in which case the Agreement Term will terminate whenever OKAPI chooses to terminate Your access to the
        Evaluation Service). Sections 2, 3, 4, 5, 6, 7 and 8 of this Agreement will survive any expiration or termination of this
        Agreement.
      </p>

      <p>
        <strong>4. Confidentiality and Feedback.</strong>
      </p>

      <p>
        4.1 As used herein, “<strong>Confidential Information</strong>” refers to non-public financial, technical, commercial or
        other information or tangible materials concerning the business and affairs of the Party to this Agreement that discloses
        such information hereunder (each a “<strong>Disclosing Party</strong>”) to the Party that receives such information
        hereunder (each a “<strong>Receiving Party</strong>”), including, without limitation, any cost or pricing information,
        contractual terms and conditions, marketing or distribution data, and business methods or plans, which a reasonable person
        in the relevant industry should understand to be confidential based on the nature of the information and all relevant
        context. For the avoidance of doubt, Confidential Information with respect to You includes Your Data, and with respect to
        OKAPI includes non-public technical information regarding the Evaluation Service, and the results of any evaluation of the
        Evaluation Service performed by or on behalf of You for purposes of monitoring its availability, performance or
        functionality, or for any other benchmarking or competitive purposes.
      </p>

      <p>
        4.2 Confidential Information does not include information that: (a) becomes generally available to the public other than
        as a result of a disclosure by the Receiving Party; (b) was available to the Receiving Party on a non-confidential basis
        prior to its disclosure by the Disclosing Party or in connection with the performance by the Disclosing Party of its
        obligations under this Agreement; (c) becomes lawfully available to the Receiving Party on a non-confidential basis from
        an independent third party; or (d) is independently developed by the Receiving Party without use of any of Disclosing
        Party’s Confidential Information.
      </p>

      <p>
        4.3 The Receiving Party must not use any of the Disclosing Party’s Confidential Information for any purpose other than
        carrying out the Purpose. Except to the extent expressly permitted by this section, each Receiving Party shall not
        disclose to any third party any Confidential Information of the Disclosing Party received in anticipation of, or in
        connection with the performance of, this Agreement, without the prior written consent of the Disclosing Party. For
        purposes of the preceding sentence, however, “third party” does not include any Affiliates, employees, attorneys,
        accountants, other professional advisors, as long as (a) the Receiving Party is responsible for any non-compliance with
        this Agreement by such person or entity, and (b) such person or entity (i) has a commercially reasonable need for access
        to such Confidential Information in connection with the Purpose, and (ii) is under contractual confidentiality obligations
        substantially equivalent to Section 4.
      </p>

      <p>
        4.4 A disclosure by the Receiving Party of any of the Disclosing Party’s Confidential Information (a) in response to a
        valid order or other legal process issued by a court or other governmental body having jurisdiction, (b) as otherwise
        required by law, or (c) necessary to establish the rights of either Party under this Agreement will not be a breach of
        this Agreement if, to the extent legally&nbsp; permitted, the Receiving Party gives the Disclosing Party prompt written
        notice and reasonable cooperation so the Disclosing Party may seek to prevent or limit such disclosure.
      </p>

      <p>
        4.5 Each Receiving Party’s confidentiality obligations with respect to the Disclosing Party’s Confidential Information
        shall remain in effect for three&nbsp; (3) years after the expiration or termination of this Agreement.
      </p>

      <p>
        4.6 You grant OKAPI a non-exclusive, perpetual, worldwide, transferable, royalty-free license (including the right to
        sublicense) to make, use,&nbsp; copy,&nbsp; develop, distribute, offer to sell and sell Feedback (defined below) as
        a&nbsp; component of the Evaluation Service and/or the Literacy Service. OKAPI and its Affiliates may also use information
        regarding Your use of the Evaluation Service under this Agreement solely for purposes of enhancing and supporting the
        Literacy Service and marketing it to you more effectively.
      </p>

      <p>
        <strong>5. Warranty Disclaimer and Limitation of Liability.</strong>
      </p>

      <p>
        <strong>
          5.1 WARRANTY DISCLAIMER. THE EVALUATION SERVICE IS PROVIDED “AS IS.” OKAPI AND ITS AFFILIATES AND&nbsp; LICENSORS&nbsp;
          DO NOT PROVIDE ANY WARRANTY WITH RESPECT TO THE EVALUATION SERVICE OR THE LITERACY SERVICE OR OKAPI TECHNOLOGY UNDER
          THIS AGREEMENT (INCLUDING, WITHOUT
        </strong>
      </p>

      <p>
        <strong>
          LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE,
          NONINFRINGEMENT, OR ANY OTHER WARRANTY, WHETHER&nbsp; EXPRESS OR IMPLIED). WITHOUT LIMITING THE PRECEDING SENTENCE: (A)
          OKAPI DOES NOT WARRANT THAT THE EVALUATION SERVICE (OR ANY RELATED PROFESSIONAL SERVICES OR DELIVERABLES) IS OR WILL BE
          ERROR-FREE, MEET YOUR REQUIREMENTS, OR BE TIMELY OR SECURE;
        </strong>
      </p>

      <p>
        <strong>
          (B) OKAPI’S EVALUATION SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE&nbsp; OF
          THE INTERNET AND ELECTRONIC COMMUNICATIONS; AND (C) OKAPI AND ITS AFFILIATES AND LICENSORS ARE NOT RESPONSIBLE FOR ANY
          DELAYS, DELIVERY FAILURES, DATA LOSS OR OTHER DAMAGE IN CONNECTION WITH THIS AGREEMENT.
        </strong>
      </p>

      <p>
        <strong>
          5.2 LIMITATION OF LIABILITY.&nbsp; TO THE MAXIMUM EXTENT PERMITTED&nbsp; BY APPLICABLE LAW, AND REGARDLESS OF WHETHER
          ANY REMEDY SET FORTH HEREIN FAILS OF ITS ESSENTIAL PURPOSE: (A) IN NO EVENT WILL OKAPI’S AND ITS AFFILIATES’ AND
          LICENSORS’ TOTAL AGGREGATE LIABILITY ARISING FROM OR RELATING TO THIS AGREEMENT (OR ANY RELATED PROFESSIONAL SERVICES OR
          DELIVERABLES) EXCEED ONE HUNDRED DOLLARS ($100); AND (B) IN NO EVENT WILL OKAPI OR ITS AFFILIATES OR LICENSORS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL OR SIMILAR DAMAGES (INCLUDING, WITHOUT
          LIMITATION, ANY LOST PROFITS, OPPORTUNITIES, DATA OR USE) ARISING OUT OF THE USE OF, OR INABILITY TO USE, THE EVALUATION
          SERVICE IN CONNECTION WITH THIS AGREEMENT, EVEN IF OKAPI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </strong>
      </p>

      <p>
        <strong>6. Indemnity.</strong>
      </p>

      <p>
        You will defend, indemnify and hold harmless OKAPI and its Affiliates and licensors, and their respective Affiliates,
        officers, directors, employees, attorneys and representatives from and against any and all claims, costs, damages, losses,
        liabilities and expenses (including reasonable legal fees, costs and expenses) to the extent arising out of or in
        connection with a claim alleging that Your breach of this Agreement violated any rights of a third party. OKAPI must (a)
        promptly give notice of any such claims to You, (b) give You sole control of the defense and settlement of the claims (but
        You may not settle such claims unless such settlement unconditionally releases OKAPI and its Affiliates, licensors,
        officers, directors, employees, attorneys and representatives of all liability and does not adversely affect OKAPI’s
        interests), and (c) give You reasonable cooperation and information in connection with such defense and indemnification.
      </p>

      <p>
        <strong>7. General.</strong>
      </p>

      <p>
        This Agreement is governed by the federal laws of the U.S.A., and the laws of the State of California, without regard to
        their conflicts of law rules, and any disputes, actions, claims or causes of action arising out of or in connection with
        this Agreement or a Evaluation Service are subject to the exclusive jurisdiction of the state and federal courts located
        in Riverside County, California, U.S.A. Neither&nbsp; Party will assign or transfer any rights or obligations under this
        Agreement without the prior written consent of the other Party. This Agreement may be modified only if authorized
        representatives of both Parties consent in writing. Failure to enforce any provision of this Agreement will not constitute
        a waiver thereof or of any other provision; any waiver to be effective must be signed by an authorized officer of both
        Parties. If any provision of this Agreement is held illegal, invalid, or unenforceable, that provision will be deemed
        amended to achieve an economic effect as near as possible to that provided by the original provision&nbsp; and the
        legality, validity, and enforceability of the remaining provisions of this Agreement will not be affected. All legal
        notices (e.g., claimed breach or termination of this Agreement) required to be provided under this Agreement must be
        delivered in writing (a) in person, (b) by nationally recognized overnight delivery service, or
      </p>

      <p>
        (c) by certified US mail to the other Party at its U.S. corporate headquarters. All other notices from You to OKAPI may be
        made by emailing <a href="mailto:notices@zuora.com">_____________________.</a>
      </p>

      <p>
        <strong>8. Definitions</strong>
      </p>

      <p>As used in this Agreement:</p>

      <p>
        “<strong>Affiliate” </strong>means a company, corporation, individual, partnership or other legal entity that directly or
        indirectly controls, is controlled by, or is under common control with a Party to this Agreement. For purposes of this
        definition, “control” means direct or indirect ownership or control of more than fifty percent (50%) of the voting
        interests of the subject entity;
      </p>

      <p>
        “<strong>Evaluation Service</strong>” means the evaluation environment of OKAPI’s [description of Digital Literacy
        product]
      </p>

      <p>
        &nbsp;“<strong>Feedback</strong>” means any comments or suggestions made by You to OKAPI or its Affiliates in connection
        with this Agreement regarding the&nbsp; Evaluation&nbsp; Service, the Literacy Service, or any OKAPI Confidential
        Information or OKAPI Technology;
      </p>

      <p>
        “<strong>Literacy Service</strong>” means OKAPI DIGITAL LITERACY provided to You under a separate contract for use,
        developed, operated, and maintained by OKAPI and accessible via{' '}
        <a href="http://www.zuora.com/">http://www.okapi-digital-literacy.com</a> or another designated web site or IP address;
      </p>

      <p>
        &nbsp;“<strong>Party</strong>” and “<strong>Parties</strong>” means a party to this Agreement – i.e., OKAPI or You;
      </p>

      <p>
        “<strong>Personal Identifiable Information</strong>” (aka “<strong>PII</strong>”) means information that is identifiable
        to an individual, or when used in conjunction with other information, can identify an individual. Examples of PII include,
        but are not limited to: name, address, phone number, email address, personal identification number&nbsp; (e.g. social
        security number, driver’s license number, taxpayer identification number, etc), financial data (e.g., credit card number),
        medical information, biometric data, race, etc;
      </p>

      <p>
        “<strong>User(s)</strong>” means Your employees, representatives, consultants, contractors or agents who are authorized to
        use the Evaluation Service and have been supplied user identifications and passwords by You (or by OKAPI at Your request);
      </p>

      <p>
        “<strong>Your Data</strong>” means any data, information or content that You or Your Users process using the Evaluation
        Service in accordance with this Agreement;&nbsp; and
      </p>

      <p>
        “<strong>OKAPI Technology</strong>” means all of OKAPI’s proprietary technology (including software, hardware, products,
        processes, algorithms, user interfaces, know-how, techniques, designs and other tangible or intangible technical material
        or information) made available to You by OKAPI in connection with this Agreement.
      </p>
    </div>
  </LandingPage>
);

export default EvaluationAgreement;
