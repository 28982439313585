import { seriesLabelMapping, stageLabelMapping } from '@okapi/domains/Books/helpers';
import { SeriesEnum, StageEnum } from '@okapi/graphql/types.generated';

const nonPhonicsStages: StageEnum[] = [
  StageEnum.SHARED_READING,
  StageEnum.EARLY_EMERGENT,
  StageEnum.EMERGENT,
  StageEnum.EARLY,
  StageEnum.TRANSITIONAL,
  StageEnum.EARLY_FLUENT,
  StageEnum.FLUENT,
  StageEnum.FLUENT_PLUS,
  StageEnum.ADVANCED_FLUENT_Q_S,
  StageEnum.ADVANCED_FLUENT_T_V
];

const config: Record<SeriesEnum, StageEnum[]> = {
  [SeriesEnum.FLYING_START_PHONICS]: [
    StageEnum.PHONICS_STAGE_ONE_MODULE_ONE,
    StageEnum.PHONICS_STAGE_ONE_MODULE_TWO,
    StageEnum.PHONICS_STAGE_ONE_MODULE_THREE,
    StageEnum.PHONICS_STAGE_TWO_MODULE_FOUR,
    StageEnum.PHONICS_STAGE_TWO_MODULE_FIVE,
    StageEnum.PHONICS_STAGE_THREE_MODULE_SIX,
    StageEnum.PHONICS_STAGE_THREE_MODULE_SEVEN
  ],
  [SeriesEnum.FLYING_START]: nonPhonicsStages,
  [SeriesEnum.DESPEGANDO]: nonPhonicsStages,
  [SeriesEnum.WORLDWISE]: nonPhonicsStages,
  [SeriesEnum.EXPLORA_MUNDOS]: nonPhonicsStages
};

export const getColumns = () => [
  {
    title: 'Series',
    dataIndex: 'name',
    key: 'name'
  }
];

export const getData = () =>
  Object.keys(config).map((series) => {
    const key = series as SeriesEnum;

    return {
      key,
      name: seriesLabelMapping[key],
      children: getStages(key)
    };
  });

const getStages = (series: SeriesEnum) =>
  config[series].map((stage) => {
    const key: string = `${series}|${stage}`;

    return {
      key,
      name: stageLabelMapping[stage]
    };
  });
