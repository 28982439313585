import { Button, Form, Input } from 'antd';
import React, { FC } from 'react';

import { FormButtons } from '@okapi/components/Form';
import { District, UpdateDistrictInput } from '@okapi/graphql/types.generated';

import styles from './styles.module.less';
import { validationRules } from './validationRules';

interface Props {
  initialValues?: District;
  onCancel: () => void;
  onSubmit: (values: UpdateDistrictInput) => void;
}

const DistrictForm: FC<Props> = ({ initialValues, onCancel, onSubmit }) => (
  <Form className={styles.wrapper} initialValues={initialValues} layout="vertical" onFinish={onSubmit}>
    <Form.Item hidden={true} name="id" noStyle={true}>
      <Input type="hidden" />
    </Form.Item>
    <Form.Item label="District name" name="name" rules={validationRules.name}>
      <Input autoFocus={true} size="large" />
    </Form.Item>
    <Form.Item label="External id" name="externalId">
      <Input autoFocus={true} size="large" />
    </Form.Item>
    <FormButtons>
      <Button onClick={onCancel}>Cancel</Button>
    </FormButtons>
  </Form>
);

export default DistrictForm;
