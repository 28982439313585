import resources from './resources.csv';
import { RawResource, Resource } from './types';

export const AWS_HOSTNAME = 'https://okapi-digital-literacy-assets.s3.amazonaws.com';

export const getResourceUrl = (assetId: string): string => `${AWS_HOSTNAME}/resources/${assetId}/index.html`;

export const getRawResourceUrl = (assetId: string): string => `${AWS_HOSTNAME}/raw-resources/${assetId}.pdf`;

export const getResources = (): Resource[] =>
  resources.map((resource: RawResource) => ({
    title: resource.TITLE,
    type: resource['RESOURCE TYPE'],
    stage: resource.STAGE,
    module: resource.MODULE,
    isbn: resource['ITEM ISBN'],
    asset: resource['ASSET ID']
  }));
