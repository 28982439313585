import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type LogInMutationVariables = GraphQLTypes.Exact<{
  email: GraphQLTypes.Scalars['String']['input'];
  password: GraphQLTypes.Scalars['String']['input'];
}>;

export type LogInMutationResult = { __typename?: 'Mutation' } & {
  login: { __typename?: 'Token' } & Pick<GraphQLTypes.Token, 'accessToken'>;
};

export const LogInDocument = /* #__PURE__ */ gql`
  mutation logIn($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
    }
  }
`;

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLogInMutation(baseOptions?: Apollo.MutationHookOptions<LogInMutationResult, LogInMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<LogInMutationResult, LogInMutationVariables>(LogInDocument, options);
}
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
