import { Alert, App } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { FC, useState } from 'react';

import { useCreateSubscription } from '../graphql';
import { prepareInitialValues, prepareRequestPayload } from '../helpers';
import SubscriptionForm from '../SubscriptionForm';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  unitId: string;
}

const AddSubscription: FC<Props> = ({ onClose, onSuccess, unitId }) => {
  const { notification } = App.useApp();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [createSubscription] = useCreateSubscription();

  const handleSubmit = async (values: any) => {
    try {
      setErrorMessage('');

      await createSubscription({
        variables: {
          subscription: {
            unitId,
            ...prepareRequestPayload(values)
          }
        },
        refetchQueries: ['subscription']
      });

      notification.success({ message: 'Success', description: 'Subscription has been added.' });

      onSuccess();
    } catch (err) {
      setErrorMessage('Something went wrong.');
    }
  };

  return (
    <Modal footer={null} onCancel={onClose} open={true} title="Add new subscription">
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <SubscriptionForm initialValues={prepareInitialValues()} onCancel={onClose} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default AddSubscription;
