import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

import DownloadLink from '@okapi/components/DownloadLink';

import { getRawResourceUrl } from './helpers';
import { Resource } from './types';

export const getColumns = (): ColumnsType<Resource> => [
  {
    title: 'Title',
    dataIndex: 'title',
    render: (title: string, { isbn }) => <Link to={`/resources/flying-start-to-literacy-phonics/${isbn}`}>{title}</Link>,
    width: 130,
    sorter: (a: { title: string }, b: { title: string }) => a.title.localeCompare(b.title)
  },
  {
    title: 'Stage',
    dataIndex: 'stage',
    width: 200,
    sorter: (a: { stage: string }, b: { stage: string }) => a.stage.localeCompare(b.stage)
  },
  {
    title: 'Module',
    dataIndex: 'module',
    width: 300,
    sorter: (a: { module: string }, b: { module: string }) => a.module.localeCompare(b.module)
  },
  {
    title: 'Actions',
    align: 'right',
    key: 'actions',
    fixed: 'right',
    width: 160,
    render: (_, { asset }) => <DownloadLink url={getRawResourceUrl(asset)} />
  }
];
