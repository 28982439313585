import { RouteObject } from 'react-router';

import DemoLifetimeAccess from '@okapi/components/DemoLifetimeAccess';
import NotAuthorized from '@okapi/components/NotAuthorized';
import Analytics from '@okapi/domains/Analytics';
import AutoLogin from '@okapi/domains/Auth/AutoLogin';
import Books from '@okapi/domains/Books';
import BookDetails from '@okapi/domains/Books/BookDetails';
import BookList from '@okapi/domains/Books/BookList';
import Dashboard from '@okapi/domains/Dashboard';
import Districts from '@okapi/domains/Districts';
import DistrictList from '@okapi/domains/Districts/DistrictList';
import EditDistrict from '@okapi/domains/Districts/EditDistrict';
import ChildrenPolicy from '@okapi/domains/Pages/ChildrenPolicy';
import EvaluationAgreement from '@okapi/domains/Pages/EvaluationAgreement/EvaluationAgreement';
import Faq from '@okapi/domains/Pages/Faq';
import Resources from '@okapi/domains/Resources';
import ResourceList from '@okapi/domains/Resources/ResourceList';
import BiliteracyParaTodos from '@okapi/domains/Resources/ResourceList/BiliteracyParaTodos/BiliteracyParaTodos';
import BiliteracyParaTodosDetails from '@okapi/domains/Resources/ResourceList/BiliteracyParaTodosDetails';
import InvestigationDetails from '@okapi/domains/Resources/ResourceList/InvestigationDetails/InvestigationDetails';
import Investigations from '@okapi/domains/Resources/ResourceList/Investigations/Investigations';
import LessonPlans from '@okapi/domains/Resources/ResourceList/LessonPlans/LessonPlans';
import Phonics from '@okapi/domains/Resources/ResourceList/Phonics/Phonics';
import PhonicsDetails from '@okapi/domains/Resources/ResourceList/PhonicsDetails/PhonicsDetails';
import TestPreparationDetails from '@okapi/domains/Resources/ResourceList/TestPreparationDetails';
import TestPreparations from '@okapi/domains/Resources/ResourceList/TestPreparations';
import Schools from '@okapi/domains/Schools';
import AddSchool from '@okapi/domains/Schools/AddSchool';
import EditSchool from '@okapi/domains/Schools/EditSchool';
import SchoolList from '@okapi/domains/Schools/SchoolList';
import Teachers from '@okapi/domains/Teachers';
import UserManagement from '@okapi/domains/UserManagement';

import Layout from '../components/Layout';
import CleverOAuth from '../domains/Auth/CleverOAuth';
import EdlinkOAuth from '../domains/Auth/EdlinkOAuth';
import ForgotPassword from '../domains/Auth/ForgotPassword';
import Login from '../domains/Auth/Login';
import Registration from '../domains/Auth/Registration';
import ResetPassword from '../domains/Auth/ResetPassword';
import PrivacyPolicy from '../domains/Pages/PrivacyPolicy';
import SasAgreement from '../domains/Pages/SasAgreement';
import TermsOfUse from '../domains/Pages/TermsOfUse';
import { Book } from '../domains/SharedResources';

export const routes: RouteObject[] = [
  {
    element: <AutoLogin />,
    path: '/login/:customerId'
  },
  {
    element: <CleverOAuth />,
    path: '/clever/authorize'
  },
  {
    element: <DemoLifetimeAccess />,
    path: '/demo-access/authorize'
  },
  {
    element: <EdlinkOAuth />,
    path: '/edlink/authorize'
  },
  {
    element: <Login />,
    path: '/login'
  },
  {
    element: <Registration />,
    path: '/register/:token'
  },
  {
    element: <ForgotPassword />,
    path: '/forgot-password'
  },
  {
    element: <ResetPassword />,
    path: '/reset-password/:token'
  },
  {
    element: <TermsOfUse />,
    path: '/terms-of-use'
  },
  {
    element: <ChildrenPolicy />,
    path: '/children-policy'
  },
  {
    element: <EvaluationAgreement />,
    path: '/evaluation-agreement'
  },
  {
    element: <PrivacyPolicy />,
    path: '/privacy-policy'
  },
  {
    element: <SasAgreement />,
    path: '/sas'
  },
  {
    element: <Book />,
    path: '/book/:token'
  },
  {
    element: <Book />,
    path: '/book/:token/:resourceType'
  },
  {
    element: <Layout />,
    path: '/',
    children: [
      {
        index: true,
        element: <Dashboard />
      },
      {
        element: <Analytics />,
        path: '/dashboard'
      },
      {
        element: <UserManagement />,
        path: '/user-management'
      },
      {
        element: <Books />,
        path: '/books',
        children: [
          {
            element: <BookDetails />,
            path: '/books/:id'
          },
          {
            element: <BookDetails />,
            path: '/books/:id/:resourceType'
          },
          {
            element: <BookList />,
            index: true
          }
        ]
      },
      {
        element: <Resources />,
        path: 'resources',
        children: [
          {
            element: <Phonics />,
            path: 'flying-start-to-literacy-phonics'
          },
          {
            element: <PhonicsDetails />,
            path: 'flying-start-to-literacy-phonics/:id'
          },
          {
            element: <BiliteracyParaTodos />,
            path: 'biliteracy-para-todos'
          },
          {
            element: <BiliteracyParaTodosDetails />,
            path: 'biliteracy-para-todos/:id'
          },
          {
            element: <LessonPlans />,
            path: 'lesson-plans'
          },
          {
            element: <Investigations />,
            path: 'investigations'
          },
          {
            element: <InvestigationDetails />,
            path: 'investigations/:id'
          },
          {
            element: <TestPreparations />,
            path: 'test-preparations'
          },
          {
            element: <TestPreparationDetails />,
            path: 'test-preparations/:id'
          },
          {
            element: <ResourceList />,
            index: true
          }
        ]
      },
      {
        element: <Districts />,
        path: '/districts',
        children: [
          {
            element: <EditDistrict />,
            path: '/districts/:unitId'
          },
          {
            element: <DistrictList />,
            index: true
          }
        ]
      },
      {
        element: <Faq />,
        path: '/faq'
      },
      {
        element: <Schools />,
        path: '/schools',
        children: [
          {
            element: <AddSchool />,
            path: '/schools/add'
          },
          {
            element: <EditSchool />,
            path: '/schools/:unitId'
          },
          {
            element: <SchoolList />,
            index: true
          }
        ]
      },
      {
        element: <Teachers />,
        path: '/teachers'
      },
      {
        element: <NotAuthorized />,
        path: '/403'
      }
    ]
  }
];
