import { Button, Drawer, Typography } from 'antd';
import React, { FC, useEffect, useState } from 'react';

import useApp from '@okapi/App/hooks/useApp';

import styles from './styles.module.less';

const DemoAccessPopup: FC = () => {
  const { isDemoVersion } = useApp();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const skipPopup = !isDemoVersion || localStorage.getItem('demo-lifetime-access');

  useEffect(() => {
    if (skipPopup) return;

    const timeout = setTimeout(() => {
      if (!showPopup) {
        setShowPopup(true);
      }
    }, 30000);

    return () => {
      clearTimeout(timeout);
    };
  }, [showPopup, skipPopup]);

  const handleClose = () => setShowPopup(false);

  if (skipPopup) {
    return null;
  }

  return (
    <Drawer height="auto" maskClosable={false} onClose={handleClose} open={showPopup} placement="bottom">
      <div className={styles.modalContent}>
        <Typography.Title level={2}>Welcome to Our Preview Site!</Typography.Title>
        <div>Choose any of the options from the left menu.</div>
        <div>
          Your login credentials at <a href="https://myokapi.com">myokapi.com</a> will provide you with free, uninterrupted access
          to this Preview Site or you may request a free, full feature trial for your school.
        </div>
        <div className={styles.actionBar}>
          <a href="https://myokapi.com/customer/account/create">
            <Button size="large" type="primary">
              Create Your Login
            </Button>
          </a>
          <span className={styles.separator}>or</span>
          <a href="https://myokapi.com/customer/account/login">
            <Button size="large" type="primary">
              Login
            </Button>
          </a>
        </div>
        <div className={styles.hint}>
          For more information about this site, we invite you to watch{' '}
          <a href="https://myokapi.com/benefits-of-registering">this</a> short video.
        </div>
      </div>
    </Drawer>
  );
};

export default DemoAccessPopup;
