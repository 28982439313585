import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ResourcesQueryVariables = GraphQLTypes.Exact<{
  filters: GraphQLTypes.BooksFilters;
  page: GraphQLTypes.GetPage;
  orderBy?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']['input']> | GraphQLTypes.Scalars['String']['input']>;
}>;

export type ResourcesQueryResult = { __typename?: 'Query' } & {
  books: { __typename?: 'PaginatedBook' } & Pick<GraphQLTypes.PaginatedBook, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          { __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'asset' | 'id' | 'title'> & {
              series: { __typename?: 'Series' } & Pick<GraphQLTypes.Series, 'key' | 'value'>;
              stage?: GraphQLTypes.Maybe<{ __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'key' | 'value'>>;
              teksLessonPlan?: GraphQLTypes.Maybe<{ __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'id' | 'asset'>>;
            }
        >
      >;
    };
};

export const ResourcesDocument = /* #__PURE__ */ gql`
  query resources($filters: BooksFilters!, $page: GetPage!, $orderBy: [String!]) {
    books(filters: $filters, page: $page, orderBy: $orderBy) {
      nodes {
        asset
        id
        title
        series {
          key
          value
        }
        stage {
          key
          value
        }
        teksLessonPlan {
          id
          asset
        }
      }
      totalCount
    }
  }
`;

/**
 * __useResourcesQuery__
 *
 * To run a query within a React component, call `useResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<ResourcesQueryResult, ResourcesQueryVariables> &
    ({ variables: ResourcesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ResourcesQueryResult, ResourcesQueryVariables>(ResourcesDocument, options);
}
export function useResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcesQueryResult, ResourcesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ResourcesQueryResult, ResourcesQueryVariables>(ResourcesDocument, options);
}
export function useResourcesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ResourcesQueryResult, ResourcesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<ResourcesQueryResult, ResourcesQueryVariables>(ResourcesDocument, options);
}
export type ResourcesQueryHookResult = ReturnType<typeof useResourcesQuery>;
export type ResourcesLazyQueryHookResult = ReturnType<typeof useResourcesLazyQuery>;
export type ResourcesSuspenseQueryHookResult = ReturnType<typeof useResourcesSuspenseQuery>;
