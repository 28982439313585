import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/interface';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

import { Book } from '../../BookDetails/types';

interface Props {
  book: Book;
}

const LessonPlanLinks: FC<Props> = ({ book }) => {
  const { lessonPlan, teksLessonPlan } = book;

  const items: ItemType[] = [
    { key: 'lesson-plan', label: <Link to={`/books/${lessonPlan?.id}`}>Lesson Plan</Link> },
    { key: 'teks-lesson-plan', label: <Link to={`/books/${teksLessonPlan?.id}`}>TEKS Lesson Plan</Link> }
  ];

  return (
    <>
      {lessonPlan && !teksLessonPlan && <Link to={`/books/${lessonPlan.id}`}>Lesson Plan</Link>}
      {lessonPlan && teksLessonPlan && (
        <Dropdown menu={{ items }} trigger={['click']}>
          <Typography.Link>
            Lesson Plans <DownOutlined />
          </Typography.Link>
        </Dropdown>
      )}
    </>
  );
};

export default LessonPlanLinks;
