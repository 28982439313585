import { Spin } from 'antd';
import React, { FC } from 'react';

import styles from './styles.module.less';

const Spinner: FC = () => (
  <div className={styles.wrapper} data-testid="spinner">
    <Spin size="large" />
  </div>
);

export default Spinner;
