import { useContext, useMemo } from 'react';

import TrackingContext from './components/TrackingProvider/TrackingContext';

const useTracking = () => {
  const { trackEvent } = useContext(TrackingContext);

  return useMemo(() => ({ trackEvent }), [trackEvent]);
};

export default useTracking;
