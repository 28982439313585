import { QuestionCircleOutlined } from '@ant-design/icons';
import { Divider, Popconfirm, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { uniq } from 'lodash';
import React from 'react';

import { SeriesEnum } from '@okapi/graphql/types.generated';
import { formatDate } from '@okapi/utils/formatting';

import { seriesLabelMapping } from '../Books/helpers';
import { Subscription, SubscriptionItem } from './types';

interface Params {
  onDelete: (id: string) => void;
  onEdit: (subscription: Subscription) => void;
  unitId: string;
}

export const getColumns = ({ onEdit, onDelete, unitId }: Params): ColumnsType<Subscription> => [
  {
    title: 'Series',
    dataIndex: 'items',
    render: (items) => {
      const seriesKeys = items.map(({ series }: SubscriptionItem) => series.key);

      return uniq<SeriesEnum>(seriesKeys).map((key) => <div key={key}>{seriesLabelMapping[key]}</div>);
    }
  },
  {
    title: 'From',
    dataIndex: 'from',
    render: (from) => formatDate(from)
  },
  {
    title: 'To',
    dataIndex: 'to',
    render: (to) => formatDate(to)
  },
  {
    title: 'Status',
    dataIndex: 'active',
    render: (active) => {
      if (!active) {
        return <Tag color="volcano">inactive</Tag>;
      }

      return <Tag color="green">active</Tag>;
    }
  },
  {
    title: 'Actions',
    align: 'right',
    key: 'actions',
    fixed: 'right',
    width: 170,
    render: (subscription) => {
      if (subscription.unitId !== unitId) {
        return (
          <Tooltip placement="topLeft" title="This is a subscription on the district level">
            <QuestionCircleOutlined />
          </Tooltip>
        );
      }

      return (
        <>
          <Typography.Link onClick={() => onEdit(subscription)}>Edit</Typography.Link>
          <Divider type="vertical" />{' '}
          <Popconfirm
            cancelText="No"
            okText="Yes"
            onConfirm={() => onDelete(subscription.id)}
            placement="topLeft"
            title="Are you sure you want to delete this subscription?"
          >
            <Typography.Link>Delete</Typography.Link>
          </Popconfirm>
        </>
      );
    }
  }
];
