import { Alert, Button, Col, Form, Input, Row } from 'antd';
import { FC } from 'react';

import { FormButtons } from '@okapi/components/Form';
import { isDistrictAdmin, isSchoolAdmin, isTeacher } from '@okapi/domains/Auth';
import RoleSelect from '@okapi/domains/Auth/components/RoleSelect';
import DistrictSelect from '@okapi/domains/Districts/components/DistrictSelect';
import SchoolSelect from '@okapi/domains/Schools/components/SchoolSelect';
import { Role } from '@okapi/graphql/types.generated';
import { Nullable, Optional } from '@okapi/utils/typescript';

import styles from './styles.module.less';
import { UserFormInitialValues, UserFormValues } from './types';
import { validationRules } from './validationRules';

interface Props {
  initialValues?: UserFormInitialValues;
  onCancel: () => void;
  onSubmit: (values: UserFormValues) => void;
}

const UserForm: FC<Props> = ({ initialValues, onCancel, onSubmit }) => {
  const [form] = Form.useForm<UserFormValues>();

  const role: Nullable<Role> = Form.useWatch('role', form);
  const externalId: Optional<string> = Form.useWatch('externalId', form);

  const isSchoolSelectVisible: boolean = (isSchoolAdmin(role) || isTeacher(role)) && !externalId;
  const isDistrictSelectVisible: boolean = isDistrictAdmin(role) || !!externalId;

  return (
    <Form
      data-testid="user-form"
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={onSubmit}
      validateTrigger="onBlur"
    >
      {externalId && <Alert className={styles.banner} message="External user" type="info" />}

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="First name" name="firstName" rules={validationRules.firstName}>
            <Input name="firstName" size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Last name" name="lastName" rules={validationRules.lastName}>
            <Input name="lastName" size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Email address" name="email" rules={validationRules.email}>
        <Input name="email" size="large" />
      </Form.Item>
      <Form.Item label="User role" name="role" rules={validationRules.role}>
        <RoleSelect excludeRoles={externalId ? [Role.ADMIN] : []} name="role" />
      </Form.Item>
      <Form.Item hidden={true} name="externalId" noStyle={true}>
        <Input type="hidden" />
      </Form.Item>
      {isDistrictSelectVisible && (
        <Form.Item label="District" name="districtId" rules={validationRules.districtId}>
          <DistrictSelect name="districtId" />
        </Form.Item>
      )}
      {isSchoolSelectVisible && (
        <Form.Item label="School" name="schoolId" rules={validationRules.schoolId}>
          <SchoolSelect name="schoolId" />
        </Form.Item>
      )}
      <FormButtons>
        <Button onClick={onCancel}>Cancel</Button>
      </FormButtons>
    </Form>
  );
};

export default UserForm;
