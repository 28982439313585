import { Typography } from 'antd';
import React, { FC, ReactNode } from 'react';

import styles from './styles.module.less';

interface Props {
  text: string;
  tagline?: ReactNode;
}

const FormHeadline: FC<Props> = ({ tagline, text }) => (
  <div className={styles.headline}>
    <Typography.Title level={3}>{text}</Typography.Title>
    {tagline && <Typography.Text>{tagline}</Typography.Text>}
  </div>
);

export default FormHeadline;
