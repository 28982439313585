import { ValidationRules } from '../../../../types';

export const validationRules: ValidationRules = {
  email: [
    {
      type: 'email',
      required: true,
      message: 'Please enter valid email address'
    }
  ],
  password: [
    {
      required: true,
      message: 'Please input your password'
    }
  ]
};
