import { Typography } from 'antd';
import { FC } from 'react';

import useApp from '@okapi/App/hooks/useApp';
import DisabledLinkTooltip from '@okapi/components/DisabledLinkTooltip';

interface Props {
  label?: string;
  url: string;
}

const DownloadLink: FC<Props> = ({ label = 'Download', url }) => {
  const { isDemoVersion } = useApp();

  if (isDemoVersion) {
    return (
      <DisabledLinkTooltip>
        <Typography.Link data-testid="download-link" disabled={true}>
          {label}
        </Typography.Link>
      </DisabledLinkTooltip>
    );
  }

  return (
    <a data-testid="download-link" href={url} rel="noopener noreferrer" target="_blank">
      {label}
    </a>
  );
};

export default DownloadLink;
