import { GetPage } from '@okapi/graphql/types.generated';

export const PAGE_SIZE = 36;

interface PaginationParams {
  page: GetPage;
}

export const getPaginationParams = (page: number): PaginationParams => ({
  page: {
    skip: (page - 1) * PAGE_SIZE,
    take: PAGE_SIZE
  }
});
