import { Select } from 'antd';
import { FC } from 'react';

import { Option } from '@okapi/types';

import { roleLabelMapping } from '../../helpers';
import { Role } from '../../types';

const options: Option[] = [
  { value: Role.ADMIN, label: roleLabelMapping[Role.ADMIN] },
  { value: Role.DISTRICT_ADMIN, label: roleLabelMapping[Role.DISTRICT_ADMIN] },
  { value: Role.SCHOOL_ADMIN, label: roleLabelMapping[Role.SCHOOL_ADMIN] },
  { value: Role.TEACHER, label: roleLabelMapping[Role.TEACHER] }
];

const getOptions = (rolesToExclude: Role[]) => options.filter(({ value }) => !rolesToExclude.includes(value as Role));

interface Props {
  excludeRoles?: Role[];
  name: string;
}

const RoleSelect: FC<Props> = ({ excludeRoles, ...rest }) => (
  <Select {...rest} options={getOptions(excludeRoles || [])} size="large" />
);

export default RoleSelect;
