import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/interface';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

import { BookTypeEnum, StageEnum } from '@okapi/graphql/types.generated';

import { Book } from '../../BookDetails/types';

interface Props {
  book: Book;
}

const RelatedBookLink: FC<Props> = ({ book }) => {
  const { related, stage, bookType } = book;

  const isSharedReadingBook: boolean = stage?.key === StageEnum.SHARED_READING;
  const isInvestigations: boolean = bookType?.key === BookTypeEnum.INVESTIGATIONS;

  const items: ItemType[] = [
    { key: 'book-with-audio', label: <Link to={`/books/${related?.id}/video`}>Book with Audio</Link> },
    { key: 'book-without-audio', label: <Link to={`/books/${related?.id}`}>Book without Audio</Link> }
  ];

  const renderDropdown = () => (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Typography.Link>
        Book <DownOutlined />
      </Typography.Link>
    </Dropdown>
  );

  const renderLink = () => <Link to={`/books/${related?.id}`}>{isInvestigations ? 'Book' : 'Paired Text'}</Link>;

  return isSharedReadingBook ? renderDropdown() : renderLink();
};

export default RelatedBookLink;
