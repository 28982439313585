import { AutoComplete, Input, InputRef } from 'antd';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDebounce } from 'react-use';

import useApp from '@okapi/App/hooks/useApp';
import { useBooksLazyQuery } from '@okapi/domains/Books/graphql';
import { BooksQueryResult } from '@okapi/domains/Books/graphql/books.generated';
import useTracking from '@okapi/useTracking';
import { getPaginationParams } from '@okapi/utils/pagination';

import styles from './styles.module.less';

interface Props {
  className?: string;
}

const Search: FC<Props> = ({ className }) => {
  const [options, setOptions] = useState<{ label: ReactNode; value: string }[]>([]);
  const searchInputRef = useRef<InputRef>(null);
  const [value, setValue] = useState<string>('');
  const { isDemoVersion } = useApp();
  const navigate = useNavigate();
  const { trackEvent } = useTracking();

  const handleSearchComplete = (data: BooksQueryResult): void => {
    const results = data?.books?.nodes?.map((book) => {
      const { asset, id, title, includedInDemo } = book;
      const label = (isDemoVersion && !includedInDemo) || !asset ? <div className={styles.notAvailable}>{title}</div> : title;

      return {
        value: `${id}|${includedInDemo}|${!!asset}|${title}`,
        label
      };
    });

    setOptions(results || []);
  };

  const [search] = useBooksLazyQuery({ onCompleted: handleSearchComplete });

  const notFoundContent: string = value ? 'Sorry, we could not find any books' : '';

  const handleSearch = (searchTerm: string): void => setValue(searchTerm);

  const handleBlur = (): void => {
    if (!value) {
      setOptions([]);
    }
  };

  const handleSelect = (val: string): void => {
    const [id, includedInDemo, hasAccess, title] = val.split('|');

    setValue('');

    if ((isDemoVersion && !JSON.parse(includedInDemo)) || !JSON.parse(hasAccess)) {
      return;
    }

    trackEvent({
      category: 'GlobalSearch',
      action: 'Search',
      label: title
    });

    navigate(`/books/${id}`);
  };

  const performSearch = (): void => {
    search({
      variables: {
        filters: {
          searchTerm: value
        },
        ...getPaginationParams(1)
      }
    });
  };

  useDebounce(performSearch, 300, [value]);

  useEffect(() => {
    if (!searchInputRef.current) return;

    searchInputRef?.current?.focus();
  }, []);

  return (
    <AutoComplete
      className={className}
      notFoundContent={notFoundContent}
      onBlur={handleBlur}
      onSearch={handleSearch}
      onSelect={handleSelect}
      options={options}
      value={value}
    >
      <Input
        ref={searchInputRef}
        placeholder="Search for a book title, ISBN number..."
        size="large"
        style={{ borderColor: 'transparent' }}
      />
    </AutoComplete>
  );
};

export default Search;
