import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookLinkQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['ID']['input'];
}>;

export type BookLinkQueryResult = { __typename?: 'Query' } & {
  link: { __typename?: 'Link' } & {
    book: { __typename?: 'Book' } & Pick<GraphQLTypes.Book, 'asset' | 'id' | 'title' | 'videoAsset'> & {
        series: { __typename?: 'Series' } & Pick<GraphQLTypes.Series, 'value' | 'key'>;
      };
  };
};

export const BookLinkDocument = /* #__PURE__ */ gql`
  query bookLink($id: ID!) {
    link(id: $id) {
      book {
        asset
        id
        title
        videoAsset
        series {
          value
          key
        }
      }
    }
  }
`;

/**
 * __useBookLinkQuery__
 *
 * To run a query within a React component, call `useBookLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookLinkQuery(
  baseOptions: Apollo.QueryHookOptions<BookLinkQueryResult, BookLinkQueryVariables> &
    ({ variables: BookLinkQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BookLinkQueryResult, BookLinkQueryVariables>(BookLinkDocument, options);
}
export function useBookLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookLinkQueryResult, BookLinkQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BookLinkQueryResult, BookLinkQueryVariables>(BookLinkDocument, options);
}
export function useBookLinkSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BookLinkQueryResult, BookLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<BookLinkQueryResult, BookLinkQueryVariables>(BookLinkDocument, options);
}
export type BookLinkQueryHookResult = ReturnType<typeof useBookLinkQuery>;
export type BookLinkLazyQueryHookResult = ReturnType<typeof useBookLinkLazyQuery>;
export type BookLinkSuspenseQueryHookResult = ReturnType<typeof useBookLinkSuspenseQuery>;
