/* eslint-disable max-len */
import React, { FC } from 'react';

import LandingPage from '@okapi/components/LandingPage';

const TermsOfUse: FC = () => (
  <LandingPage title="Okapi Terms of Use">
    <p>
      <strong>Last Modified: December 2022</strong>
    </p>
    <p>
      Copyright Notice
      <br />
      &copy;2022 Okapi Educational Publishing, Inc. All rights reserved
    </p>
    <p>
      <strong>
        PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. BY ACCESSING THE WEBSITE OR BY USING ANY MATERIALS MADE AVAILABLE
        VIA THE WEBSITE
      </strong>
      , YOU AGREE TO THESE TERMS AND CONDITIONS. &nbsp;These terms and conditions are entered into by and between You and OKAPI
      EDUCATIONAL PUBLISHING, INC. (&quot;<strong>Company</strong>&quot;, &quot;<strong>we</strong>&quot; or &quot;
      <strong>us</strong>&quot;), and together with our Privacy Policy, found at:{' '}
      <a href="https://myokapi.com/privacy-policy">https://myokapi.com/privacy-policy</a>, and any documents they expressly
      incorporate by reference (collectively, these &quot;<strong>Terms of Service</strong>
      &quot;), govern Your access to and use any of the Company&rsquo;s websites &nbsp;including without limitation, any content
      or other materials made available on such websites (collectively referred to as the &quot;<strong>Website</strong>&quot;).
      &nbsp;If You do not want to agree to these Terms of Service, You must not access or use the Website.
    </p>
    <p>
      <strong>Changes to the Terms of Service</strong>
    </p>
    <p>
      We may revise and update these Terms of Service from time to time in our sole discretion. All changes are effective
      immediately when we post them, and apply to all access to and use of the Website thereafter. Your continued use of the
      Website following the posting of revised Terms of Service means that You accept and agree to the changes. You are expected
      to check this page from time to time so You are aware of any changes, as they are binding on You.&nbsp;
    </p>
    <p>
      <strong>Accessing the Website and Account Security</strong>
    </p>
    <p>
      We reserve the right to withdraw or amend the Website, and any service or material we provide on the Website, in our sole
      discretion without notice. &nbsp;Unless we have otherwise specifically agreed, we will not be liable if for any reason all
      or any part of the Website is unavailable at any time or for any period. &nbsp;From time to time, we may restrict access to
      some parts of the Website, or the entire Website, to users, including registered users.&nbsp;
    </p>
    <p>
      You are responsible for making all arrangements necessary for You to have access to the Website, and ensuring that all
      persons who access the Website through Your internet connection are aware of these Terms of Service and comply with them.
    </p>
    <p>
      To access the Website or some of the resources it offers, You may be asked to provide certain registration details or other
      information. It is a condition of Your use of the Website that all the information You provide on the Website is correct,
      current and complete. You agree that all information You provide to register with the Website or otherwise, including but
      not limited to, through the use of any interactive features on the Website, is governed by our Privacy Policy and You
      consent to all actions we take with respect to Your information consistent with our Privacy Policy.&nbsp;
    </p>
    <p>
      If You create an account on the Website, You also acknowledge that Your account is personal to You and agree not to provide
      any other person with access to the Website or portions of it using Your username, password or other security information.
      You agree to notify us immediately of any unauthorized access to or use of Your username or password or any other breach of
      security. You also agree to ensure that You exit from Your account at the end of each session. You should use particular
      caution when accessing Your account from a public or shared computer so that others are not able to view or record Your
      password or other personal information. &nbsp;We have the right to disable, at the individual or district level, any access,
      username, password or other identifier, whether chosen by You or provided by us, at any time in our sole discretion for any
      or no reason, including if, in our opinion, You have violated any provision of these Terms of Service.
    </p>
    <p>
      <strong>Intellectual Property Rights</strong>
    </p>
    <p>
      The Website and its entire contents, features and functionality (including, but not limited to, all information, software,
      text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, our
      licensors or other providers of such material and are protected by United States and international copyright, trademark,
      patent, trade secret and other intellectual property or proprietary rights laws. &nbsp;You agree not to delete or alter any
      copyright, trademark or other proprietary rights notices from any materials or content from the Website. &nbsp;
    </p>
    <p>
      These Terms of Service permit You to use the Website for Your personal, non-commercial use only. You must not reproduce,
      distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit
      any of the material on our Website, except as follows:
    </p>
    <ul>
      <li>
        You may share links to certain materials made available for sharing as part of the Website for educational purposes only.
      </li>
      <li>
        Your computer may store copies of such materials specifically designated as available for download, including but not
        limited to, lesson plans, anchor charts, sentence stems, sample materials, lesson plans, and similar materials
        (&ldquo;Downloadable Materials&rdquo;) as part of Your accessing and viewing those materials.
      </li>
      <li>
        You may download and print a reasonable number of copies of Downloadable Materials for Your educational, non-commercial
        use and not for further reproduction, publication or distribution; provided that all copyright and proprietary notices are
        kept intact.&nbsp;
      </li>
    </ul>
    <p>
      If You print, copy, modify, download or otherwise use or provide any other person with access to any part of the Website in
      breach of the Terms of Service, Your right to use the Website will cease immediately and You must, at our option, return or
      destroy any copies of the materials You have made. No right, title or interest in or to the Downloadable Materials, Website
      or any content on the Website is transferred to You, and all rights not expressly granted are reserved by the Company. Any
      use of the Website not expressly permitted by these Terms of Service is a breach of these Terms of Service and may violate
      copyright, trademark and other laws.
    </p>
    <p>
      <strong>Trademarks</strong>
    </p>
    <p>
      The Company name, the terms including but not limited to, Okapi Digital Literacy, Flying Start to Literacy, Despegando hacia
      la lectura, World Wise &ndash; Content Based Learning, ExploraMundos, Power of the Pair and Just Dual It, Biliteracy para
      todos, the Company logo and all related names, logos, product and service names, designs and slogans are trademarks of the
      Company or our affiliates or licensors. You must not use such names or marks without the prior written permission of the
      Company. All other names, logos, product and service names, designs and slogans on the Website are the trademarks of their
      respective owners.&nbsp;
    </p>
    <p>
      <strong>Third Party Content and Services</strong>
    </p>
    <p>
      The Company may provide links to web pages and content of third parties (the &quot;<strong>Third Party Content</strong>
      &quot;) as a service to those interested in this information. The Company does not monitor nor do we have any control over
      any Third Party Content or third party websites or the privacy policies of such websites. The Company does not endorse or
      adopt any Third Party Content and can make no guarantee as to its accuracy or completeness. &nbsp;All statements and/or
      opinions expressed in any Third Party Content, and all articles and responses to questions and other content, other than the
      content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those
      materials. These materials do not necessarily reflect the opinion of the Company. The Company does not represent or warrant
      the accuracy of any information contained therein, and undertakes no responsibility to update or review any Third Party
      Content. &nbsp;You may only use these links and Third Party Content contained therein at your own risk.
    </p>
    <p>
      <strong>Prohibited Uses</strong>
    </p>
    <p>
      You may use the Website only for lawful purposes and in accordance with these Terms of Service. You agree not to use the
      Website:&nbsp;
    </p>
    <ul>
      <li>
        In any way that violates any applicable federal, state, local or international law or regulation (including, without
        limitation, any laws regarding the export of data or software to and from the US or other countries).
      </li>
      <li>
        For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to
        inappropriate content, asking for personally identifiable information or otherwise.
      </li>
      <li>
        To engage in any other conduct that restricts or inhibits anyone&rsquo;s use or enjoyment of the Website, or which, as
        determined by us, may harm the Company or users of the Website or expose them to liability.
      </li>
      <li>
        Use the Website in any manner that could disable, overburden, damage, or impair the Website or interfere with any other
        party&rsquo;s use of the Website, including their ability to engage in real time activities through the Website.
      </li>
      <li>
        Use any robot, spider or other automatic device, process or means, or any manual process, to access the Website for any
        purpose, including monitoring or copying any of the material on the Website.
      </li>
      <li>
        Use any device, software or routine that interferes with the proper working of the Website, or introduce any viruses,
        trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.
      </li>
      <li>
        Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website, the server on which
        the Website is stored, or any server, computer or database connected to the Website, or attack the Website via a
        denial-of-service attack or a distributed denial-of-service attack.
      </li>
    </ul>
    <p>
      <strong>Reliance on Information Made Available on the Website</strong>
    </p>
    <p>
      The information and any other materials presented on or through the Website is made available solely for general information
      purposes. We do not warrant the accuracy, completeness or usefulness of this information and materials. Any reliance You
      place on such information or materials is strictly at Your own risk. We disclaim all liability and responsibility arising
      from any reliance placed on such information and materials by You or any other visitor to the Website, or by anyone who may
      be informed of any of its contents.
    </p>
    <p>
      <strong>Information About You and Your Visits to the Website</strong>
    </p>
    <p>
      All information we collect on the Website is subject to our Privacy Policy. By using the Website, You consent to all actions
      taken by us with respect to Your information in compliance with the Privacy Policy.&nbsp;
    </p>
    <p>
      <strong>Disclaimer of Warranties</strong>
    </p>
    <p>
      YOUR USE OF THE WEBSITE, ITS CONTENT, MATERIALS, AND ANY SERVICES OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK, ON AN
      &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER
      THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
      COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING,
      NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, MATERIALS,
      OR ANY SERVICES &nbsp;OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS
      WILL BE CORRECTED, THAT THE WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR
      THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
      &nbsp;THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING
      BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. &nbsp;THE
      FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
    </p>
    <p>
      <strong>Limitation on Liability</strong>
    </p>
    <p>
      IN NO EVENT WILL THE COMPANY, OUR AFFILIATES OR ANY LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS,
      BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
      USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS
      OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
      PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE,
      LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
      TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. &nbsp;THE FOREGOING DOES NOT AFFECT ANY
      LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
    </p>
    <p>
      <strong>Indemnification</strong>
    </p>
    <p>
      You agree to defend, indemnify and hold harmless the Company, our affiliates, licensors and service providers, and our and
      their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and
      against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable
      attorneys&apos; fees) arising out of or relating to Your violation of these Terms of Service or Your use of the Website,
      including but not limited to, any use of the Website&apos;s content, services and products other than as expressly
      authorized in these Terms of Service or Your use of any information obtained from the Website. &nbsp;Notwithstanding the
      foregoing, if You are part of a Federal or state governmental entity, and the indemnity obligations set forth above are
      impacted by the laws or regulations of Your jurisdiction, then such laws shall take precedence over the indemnity
      obligations.
    </p>
    <p>
      <strong>Governing Law and Jurisdiction</strong>
    </p>
    <p>
      All matters relating to the Website and these Terms of Service and any dispute or claim arising therefrom or related thereto
      (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the
      internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether
      of the State of California or any other jurisdiction).
    </p>
    <p>
      Any legal suit, action or proceeding arising out of, or related to, these Terms of Service or the Website shall be
      instituted exclusively in the federal courts of the United States or the courts of the State of California in each case
      located in the City of Temecula and county of Riverside, although we retain the right to bring any suit, action or
      proceeding against You for breach of these Terms of Service in Your country or state of residence or any other relevant
      country. You waive any and all objections to the exercise of jurisdiction over You by such courts and to venue in such
      courts.
    </p>
    <p>
      <strong>Arbitration</strong>
    </p>
    <p>
      At Company&apos;s sole discretion, we may require You to submit any dispute arising from these Terms of Service or Your use
      of the Website, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance,
      or termination of these Terms of Service, to final and binding arbitration under the Rules of Arbitration of the American
      Arbitration Association applying California law.
    </p>
    <p>
      <strong>Limitation on Time to File Claims</strong>
    </p>
    <p>
      ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF SERVICE OR THE WEBSITE MUST BE
      COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY
      BARRED.&nbsp;
    </p>
    <p>
      <strong>Waiver and Severability</strong>
    </p>
    <p>
      No waiver of by the Company of any term or condition set forth in these Terms of Service shall be deemed a further or
      continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to
      assert a right or provision under these Terms of Service shall not constitute a waiver of such right or provision. &nbsp;If
      any provision of these Terms of Service is held by a court or other tribunal of competent jurisdiction to be invalid,
      illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the
      remaining provisions of the Terms of Service will continue in full force and effect.&nbsp;
    </p>
    <p>
      <strong>Termination</strong>
    </p>
    <p>
      Notwithstanding any term or condition of the Terms of Service, the Company reserves the right, without notice and in our
      sole discretion, to terminate your license to use the Website, and to block or prevent future access to and use of the
      Website.
    </p>
    <p>
      <strong>Entire Agreement</strong>
    </p>
    <p>
      Unless the Company and You have separately entered into a mutually executed agreement specifically governing Your use of the
      Website or any materials contained on the Website, the Terms of Service constitute the sole and entire agreement between You
      and the Company with respect to the Website and supersede all prior and contemporaneous understandings, agreements,
      representations and warranties, both written and oral, with respect to the Website. &nbsp;The Terms of Service apply
      exclusively to Your access to, and use of, the Website and they will not alter any terms or conditions of any other
      agreement You may enter into with the Company.
    </p>
  </LandingPage>
);

export default TermsOfUse;
