import { App, Modal } from 'antd';
import { startCase } from 'lodash';
import React, { FC, useState } from 'react';

import Alert from '@okapi/components/Alert';
import { Role } from '@okapi/domains/Users';
import { useCreateUserMutation } from '@okapi/domains/Users/graphql';
import { CreateUserInput, User } from '@okapi/graphql/types.generated';

import { userRoleLabelMapping } from '../helpers';
import UserForm from '../UserForm';

interface Props {
  role: Role;
  unitId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const AddUser: FC<Props> = ({ onClose, onSuccess, role, unitId }) => {
  const { notification } = App.useApp();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [createUser] = useCreateUserMutation();
  const userLabel: string = userRoleLabelMapping[role];

  const handleSubmit = async ({ loginPath, ...rest }: User) => {
    const user: CreateUserInput = {
      ...rest,
      loginPath: loginPath || null,
      assignedRole: {
        role,
        unitId
      }
    } as CreateUserInput;

    try {
      setErrorMessage('');

      await createUser({ variables: { user }, refetchQueries: ['users'] });

      notification.success({ message: 'Success', description: `${startCase(userLabel)} has been added.` });

      onSuccess();
    } catch (err) {
      setErrorMessage('Something went wrong.');
    }
  };

  return (
    <Modal footer={null} onCancel={onClose} open={true} title={`Add new ${userLabel}`}>
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <UserForm onCancel={onClose} onSubmit={handleSubmit} role={role} />
    </Modal>
  );
};

export default AddUser;
