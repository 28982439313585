import { App, Button, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { startCase } from 'lodash';
import { FC, useState } from 'react';

import Pagination from '@okapi/components/Pagination';
import QueryHandler from '@okapi/components/QueryHandler';
import { Role } from '@okapi/domains/Users';
import { getPaginationParams } from '@okapi/utils/pagination';
import { Nullable } from '@okapi/utils/typescript';

import useAccount from '../Auth/hooks/useAccount';
import AddUser from './AddUser';
import EditUser from './EditUser';
import { useActivateUserMutation, useDeactivateUsersMutation, useUsersQuery } from './graphql';
import { useResendInvitationEmailMutation } from './graphql/resendInvitationEmail.generated';
import { userRoleLabelMapping } from './helpers';
import styles from './styles.module.less';
import { getColumns } from './tableConfig';

interface Props {
  unitId: string;
  role: Role;
}

const Users: FC<Props> = ({ role, unitId }) => {
  const { notification } = App.useApp();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [userIdToEdit, setUserIdToEdit] = useState<Nullable<string>>(null);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false);
  const userLabel: string = userRoleLabelMapping[role];
  const { userProfile } = useAccount();

  const [activateUser] = useActivateUserMutation();
  const [resendInvitationEmail] = useResendInvitationEmailMutation();
  const [deactivateUser] = useDeactivateUsersMutation();
  const { data, loading, error } = useUsersQuery({
    variables: {
      ...getPaginationParams(page),
      filters: {
        roles: [role],
        unitIds: [unitId],
        search: searchTerm
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const users = data?.users?.nodes || [];
  const totalCount = data?.users?.totalCount || 0;

  const handleEdit = (id: string) => {
    setUserIdToEdit(id);
    setShowEditUserModal(true);
  };

  const handleSearch = (val: string) => {
    setSearchTerm(val);
    setPage(1);
  };

  const handleActivate = async (id: string) => {
    try {
      await activateUser({ variables: { userId: id }, refetchQueries: ['users'] });

      notification.success({ message: 'Success', description: `${startCase(userLabel)} has been activated.` });
    } catch (err) {
      notification.error({ message: 'Error', description: 'Something went wrong.' });
    }
  };

  const handleDeactivate = async (id: string) => {
    try {
      await deactivateUser({ variables: { userIds: [id] }, refetchQueries: ['users'] });

      notification.success({ message: 'Success', description: `${startCase(userLabel)} has been deactivated.` });
    } catch (err) {
      notification.error({ message: 'Error', description: 'Something went wrong.' });
    }
  };

  const handleResendInvitationEmail = async (userId: string) => {
    try {
      await resendInvitationEmail({ variables: { userId }, refetchQueries: ['users'] });

      notification.success({ message: 'Success', description: 'Invitation email has been sent.' });
    } catch (err) {
      notification.error({ message: 'Error', description: 'Something went wrong.' });
    }
  };

  const columns = getColumns({
    onEdit: handleEdit,
    onActivate: handleActivate,
    onDeactivate: handleDeactivate,
    onResendInvitationEmail: handleResendInvitationEmail,
    currentUserId: userProfile.id
  });

  const closeAddUserModal = () => setShowAddUserModal(false);
  const closeEditUserModal = () => setShowEditUserModal(false);

  return (
    <>
      <div className={styles.buttonWrapper}>
        <Button onClick={() => setShowAddUserModal(true)} size="large" type="primary">
          Add new {userLabel}
        </Button>
      </div>

      <Search
        allowClear={true}
        className={styles.searchWrapper}
        onSearch={handleSearch}
        placeholder="Search for a first name, last name and email"
        size="large"
      />
      <QueryHandler data={users} error={!!error} isLoading={loading}>
        <Table columns={columns} dataSource={users} pagination={false} rowKey="id" scroll={{ x: 700 }} tableLayout="auto" />
        <Pagination currentPage={page} onChange={setPage} totalCount={totalCount} />
      </QueryHandler>
      {showAddUserModal && <AddUser onClose={closeAddUserModal} onSuccess={closeAddUserModal} role={role} unitId={unitId} />}
      {showEditUserModal && userIdToEdit && (
        <EditUser onClose={closeEditUserModal} onSuccess={closeEditUserModal} role={role} userId={userIdToEdit} />
      )}
    </>
  );
};

export default Users;
