import { Role } from '@okapi/graphql/types.generated';

import useAccount from './useAccount';

type Ability = 'userManagement' | 'dashboard' | 'districts' | 'schools' | 'teachers';

const useAbility = () => {
  const { role } = useAccount();

  const abilities = {
    userManagement: role === Role.ADMIN,
    dashboard: role === Role.ADMIN,
    districts: role === Role.ADMIN,
    schools: role === Role.ADMIN || role === Role.DISTRICT_ADMIN,
    teachers: role === Role.SCHOOL_ADMIN
  };

  return {
    canAccess: (ability: Ability): boolean => abilities[ability]
  };
};

export default useAbility;
