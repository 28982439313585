import { Card, Typography } from 'antd';
import { FC } from 'react';
import CountUp from 'react-countup';
import { Helmet } from 'react-helmet-async';

import QueryHandler from '@okapi/components/QueryHandler';
import { formatNumber } from '@okapi/utils/formatting';

import LabelledValue from '../../components/LabelledValue';
import BarChart from './components/BarChart';
import DistrictsTable from './components/DistrictsTable/DistrictsTable';
import LoginChart from './components/LoginMethodsChart';
import UsersChart from './components/UsersChart';
import { useAnalyticsQuery } from './graphql';
import styles from './styles.module.less';

const Analytics: FC = () => {
  const { data, loading, error } = useAnalyticsQuery({ variables: { page: { skip: 0, take: 20 } } });

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <QueryHandler data={data} error={!!error} isLoading={loading}>
        <>
          <div className={`${styles.totals} ${styles.slideInUp}`}>
            <Card>
              <LabelledValue label="Books in total" value={data?.books.totalCount || 0} />
            </Card>
            <Card>
              <LabelledValue label="Districts in total" value={data?.districts.totalCount || 0} />
            </Card>
            <Card>
              <LabelledValue label="Schools in total" value={data?.schools.totalCount || 0} />
            </Card>
            <Card>
              <LabelledValue label="Teachers in total" value={data?.users.totalCount || 0} />
            </Card>
          </div>

          <div className={styles.slideInUp}>
            <div className={styles.label}>Showing results for the last 7 days</div>
            <div className={styles.users}>
              {data?.analytics?.dailyUsers && (
                <Card>
                  <Typography.Title level={4}>
                    Users this week: <CountUp duration={2} end={data?.analytics?.activeUsers || 0} formattingFn={formatNumber} />
                  </Typography.Title>
                  <UsersChart data={data?.analytics?.dailyUsers} />
                </Card>
              )}
              {data?.analytics?.loginMethods && (
                <Card>
                  <Typography.Title level={4}>Login methods</Typography.Title>
                  <div className={styles.loginChart}>
                    <LoginChart data={data?.analytics?.loginMethods} />
                  </div>
                </Card>
              )}
            </div>
          </div>

          <div className={`${styles.stats} ${styles.slideInUp}`}>
            {data?.analytics?.topBooks && (
              <Card>
                <Typography.Title level={4}>Top 5 viewed books</Typography.Title>
                <BarChart data={data?.analytics?.topBooks} />
              </Card>
            )}
            {data?.analytics?.topSearches && (
              <Card>
                <Typography.Title level={4}>Top 5 searched books</Typography.Title>
                <BarChart data={data?.analytics?.topSearches} />
              </Card>
            )}
            {data?.analytics?.topBookFilters && (
              <Card>
                <Typography.Title level={4}>Filters usage</Typography.Title>
                <BarChart data={data?.analytics?.topBookFilters} />
              </Card>
            )}
          </div>

          {data?.analytics?.districts && (
            <Card>
              <Typography.Title level={4}>Logins by District</Typography.Title>
              <DistrictsTable data={data?.analytics?.districts} />
            </Card>
          )}
        </>
      </QueryHandler>
    </>
  );
};

export default Analytics;
