interface StaticConfig {
  googleTagManagerId: string;
  graphQLEndpointUrl: string;
  isDemoVersion: string;
}
declare global {
  interface Window {
    okapiStaticConfig?: StaticConfig;
  }
}

export const staticConfig = (): Readonly<StaticConfig> => {
  if (!window.okapiStaticConfig) {
    throw new Error('Missing "okapiStaticConfig" on window');
  }

  return window.okapiStaticConfig;
};
