import { ValidationRules } from '../../../types';

export const validationRules: ValidationRules = {
  name: [
    {
      required: true,
      message: 'Please enter district name'
    }
  ]
};
