import { createContext } from 'react';

import type { TrackingEvent } from './types';

interface InitialContext {
  trackEvent: (event: TrackingEvent) => void;
}

const initialContext = {
  trackEvent: () => {}
};

const TrackingContext = createContext<InitialContext>(initialContext);

export default TrackingContext;
