import axios from 'axios';

const API_KEY = 'CJAL-AJAG-BDUC-E7IV';
const API_URL = 'https://survey.myokapi.com/wp-json/frm/v2/entries';

export const collectData = async (payload: object) =>
  axios.post(API_URL, payload, {
    auth: {
      username: API_KEY,
      password: API_KEY
    }
  });
