import { Card, Table, Typography } from 'antd';
import type { FC } from 'react';

import { getResources } from './helpers';
import styles from './styles.module.less';
import { getColumns } from './tableConfig';

const BiliteracyParaTodos: FC = () => (
  <div className={styles.wrapper} data-testid="biliteracy-para-todos">
    <div className={styles.headline}>
      <Typography.Title level={3}>Welcome to the Biliteracy para todos™ Resource Page!</Typography.Title>
      <div>
        Biliteracy para todos™ provides a wealth of online teacher support material available for downloading. Downloads for each
        set include:
      </div>
      <ul>
        <li>Anchor Charts</li>
        <li>Sentence Stems</li>
        <li>Records of Reading/Biliteracy Behaviors</li>
        <li>Graphic Organizers (Advanced Stage Only)</li>
      </ul>
    </div>

    <Card>
      <Table
        columns={getColumns()}
        dataSource={getResources()}
        pagination={false}
        rowKey="isbn"
        scroll={{ x: 700 }}
        size="small"
        tableLayout="auto"
      />
    </Card>
  </div>
);

export default BiliteracyParaTodos;
