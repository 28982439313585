import { Tooltip } from 'antd';
import { FC, ReactChild } from 'react';

import styles from './styles.module.less';

interface Props {
  children: ReactChild;
}

const DisabledLinkTooltip: FC<Props> = ({ children }) => {
  const tooltip = (
    <div>
      This link has been disabled. For access,{' '}
      <a className={styles.link} href="https://myokapi.com/request-trial-form" target="_blank">
        consider a free trial
      </a>{' '}
      for your school.
    </div>
  );

  return (
    <Tooltip placement="topLeft" title={tooltip}>
      {children}
    </Tooltip>
  );
};

export default DisabledLinkTooltip;
