import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateBookLinkMutationVariables = GraphQLTypes.Exact<{
  bookId: GraphQLTypes.Scalars['ID']['input'];
}>;

export type CreateBookLinkMutationResult = { __typename?: 'Mutation' } & {
  createLink: { __typename?: 'Link' } & Pick<GraphQLTypes.Link, 'id'>;
};

export const CreateBookLinkDocument = /* #__PURE__ */ gql`
  mutation createBookLink($bookId: ID!) {
    createLink(bookId: $bookId) {
      id
    }
  }
`;

/**
 * __useCreateBookLinkMutation__
 *
 * To run a mutation, you first call `useCreateBookLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookLinkMutation, { data, loading, error }] = useCreateBookLinkMutation({
 *   variables: {
 *      bookId: // value for 'bookId'
 *   },
 * });
 */
export function useCreateBookLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBookLinkMutationResult, CreateBookLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateBookLinkMutationResult, CreateBookLinkMutationVariables>(CreateBookLinkDocument, options);
}
export type CreateBookLinkMutationHookResult = ReturnType<typeof useCreateBookLinkMutation>;
