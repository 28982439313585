import { DownOutlined } from '@ant-design/icons';
import { Avatar, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/interface';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import useAbility from '@okapi/domains/Auth/hooks/useAbility';
import useAccount from '@okapi/domains/Auth/hooks/useAccount';

import styles from './styles.module.less';

interface Props {
  className?: string;
}

const UserMenu: FC<Props> = ({ className }) => {
  const { userProfile, logout } = useAccount();
  const { canAccess } = useAbility();
  const { firstName, lastName } = userProfile;

  const items: ItemType[] = [
    ...(canAccess('userManagement')
      ? [{ key: 'userManagement', label: <Link to="/user-management">User Management</Link> }]
      : []),
    { key: 'logout', onClick: logout, label: 'Logout' }
  ];

  return (
    <Dropdown arrow={true} className={className} menu={{ items }} placement="bottomRight" trigger={['click']}>
      <div className={styles.menuWrapper}>
        <Avatar className={styles.avatar} size={36}>
          {firstName.charAt(0)}
          {lastName.charAt(0)}
        </Avatar>
        <div className={styles.userName}>
          {firstName} {lastName}
        </div>
        <span className={styles.icon}>
          <DownOutlined />
        </span>
      </div>
    </Dropdown>
  );
};

export default UserMenu;
