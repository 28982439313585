import { Select } from 'antd';
import { FC } from 'react';

import { Option } from '@okapi/types';
import { Optional } from '@okapi/utils/typescript';

import { useDistrictsQuery } from '../../graphql';
import { getOptions } from './helpers';

interface Props {
  name: string;
}

const DistrictSelect: FC<Props> = (props) => {
  const { data } = useDistrictsQuery({ fetchPolicy: 'cache-and-network' });

  const districtOptions: Option[] = getOptions(data?.districts?.nodes || []);

  const handleFilter = (inputValue: string, option: Optional<Option>): boolean =>
    !!option?.label.toLowerCase().includes(inputValue?.toLowerCase());

  return (
    <Select {...props} allowClear={true} filterOption={handleFilter} options={districtOptions} showSearch={true} size="large" />
  );
};

export default DistrictSelect;
