export const validationRules = {
  password: [
    {
      required: true,
      message: 'Please input your password'
    },
    {
      min: 6,
      message: 'Password must contain at least 6 characters'
    }
  ],
  confirmPassword: [
    {
      required: true,
      message: 'Please confirm your password'
    },
    ({ getFieldValue }: { getFieldValue: (name: string) => string }) => ({
      validator: (_: unknown, value: string) => {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }

        return Promise.reject('The two passwords that you entered do not match');
      }
    })
  ],
  terms: [
    {
      validator: (_: unknown, value: string[]) => {
        if (value && value.length === 2) {
          return Promise.resolve();
        }

        return Promise.reject('Please accept TOU and Privacy Policy');
      }
    }
  ]
};
