import biliteracyImageUrl from './images/biliteracy.png';
import investigationsImageUrl from './images/investigations.png';
import lessonPlansImageUrl from './images/lesson-plans.png';
import phonicsImageUrl from './images/phonics.png';
import testPreparationsImageUrl from './images/test-preparations.png';
import { Resource } from './types';

export const resources: Resource[] = [
  {
    title: 'Flying Start to Literacy: PHONICS',
    imageUrl: phonicsImageUrl,
    description: 'Printable rhymes, practice sentences, word-picture cards, and assessments to augment your phonics instruction.',
    url: '/resources/flying-start-to-literacy-phonics'
  },
  {
    title: 'Biliteracy para todos™',
    imageUrl: biliteracyImageUrl,
    description:
      'Essential instructional templates in both Spanish and English that include Vocabulary Anchor Charts, Sentence Stems, Records of Biliteracy Behaviors, and more!',
    url: '/resources/biliteracy-para-todos'
  },
  {
    title: 'Lesson Plans',
    imageUrl: lessonPlansImageUrl,
    description:
      'Access English or Spanish lesson plans for every title, available in two versions: our National Lesson Plans or our TEKS Lesson Plans for Texas educators!',
    url: '/resources/lesson-plans'
  },
  {
    title: 'Investigations / Investigaciones',
    imageUrl: investigationsImageUrl,
    description:
      'Inquiry-driven, independent project frameworks for student use with WorldWise: Content-Based Learning™ and ExploraMundos™ books at Grades 3-5.',
    url: '/resources/investigations'
  },
  {
    title: 'Texas Test Prep',
    imageUrl: testPreparationsImageUrl,
    description: (
      <>
        Activities to support the State of Texas Assessments of Academic Readiness. <br />
        Practical guidance for working with paired text selections. Available in both English and Spanish!
      </>
    ),
    url: '/resources/test-preparations'
  }
];
