import { Button, Col, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { FC } from 'react';

import { FormButtons } from '@okapi/components/Form';

import { ReportIssueFormFields } from './types';
import { validationRules } from './validationRules';

interface Props {
  initialValues: ReportIssueFormFields;
  isSubmitting: boolean;
  onCancel: () => void;
  onSubmit: (values: ReportIssueFormFields) => void;
}

const ReportIssueForm: FC<Props> = ({ initialValues, isSubmitting, onCancel, onSubmit }) => (
  <Form data-testid="report-issue-form" initialValues={initialValues} layout="vertical" onFinish={onSubmit}>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item label="Name" name="name" rules={validationRules.name}>
          <Input size="large" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Email" name="email" rules={validationRules.email}>
          <Input size="large" />
        </Form.Item>
      </Col>
    </Row>
    <Form.Item label="Subject" name="subject" rules={validationRules.subject}>
      <Input size="large" />
    </Form.Item>
    <Form.Item label="Description" name="description" rules={validationRules.description}>
      <TextArea rows={8} />
    </Form.Item>
    <FormButtons isSubmitting={isSubmitting} text="Report an Issue">
      <Button onClick={onCancel} type="default">
        Cancel
      </Button>
    </FormButtons>
  </Form>
);

export default ReportIssueForm;
