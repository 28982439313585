import { ThemeConfig } from 'antd';

export const theme: ThemeConfig = {
  hashed: false,
  token: {
    borderRadius: 5,
    colorLink: '#005f90',
    colorPrimary: '#005f90',
    controlItemBgActive: '#ddecf8',
    controlItemBgActiveHover: '#d5e7f6',
    fontFamily: 'Poppins, sans-serif;',
    fontSizeLG: 14,
    fontWeightStrong: 500,
    paddingXS: 8
  }
};
