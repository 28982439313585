import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateDistrictMutationVariables = GraphQLTypes.Exact<{
  district: GraphQLTypes.CreateDistrictInput;
}>;

export type CreateDistrictMutationResult = { __typename?: 'Mutation' } & {
  createDistrict: { __typename?: 'District' } & Pick<GraphQLTypes.District, 'id'>;
};

export const CreateDistrictDocument = /* #__PURE__ */ gql`
  mutation createDistrict($district: CreateDistrictInput!) {
    createDistrict(district: $district) {
      id
    }
  }
`;

/**
 * __useCreateDistrictMutation__
 *
 * To run a mutation, you first call `useCreateDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDistrictMutation, { data, loading, error }] = useCreateDistrictMutation({
 *   variables: {
 *      district: // value for 'district'
 *   },
 * });
 */
export function useCreateDistrictMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDistrictMutationResult, CreateDistrictMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateDistrictMutationResult, CreateDistrictMutationVariables>(CreateDistrictDocument, options);
}
export type CreateDistrictMutationHookResult = ReturnType<typeof useCreateDistrictMutation>;
