import React, { FC } from 'react';
import { Outlet } from 'react-router';

import Header from '@okapi/components/Header';

import UserProvider from '../../domains/Auth/UserProvider';
import DemoAccessPopup from '../DemoLifetimeAccess/DemoAccessPopup';
import Footer from '../Footer';
import styles from './styles.module.less';

const Layout: FC = () => (
  <UserProvider>
    <Header />
    <div className={styles.content}>
      <Outlet />
      <DemoAccessPopup />
    </div>
    <Footer />
  </UserProvider>
);

export default Layout;
