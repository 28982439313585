import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';

const Districts: FC = () => (
  <>
    <Helmet>
      <title>Districts</title>
    </Helmet>
    <Outlet />
  </>
);

export default Districts;
