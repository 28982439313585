import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AuthorizeEdlinkMutationVariables = GraphQLTypes.Exact<{
  code: GraphQLTypes.Scalars['String']['input'];
  state?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']['input']>;
}>;

export type AuthorizeEdlinkMutationResult = { __typename?: 'Mutation' } & {
  edlink: { __typename?: 'Token' } & Pick<GraphQLTypes.Token, 'accessToken'>;
};

export const AuthorizeEdlinkDocument = /* #__PURE__ */ gql`
  mutation authorizeEdlink($code: String!, $state: String = null) {
    edlink(code: $code, state: $state) {
      accessToken
    }
  }
`;

/**
 * __useAuthorizeEdlinkMutation__
 *
 * To run a mutation, you first call `useAuthorizeEdlinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeEdlinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeEdlinkMutation, { data, loading, error }] = useAuthorizeEdlinkMutation({
 *   variables: {
 *      code: // value for 'code'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useAuthorizeEdlinkMutation(
  baseOptions?: Apollo.MutationHookOptions<AuthorizeEdlinkMutationResult, AuthorizeEdlinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<AuthorizeEdlinkMutationResult, AuthorizeEdlinkMutationVariables>(AuthorizeEdlinkDocument, options);
}
export type AuthorizeEdlinkMutationHookResult = ReturnType<typeof useAuthorizeEdlinkMutation>;
