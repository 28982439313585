import { Alert as AlertComponent } from 'antd';
import cx from 'classnames';
import React, { FC, ReactNode } from 'react';

import styles from './styles.module.less';

interface Props {
  type: 'success' | 'error';
  message: ReactNode;
}

const Alert: FC<Props> = ({ message, type }) => {
  const classNames = cx(
    {
      [styles.success]: type === 'success',
      [styles.error]: type === 'error'
    },
    styles.alert
  );

  return <AlertComponent className={classNames} message={message} type={type} />;
};

export default Alert;
