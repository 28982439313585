import resources from './resources.csv';
import { RawTestPreparation, TestPreparation } from './types';

export const AWS_HOSTNAME = 'https://okapi-digital-literacy-assets.s3.amazonaws.com';

export const getResourceUrl = (assetId: string): string => `${AWS_HOSTNAME}/resources/${assetId}/index.html`;

export const getRawResourceUrl = (assetId: string): string => `${AWS_HOSTNAME}/raw-resources/${assetId}.pdf`;

export const getResources = (): TestPreparation[] =>
  resources.map((resource: RawTestPreparation) => ({
    title: resource.TITLE,
    gradeLevel: resource['GRADE LEVEL'],
    language: resource.LANGUAGE,
    isbn: resource['ITEM ISBN'],
    asset: resource['ASSET ID']
  }));
