import { User } from '@okapi/graphql/types.generated';
import { Optional } from '@okapi/utils/typescript';

import { isAdmin, isDistrictAdmin, isSchoolAdmin, isTeacher } from '../Auth';
import { UserFormInitialValues, UserFormValues } from './UserForm/types';

export const prepareInitialValues = (user: Optional<User>): UserFormInitialValues => {
  if (!user) {
    return {
      firstName: '',
      lastName: '',
      email: '',
      role: null,
      schoolId: '',
      districtId: '',
      externalId: ''
    };
  }

  const { firstName, lastName, email, assignedRole, externalId } = user;

  return {
    firstName,
    lastName,
    email: email || '',
    externalId: externalId?.id,
    role: assignedRole.role,
    schoolId: (isSchoolAdmin(assignedRole.role) || isTeacher(assignedRole.role)) && !externalId ? assignedRole.unitId : '',
    districtId: isDistrictAdmin(assignedRole.role) || externalId ? assignedRole.unitId : ''
  };
};

export const getUnitId = ({ districtId, role, schoolId, externalId }: UserFormValues, adminUnitId: string) => {
  if (isAdmin(role)) return adminUnitId;

  return isDistrictAdmin(role) || externalId ? districtId : schoolId;
};
