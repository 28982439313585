import { Card } from 'antd';
import cx from 'classnames';
import React, { CSSProperties, FC, SyntheticEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useApp from '@okapi/App/hooks/useApp';

import { getBookThumbnail } from '../../helpers';
import { Book } from '../types';
import styles from './styles.module.less';

interface Props {
  book: Book;
}

const BookListItem: FC<Props> = ({ book }) => {
  const navigate = useNavigate();
  const { isDemoVersion } = useApp();
  const { asset, id, title, lessonPlan, includedInDemo } = book;

  const cardStyles: { body: CSSProperties } = { body: { flex: 1, padding: 0, display: 'flex', flexDirection: 'column' } };

  const handleClick = (event: SyntheticEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    navigate(`/books/${lessonPlan?.id}`);
  };

  if ((isDemoVersion && !includedInDemo) || !asset) {
    return (
      <Card
        className={cx(styles.card, styles.notAvailable)}
        cover={<img alt={title} src={getBookThumbnail(id)} />}
        hoverable={false}
        size="small"
        styles={cardStyles}
      >
        <div className={styles.title}>{title}</div>
        {lessonPlan && (
          <span key={lessonPlan.id} className={styles.link}>
            See Lesson Plan
          </span>
        )}
      </Card>
    );
  }

  return (
    <Link className={styles.wrapper} to={`/books/${id}`}>
      <Card
        className={styles.card}
        cover={<img alt={title} src={getBookThumbnail(id)} />}
        hoverable={true}
        size="small"
        styles={cardStyles}
      >
        <div className={styles.title}>{title}</div>
        {lessonPlan && (
          <span key={lessonPlan.id} className={styles.link} onClick={handleClick}>
            See Lesson Plan
          </span>
        )}
      </Card>
    </Link>
  );
};

export default BookListItem;
