import { App, Card, Tabs } from 'antd';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';

import Alert from '@okapi/components/Alert';
import QueryHandler from '@okapi/components/QueryHandler';
import useAccount from '@okapi/domains/Auth/hooks/useAccount';
import Subscriptions from '@okapi/domains/Subscriptions';
import Users from '@okapi/domains/Users';
import { Role, School } from '@okapi/graphql/types.generated';

import { useSchoolQuery, useUpdateSchoolMutation } from '../graphql';
import SchoolForm from '../SchoolForm';
import { prepareInitialValues } from './helpers';

interface UrlParams {
  unitId: string;
}

const EditSchool: FC = () => {
  const { notification } = App.useApp();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { unitId } = useParams<{ unitId: string }>() as UrlParams;
  const { role } = useAccount();
  const { data, error, loading } = useSchoolQuery({ variables: { id: unitId } });
  const [updateSchool] = useUpdateSchoolMutation();
  const navigate = useNavigate();

  const schoolData = data?.school;

  const handleSubmit = async (school: School & { districtId: string }) => {
    try {
      setErrorMessage('');

      await updateSchool({ variables: { school } });

      notification.success({ message: 'Success', description: 'School has been updated.' });

      navigate('/schools');
    } catch (err) {
      setErrorMessage('Something went wrong.');
    }
  };

  const handleCancel = () => navigate('/schools');

  const items = [
    {
      label: 'General information',
      key: 'general-information',
      children: (
        <>
          {errorMessage && <Alert message={errorMessage} type="error" />}
          <SchoolForm initialValues={prepareInitialValues(schoolData)} onCancel={handleCancel} onSubmit={handleSubmit} />
        </>
      )
    },
    ...(role === Role.ADMIN
      ? [
          {
            key: 'subscriptions',
            label: 'Subscriptions',
            children: <Subscriptions unitId={unitId} />
          }
        ]
      : []),
    {
      label: 'Admins',
      key: 'admins',
      children: <Users role={Role.SCHOOL_ADMIN} unitId={unitId} />
    },
    {
      label: 'Teachers',
      key: 'teachers',
      children: <Users role={Role.TEACHER} unitId={unitId} />
    }
  ];

  return (
    <>
      <Helmet>
        <title>Edit school</title>
      </Helmet>
      <QueryHandler data={data} error={!!error} isLoading={loading}>
        <Card title={`Edit school: ${schoolData?.name}`}>
          <Tabs defaultActiveKey="general-information" items={items} />
        </Card>
      </QueryHandler>
    </>
  );
};

export default EditSchool;
