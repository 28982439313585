import { Checkbox, Form, Input } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import FormButtons from '@okapi/components/Form/FormButtons';

import { validationRules } from './validationRules';

interface Props {
  isRegistration?: boolean;
  onSubmit: (value: { password: string }) => void;
  showBackLink?: boolean;
}

const options = [
  {
    label: (
      <>
        I agree to the{' '}
        <Link target="_blank" to="/terms-of-use">
          Terms of Use
        </Link>
      </>
    ),
    value: 'terms-of-use'
  },
  {
    label: (
      <>
        I agree to the{' '}
        <Link target="_blank" to="/privacy-policy">
          Privacy Policy
        </Link>
      </>
    ),
    value: 'privacy-policy'
  }
];

const UpdatePasswordForm: FC<Props> = ({ isRegistration = false, onSubmit, showBackLink = true }) => (
  <Form onFinish={onSubmit}>
    <Form.Item name="password" rules={validationRules.password} validateTrigger="onBlur">
      <Input.Password placeholder="New password" size="large" />
    </Form.Item>
    <Form.Item name="confirmPassword" rules={validationRules.confirmPassword} validateTrigger="onBlur">
      <Input.Password placeholder="Retype new password" size="large" />
    </Form.Item>
    {isRegistration && (
      <Form.Item name="terms" rules={validationRules.terms} validateTrigger="onSubmit">
        <Checkbox.Group options={options} />
      </Form.Item>
    )}
    <FormButtons>{showBackLink && <Link to="/login">Back to login</Link>}</FormButtons>
  </Form>
);

export default UpdatePasswordForm;
