import './assets/app.less';

import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import GoogleTagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { staticConfig } from './utils/staticConfig';

GoogleTagManager.initialize({ gtmId: staticConfig().googleTagManagerId });

const rootElement = document.getElementById('root') as HTMLDivElement;

const root: Root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
