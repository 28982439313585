import { School } from '@okapi/graphql/types.generated';
import { Optional } from '@okapi/utils/typescript';

export const prepareInitialValues = (values: Optional<School>) => {
  if (!values) {
    return {};
  }

  const { district, ...rest } = values;

  return {
    ...rest,
    districtId: district?.id
  };
};
