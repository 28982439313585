import { Empty, Typography } from 'antd';
import React, { FC } from 'react';

import styles from './styles.module.less';

interface Props {
  text?: string;
  tagline?: string;
}

const NoResults: FC<Props> = ({ text = 'No results found.', tagline }) => (
  <div className={styles.wrapper}>
    <div>
      {Empty.PRESENTED_IMAGE_DEFAULT}
      <Typography.Title level={4}>{text}</Typography.Title>
      {tagline && <div>{tagline}</div>}
    </div>
  </div>
);

export default NoResults;
