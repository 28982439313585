import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ActivateUserMutationVariables = GraphQLTypes.Exact<{
  userId: GraphQLTypes.Scalars['ID']['input'];
}>;

export type ActivateUserMutationResult = { __typename?: 'Mutation' } & Pick<GraphQLTypes.Mutation, 'activateUsers'>;

export const ActivateUserDocument = /* #__PURE__ */ gql`
  mutation activateUser($userId: ID!) {
    activateUsers(userIds: [$userId])
  }
`;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useActivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateUserMutationResult, ActivateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ActivateUserMutationResult, ActivateUserMutationVariables>(ActivateUserDocument, options);
}
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
