import { Divider } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { getRawResourceUrl } from '@okapi/domains/Books/helpers';

import { RawResource } from '../types';

export const getColumns = (): ColumnsType<RawResource> => [
  {
    title: 'Title',
    dataIndex: 'title',
    render: (title: string) => title,
    width: 220
  },
  {
    title: 'Series',
    dataIndex: ['series', 'value'],
    width: 140
  },
  {
    title: 'Stage',
    dataIndex: ['stage', 'value'],
    width: 180
  },
  {
    title: 'Actions',
    align: 'right',
    key: 'actions',
    fixed: 'right',
    width: 220,
    render: (_, { asset, teksLessonPlan }) => (
      <>
        {asset && (
          <a href={getRawResourceUrl(asset, 'lessonPlan')} rel="noopener noreferrer" target="_blank">
            Lesson Plan
          </a>
        )}
        <Divider type="vertical" />
        {teksLessonPlan?.asset && (
          <a href={getRawResourceUrl(teksLessonPlan.asset, 'lessonPlan')} rel="noopener noreferrer" target="_blank">
            TEKS Lesson Plan
          </a>
        )}
      </>
    )
  }
];
