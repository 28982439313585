import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type SchoolQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['ID']['input'];
}>;

export type SchoolQueryResult = { __typename?: 'Query' } & {
  school: { __typename?: 'School' } & Pick<
    GraphQLTypes.School,
    'id' | 'name' | 'city' | 'state' | 'zip' | 'address' | 'active'
  > & {
      district: { __typename?: 'District' } & Pick<GraphQLTypes.District, 'id' | 'name' | 'active'>;
    };
};

export const SchoolDocument = /* #__PURE__ */ gql`
  query school($id: ID!) {
    school(id: $id) {
      id
      name
      city
      state
      district {
        id
        name
        active
      }
      zip
      address
      active
    }
  }
`;

/**
 * __useSchoolQuery__
 *
 * To run a query within a React component, call `useSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolQuery(
  baseOptions: Apollo.QueryHookOptions<SchoolQueryResult, SchoolQueryVariables> &
    ({ variables: SchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<SchoolQueryResult, SchoolQueryVariables>(SchoolDocument, options);
}
export function useSchoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolQueryResult, SchoolQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<SchoolQueryResult, SchoolQueryVariables>(SchoolDocument, options);
}
export function useSchoolSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SchoolQueryResult, SchoolQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<SchoolQueryResult, SchoolQueryVariables>(SchoolDocument, options);
}
export type SchoolQueryHookResult = ReturnType<typeof useSchoolQuery>;
export type SchoolLazyQueryHookResult = ReturnType<typeof useSchoolLazyQuery>;
export type SchoolSuspenseQueryHookResult = ReturnType<typeof useSchoolSuspenseQuery>;
