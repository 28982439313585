import { Typography } from 'antd';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

import BookViewer from '@okapi/components/BookViewer';
import QueryHandler from '@okapi/components/QueryHandler';
import ScrollToTop from '@okapi/components/ScrollToTop';
import ShareBookLink from '@okapi/domains/Books/components/ShareBookLink';
import { useBookQuery } from '@okapi/domains/Books/graphql';
import { getBookUrl } from '@okapi/domains/Books/helpers';
import { useShareBook } from '@okapi/domains/Books/hooks/useShareBook';
import { BookTypeEnum } from '@okapi/graphql/types.generated';
import useTracking from '@okapi/useTracking';

import { ResourcesTrackingCategory } from '../../types';
import styles from './styles.module.less';

const InvestigationDetails: FC = () => {
  const { id } = useParams<{ id: string }>() as { id: string };
  const { data, loading, error } = useBookQuery({ variables: { id } });
  const { trackEvent } = useTracking();
  const { shareBook } = useShareBook();

  const book = data?.book;

  useEffect(() => {
    if (!book) return;

    trackEvent({
      category: ResourcesTrackingCategory,
      action: 'View Investigation',
      label: book.title
    });
  }, [book, trackEvent]);

  const handleShare = (): void => {
    shareBook(id);

    trackEvent({
      category: ResourcesTrackingCategory,
      action: 'Share Investigation',
      label: book?.title
    });
  };

  return (
    <div className={styles.wrapper}>
      <QueryHandler data={data?.book} error={!!error} isLoading={loading}>
        <ScrollToTop />
        <Helmet>
          <title>{book?.title}</title>
        </Helmet>
        <div className={styles.header}>
          <Typography.Title level={3}>{book?.title}</Typography.Title>
          <div className={styles.links}>
            <ShareBookLink bookType={BookTypeEnum.INVESTIGATIONS} hasVideoVersion={false} onShareButtonClick={handleShare} />
          </div>
        </div>
        <BookViewer title={book?.title || ''} url={getBookUrl(book?.asset || '')} />
      </QueryHandler>
    </div>
  );
};

export default InvestigationDetails;
