import dayjs from 'dayjs';

import { Subscription } from './types';

export const prepareInitialValues = (subscription?: Subscription) => {
  const today = dayjs();

  if (!subscription) {
    return {
      from: today,
      to: dayjs(`${dayjs().add(1, 'year').format('YYYY')}-06-30`),
      items: []
    };
  }

  const { from, to, items } = subscription;

  return {
    from: dayjs(from),
    to: dayjs(to),
    items: items.map(({ series, stage }) => `${series.key}|${stage.key}`)
  };
};

export const prepareRequestPayload = ({ from, to, items: subscriptions }: any) => {
  const items = subscriptions
    .filter((item: string) => item.includes('|'))
    .map((item: string) => {
      const [series, stage] = item.split('|');

      return {
        series,
        stage
      };
    });

  return {
    from: from.format('YYYY-MM-DD'),
    to: to.format('YYYY-MM-DD'),
    items
  };
};
