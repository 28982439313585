import { FilterOutlined } from '@ant-design/icons';
import { Badge, Button, Form, Popover, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { FC, useState } from 'react';

import CheckboxGroup from '@okapi/components/Form/CheckboxGroup';
import MultiSelect from '@okapi/components/Form/MultiSelect/MultiSelect';
import { BooksFilters } from '@okapi/graphql/types.generated';

import { ResourceFilters } from '../types';
import styles from './styles.module.less';

interface Props {
  filterOptions: ResourceFilters;
  initialFilters: BooksFilters;
  onClearAll: () => void;
  onFilterChange: (selectedFilters: BooksFilters) => void;
  onSearch: (searchTerm: string) => void;
}

const Filters: FC<Props> = ({ filterOptions, initialFilters, onClearAll, onFilterChange, onSearch }) => {
  const [isOpen, setOpen] = useState(false);
  const [form] = Form.useForm();

  const handleClearFilters = () => {
    form.setFieldsValue(initialFilters);
    onClearAll();
  };
  const activeFilters: number[][] = Object.values(form.getFieldsValue());
  const appliedFiltersCount = activeFilters.reduce((prev: number, curr: number[]) => prev + curr.length, 0);

  const content = (
    <Form className={styles.filters} form={form} initialValues={initialFilters} onValuesChange={onFilterChange}>
      <div className={styles.headline}>
        <span>Filter by</span>
        <Typography.Link onClick={handleClearFilters}>Clear all</Typography.Link>
      </div>
      <div>
        <div className={styles.filterTitle}>Series</div>
        <CheckboxGroup name="series" options={filterOptions.series} />
      </div>
      <div>
        <div className={styles.filterTitle}>Stage</div>
        <MultiSelect dropdownWidth={250} name="stage" options={filterOptions.stages} />
      </div>
    </Form>
  );

  return (
    <div className={styles.wrapper}>
      <Search allowClear={true} className={styles.searchWrapper} onSearch={onSearch} placeholder="Search..." size="large" />
      <Popover arrow={false} content={content} onOpenChange={setOpen} open={isOpen} placement="bottomRight">
        <Button className={styles.filterButton} size="large" type="primary">
          <FilterOutlined />
          Filters
          {appliedFiltersCount > 0 && <Badge className={styles.badge} color="white" count={appliedFiltersCount} size="default" />}
        </Button>
      </Popover>
    </div>
  );
};

export default Filters;
