import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type MeQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type MeQueryResult = { __typename?: 'Query' } & {
  me: { __typename?: 'Me' } & {
    user: { __typename?: 'User' } & Pick<GraphQLTypes.User, 'id' | 'email' | 'firstName' | 'lastName' | 'termsAccepted'> & {
        assignedRole: { __typename?: 'AssignedRole' } & Pick<GraphQLTypes.AssignedRole, 'role' | 'unitId'>;
      };
    district?: GraphQLTypes.Maybe<{ __typename?: 'District' } & Pick<GraphQLTypes.District, 'id' | 'name'>>;
    school?: GraphQLTypes.Maybe<{ __typename?: 'School' } & Pick<GraphQLTypes.School, 'id' | 'name'>>;
  };
};

export const MeDocument = /* #__PURE__ */ gql`
  query me {
    me {
      user {
        id
        email
        firstName
        lastName
        termsAccepted
        assignedRole {
          role
          unitId
        }
      }
      district {
        id
        name
      }
      school {
        id
        name
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQueryResult, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<MeQueryResult, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQueryResult, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<MeQueryResult, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQueryResult, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<MeQueryResult, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
