import { App, Modal } from 'antd';
import React, { FC, useState } from 'react';

import { FormHeadline } from '@okapi/components/Form';
import useAccount from '@okapi/domains/Auth/hooks/useAccount';
import { collectData } from '@okapi/utils/collectData';

import { prepareInitialValues, preparePayload } from './helpers';
import ReportIssueForm from './ReportIssueForm';
import { ReportIssueFormFields } from './ReportIssueForm/types';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const ReportIssueModal: FC<Props> = ({ isVisible, onClose }) => {
  const { notification } = App.useApp();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { userProfile } = useAccount();

  const handleSubmit = async (values: ReportIssueFormFields): Promise<void> => {
    try {
      setIsSubmitting(true);

      const payload = preparePayload(values);

      await collectData(payload);

      notification.success({
        message: 'Success',
        description: 'Thank you for reporting the issue! We will get back to you as soon as possible.'
      });
    } catch (err) {
      notification.error({ message: 'Error', description: 'Something went wrong. Please try again later.' });
    }

    setIsSubmitting(false);
    onClose();
  };

  return (
    <Modal
      data-testid="report-issue-modal"
      destroyOnClose={true}
      footer={null}
      onCancel={onClose}
      open={isVisible}
      title={null}
      width={600}
    >
      <FormHeadline
        tagline="Please provide as many details as possible so we can better identify the issue."
        text="Report an Issue"
      />
      <ReportIssueForm
        initialValues={prepareInitialValues(userProfile)}
        isSubmitting={isSubmitting}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default ReportIssueModal;
