import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

import NotAuthorized from '@okapi/components/NotAuthorized';
import Pagination from '@okapi/components/Pagination';
import QueryHandler from '@okapi/components/QueryHandler';
import ScrollToTop from '@okapi/components/ScrollToTop';
import Spinner from '@okapi/components/Spinner';
import { BooksFilters } from '@okapi/graphql/types.generated';
import { getPaginationParams } from '@okapi/utils/pagination';

import Filters from '../components/Filters';
import { useBookFiltersQuery, useBooksQuery } from '../graphql';
import BookListItem from './BookListItem';
import styles from './styles.module.less';

export const initialFilters: BooksFilters = {
  bookType: [],
  curriculumConnection: [],
  language: [],
  level: [],
  series: [],
  stage: []
};

const BookList: FC = () => {
  const [page, setPage] = useSessionStorage<number>('book-page', 1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [appliedFilters, setAppliedFilters] = useSessionStorage('book-filters', initialFilters);
  const { data: filtersData, loading: loadingFilters } = useBookFiltersQuery();
  const { data, loading, error } = useBooksQuery({
    variables: {
      filters: appliedFilters,
      ...getPaginationParams(page)
    }
  });

  const books = data?.books.nodes || [];
  const totalCount = data?.books?.totalCount || 0;
  const filterOptions = filtersData?.filters;
  const bookSeriesFromUrl = searchParams.getAll('series');

  useEffect(() => {
    if (bookSeriesFromUrl.length === 0) return;

    setAppliedFilters({ ...initialFilters, series: bookSeriesFromUrl.map(Number) });
    setSearchParams({});
  }, [bookSeriesFromUrl.length]);

  if (loadingFilters) {
    return <Spinner />;
  }

  const handleFiltersChange = (filters: BooksFilters) => {
    setAppliedFilters(filters);
    setPage(1);
  };

  const handleClearFilters = () => {
    setAppliedFilters({ ...initialFilters, language: [] });
    setPage(1);
  };

  if (error) {
    const subtitle = (
      <>
        To be able to continue to access the portal, contact our <a href="mailto:info@myokapi.com">customer team</a> anytime.
      </>
    );

    return <NotAuthorized showBackButton={false} subTitle={subtitle} title="Oh no! Your subscription has expired" />;
  }

  return (
    <div>
      <ScrollToTop dependency={page} />
      <Helmet>
        <title>Book Browse</title>
      </Helmet>
      <div className={styles.listingWrapper}>
        <div className={styles.filters}>
          {filterOptions && (
            <Filters
              filterOptions={filterOptions}
              initialValues={appliedFilters}
              onChange={handleFiltersChange}
              onClearFilters={handleClearFilters}
            />
          )}
        </div>
        <QueryHandler data={books} error={!!error} isLoading={loading}>
          <div className={styles.list}>
            {books.map((book) => (
              <BookListItem key={book.id} book={book} />
            ))}
          </div>
        </QueryHandler>
      </div>
      <Pagination currentPage={page} onChange={setPage} totalCount={totalCount} />
    </div>
  );
};

export default BookList;
