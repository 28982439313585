import { Nullable } from '@okapi/utils/typescript';

import { Role } from './types';

export const isAdmin = (role: Nullable<Role>): boolean => role === Role.ADMIN;

export const isDistrictAdmin = (role: Nullable<Role>): boolean => role === Role.DISTRICT_ADMIN;

export const isSchoolAdmin = (role: Nullable<Role>): boolean => role === Role.SCHOOL_ADMIN;

export const isTeacher = (role: Nullable<Role>): boolean => role === Role.TEACHER;

export const roleLabelMapping: Record<Role, string> = {
  [Role.ADMIN]: 'Okapi admin',
  [Role.DISTRICT_ADMIN]: 'District admin',
  [Role.SCHOOL_ADMIN]: 'School admin',
  [Role.TEACHER]: 'Teacher'
};
