import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/interface';
import type { FC } from 'react';

import styles from './styles.module.less';

interface Props {
  items: ItemType[];
}

const DropdownMenu: FC<Props> = ({ items }) => (
  <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
    <Button className={styles.button} icon={<MoreOutlined />} type="link" />
  </Dropdown>
);

export default DropdownMenu;
