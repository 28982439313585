import { User } from '@okapi/graphql/types.generated';

import { ReportIssueFormFields } from './ReportIssueForm/types';

export const preparePayload = ({ name, description, email, subject }: ReportIssueFormFields) => ({
  form_id: 44,
  546: name,
  551: email,
  553: subject,
  556: description
});

export const prepareInitialValues = (profile: Pick<User, 'firstName' | 'lastName' | 'email'>): ReportIssueFormFields => {
  const { firstName, lastName, email } = profile;

  return {
    name: `${firstName} ${lastName}`,
    email: email || '',
    subject: '',
    description: ''
  };
};
