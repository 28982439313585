import { QuestionCircleOutlined } from '@ant-design/icons';
import { Divider, Popconfirm, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './SchoolList/styles.module.less';
import { School } from './types';

interface Params {
  onActivate: (id: string) => void;
  onDeactivate: (id: string) => void;
}

export const getColumns = ({ onActivate, onDeactivate }: Params): ColumnsType<School> => [
  {
    title: 'School name',
    dataIndex: 'name'
  },
  {
    title: 'District',
    dataIndex: ['district', 'name']
  },
  {
    title: 'City',
    dataIndex: 'city'
  },
  {
    title: 'State',
    dataIndex: 'state'
  },
  {
    title: 'Zip code',
    dataIndex: 'zip'
  },
  {
    title: 'Status',
    dataIndex: 'active',
    render: (active) => {
      if (!active) {
        return <Tag color="volcano">inactive</Tag>;
      }

      return <Tag color="green">active</Tag>;
    }
  },
  {
    title: 'Actions',
    align: 'right',
    key: 'actions',
    fixed: 'right',
    width: 160,
    render: ({ active, district, id }) => {
      if (!district.active) {
        return (
          <Tooltip placement="topLeft" title="The school's district is deactivated.">
            <QuestionCircleOutlined />
          </Tooltip>
        );
      }

      if (!active) {
        return (
          <Popconfirm
            cancelText="No"
            okText="Yes"
            onConfirm={() => onActivate(id)}
            placement="topLeft"
            title="Are you sure you want to activate this school?"
          >
            <Typography.Link>Activate</Typography.Link>
          </Popconfirm>
        );
      }

      return (
        <div className={styles.links}>
          <Link to={`/schools/${id}`}>Edit</Link>
          <Divider type="vertical" />
          <Popconfirm
            cancelText="No"
            okText="Yes"
            onConfirm={() => onDeactivate(id)}
            placement="topLeft"
            title="Are you sure you want to deactivate this school?"
          >
            <Typography.Link>Deactivate</Typography.Link>
          </Popconfirm>
        </div>
      );
    }
  }
];
