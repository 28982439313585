import { App, Modal } from 'antd';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Alert from '@okapi/components/Alert';
import { UpdateDistrictInput } from '@okapi/graphql/types.generated';

import DistrictForm from '../DistrictForm';
import { useCreateDistrictMutation } from '../graphql';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}

const AddDistrict: FC<Props> = ({ onSuccess, onCancel }) => {
  const { notification } = App.useApp();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [createDistrict] = useCreateDistrictMutation();

  const handleSubmit = async (district: UpdateDistrictInput) => {
    try {
      setErrorMessage('');

      await createDistrict({ variables: { district }, refetchQueries: ['districts'] });

      notification.success({ message: 'Success', description: 'New district has been added' });

      onSuccess();
    } catch (err) {
      setErrorMessage('Something went wrong.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Add district</title>
      </Helmet>
      <Modal footer={null} onCancel={onCancel} open={true} title="Add new district" width={600}>
        {errorMessage && <Alert message={errorMessage} type="error" />}
        <DistrictForm onCancel={onCancel} onSubmit={handleSubmit} />
      </Modal>
    </>
  );
};

export default AddDistrict;
