import { App } from 'antd';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';

import { FormHeadline } from '@okapi/components/Form';

import AuthWrapper from '../components/AuthWrapper';
import UpdatePasswordForm from '../components/UpdatePasswordForm';
import { useUpdatePasswordMutation } from '../graphql';

interface UrlParams {
  token: string;
}

const ResetPassword: FC = () => {
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>() as UrlParams;
  const [updatePassword] = useUpdatePasswordMutation();

  const handleSubmit = async ({ password }: { password: string }) => {
    try {
      await updatePassword({
        variables: {
          passwordInput: {
            password,
            token
          }
        }
      });

      notification.success({ message: 'You password has been updated. Please log in using the new password.' });

      navigate('/login');
    } catch (err) {
      notification.error({ message: 'Something went wrong' });
    }
  };

  return (
    <AuthWrapper>
      <Helmet>
        <title>Reset password</title>
      </Helmet>
      <FormHeadline
        tagline="Please enter your new password twice so we can verify you typed it in correctly."
        text="Reset Password"
      />
      <UpdatePasswordForm onSubmit={handleSubmit} />
    </AuthWrapper>
  );
};

export default ResetPassword;
