import { ValidationRules } from '../../../types';

export const validationRules: ValidationRules = {
  name: [
    {
      required: true,
      message: 'Please enter school name'
    }
  ],
  district: [
    {
      required: true,
      message: 'Please select district'
    }
  ],
  address: [
    {
      required: true,
      message: 'Please enter address'
    }
  ],
  city: [
    {
      required: true,
      message: 'Please enter city'
    }
  ],
  state: [
    {
      required: true,
      message: 'Please enter state'
    }
  ],
  zipCode: [
    {
      required: true,
      message: 'Please enter zip code'
    }
  ]
};
