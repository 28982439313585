import React, { FC } from 'react';
import { Navigate } from 'react-router';

import useAbility from '../Auth/hooks/useAbility';
import useAccount from '../Auth/hooks/useAccount';

const Dashboard: FC = () => {
  const { canAccess } = useAbility();
  const { districtName } = useAccount();

  const getRedirectUrl = (): string => {
    if (canAccess('dashboard')) {
      return '/dashboard';
    }

    return districtName === 'Free Resources District' ? '/resources' : '/books';
  };

  return <Navigate replace={true} to={getRedirectUrl()} />;
};

export default Dashboard;
