import { useApolloClient } from '@apollo/client';
import React, { FC, ReactNode, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';

import Spinner from '@okapi/components/Spinner';
import useTracking from '@okapi/useTracking';

import { useMeQuery } from '../graphql';
import { MeQueryResult } from '../graphql/me.generated';
import UserContext from './UserContext';

interface LocationState {
  state: {
    loginMethod?: string;
  };
}

interface Props {
  children: ReactNode;
}

const UserProvider: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const { state } = useLocation() as LocationState;
  const { resetStore } = useApolloClient();
  const { trackEvent } = useTracking();
  const { pathname, search } = useLocation();
  const { data, loading, error } = useMeQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (data && state?.loginMethod) {
      trackEvent({
        category: 'Login',
        action: 'Method',
        label: state.loginMethod
      });
    }
  }, [data, state, trackEvent]);

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRole');
    localStorage.removeItem('districtId');
    localStorage.removeItem('districtName');
    localStorage.removeItem('schoolId');
    localStorage.removeItem('schoolName');

    sessionStorage.removeItem('book-page');
    sessionStorage.removeItem('book-filters');

    resetStore();

    navigate('/login');
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Navigate state={{ backUrl: { pathname, search } }} to="/login" />;
  }

  if (!data) {
    return null;
  }

  const extractAndSaveUserContext = (values: MeQueryResult['me']) => {
    const { user, district, school } = values;
    const { id, firstName, lastName, assignedRole, email } = user;
    const { role, unitId } = assignedRole;

    const districtName: string = district?.name || '';

    localStorage.setItem('userId', id);
    localStorage.setItem('userRole', role);
    localStorage.setItem('districtId', district?.id || '');
    localStorage.setItem('districtName', districtName);
    localStorage.setItem('schoolId', school?.id || '');
    localStorage.setItem('schoolName', school?.name || '');

    return {
      userProfile: {
        id,
        email,
        firstName,
        lastName
      },
      districtName,
      role,
      unitId,
      logout
    };
  };

  return <UserContext.Provider value={extractAndSaveUserContext(data?.me)}>{children}</UserContext.Provider>;
};

export default UserProvider;
