import { Button, Col, Form, Input, Row } from 'antd';
import React, { FC } from 'react';

import { FormButtons } from '@okapi/components/Form';
import { isAdmin } from '@okapi/domains/Auth';
import useAccount from '@okapi/domains/Auth/hooks/useAccount';
import { School } from '@okapi/graphql/types.generated';

import DistrictSelect from '../../Districts/components/DistrictSelect';
import styles from './styles.module.less';
import { validationRules } from './validationRules';

interface Props {
  initialValues?: object;
  onCancel: () => void;
  onSubmit: (values: School & { districtId: string }) => void;
}

const SchoolForm: FC<Props> = ({ initialValues, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const { role } = useAccount();

  const renderDistrictField = () => {
    if (isAdmin(role)) {
      return (
        <Form.Item label="District" name="districtId" rules={validationRules.district}>
          <DistrictSelect name="districtId" />
        </Form.Item>
      );
    }

    return (
      <Form.Item name="districtId" noStyle={true}>
        <Input type="hidden" />
      </Form.Item>
    );
  };

  return (
    <Form className={styles.wrapper} form={form} initialValues={initialValues} layout="vertical" onFinish={onSubmit}>
      {renderDistrictField()}
      <Form.Item name="id" noStyle={true}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item label="School name" name="name" rules={validationRules.name}>
        <Input size="large" />
      </Form.Item>
      <Form.Item label="Address" name="address" rules={validationRules.address}>
        <Input size="large" />
      </Form.Item>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="City" name="city" rules={validationRules.city}>
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="State" name="state" rules={validationRules.state}>
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Zip Code" name="zip" rules={validationRules.zipCode}>
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>
      <FormButtons>
        <Button onClick={onCancel}>Cancel</Button>
      </FormButtons>
    </Form>
  );
};

export default SchoolForm;
