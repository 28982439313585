import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/interface';
import { FC } from 'react';

import useApp from '@okapi/App/hooks/useApp';
import DisabledLinkTooltip from '@okapi/components/DisabledLinkTooltip';
import { BookTypeEnum } from '@okapi/graphql/types.generated';

interface Props {
  bookType: BookTypeEnum;
  hasVideoVersion: boolean;
  onShareButtonClick: (resourceType: 'book' | 'video') => void;
}

const ShareBookLink: FC<Props> = ({ bookType, hasVideoVersion, onShareButtonClick }) => {
  const { isDemoVersion } = useApp();

  const label: string = `Share ${bookType === BookTypeEnum.INVESTIGATIONS ? 'Investigation' : 'Book'}`;

  if (isDemoVersion) {
    return (
      <DisabledLinkTooltip>
        <Typography.Link disabled={true}>{label}</Typography.Link>
      </DisabledLinkTooltip>
    );
  }

  if (hasVideoVersion) {
    const items: ItemType[] = [
      { key: 'book-with-audio', label: 'Book with Audio', onClick: () => onShareButtonClick('video') },
      { key: 'book-without-audio', label: 'Book without Audio', onClick: () => onShareButtonClick('book') }
    ];

    return (
      <Dropdown menu={{ items }} trigger={['click']}>
        <Typography.Link>
          Share book <DownOutlined />
        </Typography.Link>
      </Dropdown>
    );
  }

  return <Typography.Link onClick={() => onShareButtonClick('book')}>{label}</Typography.Link>;
};

export default ShareBookLink;
