import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateSubscriptionMutationVariables = GraphQLTypes.Exact<{
  subscription: GraphQLTypes.CreateSubscriptionInput;
}>;

export type CreateSubscriptionMutationResult = { __typename?: 'Mutation' } & {
  createSubscription: { __typename?: 'Subscription' } & Pick<GraphQLTypes.Subscription, 'unitId'>;
};

export const CreateSubscriptionDocument = /* #__PURE__ */ gql`
  mutation createSubscription($subscription: CreateSubscriptionInput!) {
    createSubscription(subscription: $subscription) {
      unitId
    }
  }
`;

/**
 * __useCreateSubscription__
 *
 * To run a mutation, you first call `useCreateSubscription` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscription` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscription, { data, loading, error }] = useCreateSubscription({
 *   variables: {
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useCreateSubscription(
  baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutationResult, CreateSubscriptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateSubscriptionMutationResult, CreateSubscriptionMutationVariables>(
    CreateSubscriptionDocument,
    options
  );
}
export type CreateSubscriptionHookResult = ReturnType<typeof useCreateSubscription>;
