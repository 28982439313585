import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type DeleteSubscriptionMutationVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['ID']['input'];
}>;

export type DeleteSubscriptionMutationResult = { __typename?: 'Mutation' } & {
  deleteSubscription: { __typename?: 'Subscription' } & Pick<GraphQLTypes.Subscription, 'id'>;
};

export const DeleteSubscriptionDocument = /* #__PURE__ */ gql`
  mutation deleteSubscription($id: ID!) {
    deleteSubscription(id: $id) {
      id
    }
  }
`;

/**
 * __useDeleteSubscription__
 *
 * To run a mutation, you first call `useDeleteSubscription` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubscription` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubscription, { data, loading, error }] = useDeleteSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubscription(
  baseOptions?: Apollo.MutationHookOptions<DeleteSubscriptionMutationResult, DeleteSubscriptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<DeleteSubscriptionMutationResult, DeleteSubscriptionMutationVariables>(
    DeleteSubscriptionDocument,
    options
  );
}
export type DeleteSubscriptionHookResult = ReturnType<typeof useDeleteSubscription>;
