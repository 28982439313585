import { Typography } from 'antd';
import React, { FC } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import DownloadLink from '@okapi/components/DownloadLink';
import { BookTypeEnum, LanguageEnum } from '@okapi/graphql/types.generated';

import { Book } from '../../BookDetails/types';
import { getRawResourceUrl } from '../../helpers';
import LessonPlanLinks from '../LessonPlanLinks';
import PairedTextsDropdown from '../PairedTextsDropdown';
import RelatedBookLink from '../RelatedBookLink';
import ShareBookLink from '../ShareBookLink';

interface Props {
  book: Book;
  onShareButtonClick: (bookId: string, resourceType: 'book' | 'video') => void;
}

const RelatedLinks: FC<Props> = ({ book, onShareButtonClick }) => {
  const navigate = useNavigate();
  const { resourceType } = useParams();

  const {
    asset,
    alternate,
    bigBook,
    bookType,
    flashCards,
    id,
    investigations,
    language,
    lessonPlan,
    pairedText1,
    pairedText2,
    perspectives,
    programOverview,
    related,
    runningRecord,
    teacherResourceBook,
    teachingNote,
    teksLessonPlan,
    videoAsset,
    vocabulary,
    vocabularySideB
  } = book;

  const hasPairedTexts: boolean = !!(pairedText1 && pairedText2);
  const isEnglishBook: boolean = language?.key === LanguageEnum.ENGLISH;
  const isVideoPage: boolean = resourceType === 'video';
  const isLessonPlan: boolean = bookType?.key === BookTypeEnum.LESSON_PLAN || bookType?.key === BookTypeEnum.TEKS_LESSON_PLAN;
  const isRunningRecord: boolean = bookType?.key === BookTypeEnum.RUNNING_RECORD;
  const isFlashCards: boolean = bookType?.key === BookTypeEnum.FLASH_CARDS;
  const isBigBook: boolean = bookType?.key === BookTypeEnum.BIG_BOOK;
  const isTeacherResourceBook: boolean = bookType?.key === BookTypeEnum.TEACHER_RESOURCE_BOOK;
  const isTeachingNotes: boolean = bookType?.key === BookTypeEnum.TEACHING_NOTES;
  const showBackLink: boolean = isFlashCards || isBigBook || isTeacherResourceBook || isTeachingNotes;
  const showShareButton: boolean = bookType?.key === BookTypeEnum.STUDENT_BOOK;

  const hasLessonPlan: boolean = !!(lessonPlan || teksLessonPlan);

  return (
    <>
      {showBackLink && <Typography.Link onClick={() => navigate(-1)}>Back to Book</Typography.Link>}
      {programOverview && <Link to={`/books/${programOverview.id}`}>Program Overview</Link>}
      {showShareButton && bookType?.key && (
        <ShareBookLink
          bookType={bookType.key}
          hasVideoVersion={!!videoAsset}
          onShareButtonClick={(type: 'book' | 'video') => onShareButtonClick(id, type)}
        />
      )}
      {videoAsset && (
        <>
          {isVideoPage && <Link to={`/books/${id}`}>Book without Audio</Link>}
          {!isVideoPage && <Link to={`/books/${id}/video`}>Book with Audio</Link>}
        </>
      )}
      {investigations && <Link to={`/books/${investigations.id}`}>Investigations</Link>}
      {related && <RelatedBookLink book={book} />}
      {perspectives && <Link to={`/books/${perspectives.id}`}>{isEnglishBook ? 'Perspectives' : 'Perspectivas'}</Link>}
      {hasPairedTexts && <PairedTextsDropdown book={book} />}
      {vocabulary && <Link to={`/books/${vocabulary.id}`}>Vocabulary Starter</Link>}
      {vocabularySideB && <Link to={`/books/${vocabularySideB.id}`}>Instruction</Link>}
      {runningRecord && <Link to={`/books/${runningRecord.id}`}>Running Record</Link>}
      {isRunningRecord && <DownloadLink url={getRawResourceUrl(asset, 'runningRecord')} />}
      {isLessonPlan && <DownloadLink url={getRawResourceUrl(asset, 'lessonPlan')} />}
      {isTeachingNotes && <DownloadLink url={getRawResourceUrl(asset, 'teachingNotes')} />}
      {hasLessonPlan && <LessonPlanLinks book={book} />}
      {alternate && <Link to={`/books/${alternate.id}`}>{isEnglishBook ? 'Spanish' : 'English'} Version</Link>}
      {flashCards && <Link to={`/books/${flashCards.id}`}>Alphabet Cards</Link>}
      {bigBook && <Link to={`/books/${bigBook.id}`}>Big Book of Rhymes</Link>}
      {teacherResourceBook && <Link to={`/books/${teacherResourceBook.id}`}>Teacher Resource Book</Link>}
      {teachingNote && <Link to={`/books/${teachingNote.id}`}>Teaching Notes</Link>}
    </>
  );
};

export default RelatedLinks;
