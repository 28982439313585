import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateSubscriptionMutationVariables = GraphQLTypes.Exact<{
  subscription: GraphQLTypes.UpdateSubscriptionInput;
}>;

export type UpdateSubscriptionMutationResult = { __typename?: 'Mutation' } & {
  updateSubscription: { __typename?: 'Subscription' } & Pick<GraphQLTypes.Subscription, 'unitId'>;
};

export const UpdateSubscriptionDocument = /* #__PURE__ */ gql`
  mutation updateSubscription($subscription: UpdateSubscriptionInput!) {
    updateSubscription(subscription: $subscription) {
      unitId
    }
  }
`;

/**
 * __useUpdateSubscription__
 *
 * To run a mutation, you first call `useUpdateSubscription` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscription` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscription, { data, loading, error }] = useUpdateSubscription({
 *   variables: {
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useUpdateSubscription(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionMutationResult, UpdateSubscriptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateSubscriptionMutationResult, UpdateSubscriptionMutationVariables>(
    UpdateSubscriptionDocument,
    options
  );
}
export type UpdateSubscriptionHookResult = ReturnType<typeof useUpdateSubscription>;
