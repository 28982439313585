import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import DownloadLink from '@okapi/components/DownloadLink';

import { getRawResourceUrl } from './helpers';
import { TestPreparation } from './types';

export const getColumns = (): ColumnsType<TestPreparation> => [
  {
    title: 'Title',
    dataIndex: 'title',
    render: (title: string, { isbn }) => <Link to={`/resources/test-preparations/${isbn}`}>{title}</Link>,
    width: 220,
    sorter: (a: { title: string }, b: { title: string }) => a.title.localeCompare(b.title)
  },
  {
    title: 'Language',
    dataIndex: 'language',
    width: 100,
    sorter: (a: { language: string }, b: { language: string }) => a.language.localeCompare(b.language)
  },
  {
    title: 'Grade Level',
    dataIndex: 'gradeLevel',
    width: 120,
    sorter: (a: { gradeLevel: string }, b: { gradeLevel: string }) => a.gradeLevel.localeCompare(b.gradeLevel)
  },
  {
    title: 'Actions',
    align: 'right',
    key: 'actions',
    fixed: 'right',
    width: 60,
    render: (_, { asset }) => <DownloadLink url={getRawResourceUrl(asset)} />
  }
];
