import { Pagination as PaginationComponent } from 'antd';
import React, { FC } from 'react';

import { PAGE_SIZE } from '@okapi/utils/pagination';

import styles from './styles.module.less';

interface Props {
  currentPage: number;
  onChange: (page: number) => void;
  totalCount: number;
}

const Pagination: FC<Props> = ({ currentPage, onChange, totalCount }) => (
  <div className={styles.pagination}>
    <PaginationComponent
      current={currentPage}
      defaultPageSize={PAGE_SIZE}
      hideOnSinglePage={true}
      onChange={onChange}
      responsive={true}
      showSizeChanger={false}
      total={totalCount}
    />
  </div>
);

export default Pagination;
