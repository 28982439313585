import { Card, Table, Typography } from 'antd';
import { FC } from 'react';

import ScrollToTop from '@okapi/components/ScrollToTop';

import { getResources } from './helpers';
import styles from './styles.module.less';
import { getColumns } from './tableConfig';

const TestPreparations: FC = () => (
  <>
    <ScrollToTop />
    <div className={styles.wrapper} data-testid="lesson-plans">
      <div className={styles.headline}>
        <Typography.Title level={3}>Texas Test Prep</Typography.Title>
        <div>
          Activities to support the State of Texas Assessments of Academic Readiness. <br />
          Practical guidance for working with paired text selections. Available in both English and Spanish!
        </div>
      </div>

      <Card>
        <Table
          columns={getColumns()}
          dataSource={getResources()}
          pagination={false}
          rowKey="isbn"
          scroll={{ x: 700 }}
          size="middle"
          tableLayout="auto"
        />
      </Card>
    </div>
  </>
);

export default TestPreparations;
