import React, { ReactChild, useCallback, useMemo } from 'react';

import TrackingContext from './TrackingContext';
import type { TrackingEvent } from './types';

interface TrackingProviderProps {
  children: ReactChild;
}

const TrackingProvider = ({ children }: TrackingProviderProps) => {
  const pushToDataLayer = <T extends {}>(data: T): void => {
    (window.dataLayer = window.dataLayer || []).push(data);
  };

  const trackEvent = useCallback(({ event = 'Event', ...rest }: TrackingEvent): void => {
    pushToDataLayer<TrackingEvent>({ event, ...rest });
  }, []);

  const context = useMemo(() => ({ trackEvent }), [trackEvent]);

  return <TrackingContext.Provider value={context}>{children}</TrackingContext.Provider>;
};

export default TrackingProvider;
