import { Button, Result } from 'antd';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  showBackButton?: boolean;
  title?: string;
  subTitle?: ReactNode;
}

const NotAuthorized: FC<Props> = (props) => {
  const { showBackButton = true, title = '403', subTitle = 'Sorry, you are not authorized to access this page.' } = props;

  const actionButton = showBackButton ? (
    <Link to="/">
      <Button type="primary">Back Home</Button>
    </Link>
  ) : undefined;

  return <Result extra={actionButton} status="403" subTitle={subTitle} title={title} />;
};

export default NotAuthorized;
