import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AuthorizeCleverMutationVariables = GraphQLTypes.Exact<{
  code: GraphQLTypes.Scalars['String']['input'];
  state?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']['input']>;
  scope?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']['input']>;
}>;

export type AuthorizeCleverMutationResult = { __typename?: 'Mutation' } & {
  clever: { __typename?: 'Token' } & Pick<GraphQLTypes.Token, 'accessToken'>;
};

export const AuthorizeCleverDocument = /* #__PURE__ */ gql`
  mutation authorizeClever($code: String!, $state: String = null, $scope: String = null) {
    clever(code: $code, state: $state, scope: $scope) {
      accessToken
    }
  }
`;

/**
 * __useAuthorizeCleverMutation__
 *
 * To run a mutation, you first call `useAuthorizeCleverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeCleverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeCleverMutation, { data, loading, error }] = useAuthorizeCleverMutation({
 *   variables: {
 *      code: // value for 'code'
 *      state: // value for 'state'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useAuthorizeCleverMutation(
  baseOptions?: Apollo.MutationHookOptions<AuthorizeCleverMutationResult, AuthorizeCleverMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<AuthorizeCleverMutationResult, AuthorizeCleverMutationVariables>(AuthorizeCleverDocument, options);
}
export type AuthorizeCleverMutationHookResult = ReturnType<typeof useAuthorizeCleverMutation>;
