import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type SubscriptionQueryVariables = GraphQLTypes.Exact<{
  unitId: GraphQLTypes.Scalars['ID']['input'];
  active?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']['input']>;
}>;

export type SubscriptionQueryResult = { __typename?: 'Query' } & {
  subscription: Array<
    { __typename?: 'Subscription' } & Pick<GraphQLTypes.Subscription, 'id' | 'from' | 'to' | 'active' | 'unitId'> & {
        items: Array<
          { __typename?: 'SubscriptionItem' } & {
            series: { __typename?: 'Series' } & Pick<GraphQLTypes.Series, 'key'>;
            stage: { __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'key'>;
          }
        >;
      }
  >;
};

export const SubscriptionDocument = /* #__PURE__ */ gql`
  query subscription($unitId: ID!, $active: Boolean) {
    subscription(active: $active, unitId: $unitId) {
      id
      from
      to
      active
      items {
        series {
          key
        }
        stage {
          key
        }
      }
      unitId
    }
  }
`;

/**
 * __useSubscriptionQuery__
 *
 * To run a query within a React component, call `useSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useSubscriptionQuery(
  baseOptions: Apollo.QueryHookOptions<SubscriptionQueryResult, SubscriptionQueryVariables> &
    ({ variables: SubscriptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<SubscriptionQueryResult, SubscriptionQueryVariables>(SubscriptionDocument, options);
}
export function useSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionQueryResult, SubscriptionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<SubscriptionQueryResult, SubscriptionQueryVariables>(SubscriptionDocument, options);
}
export function useSubscriptionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SubscriptionQueryResult, SubscriptionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<SubscriptionQueryResult, SubscriptionQueryVariables>(SubscriptionDocument, options);
}
export type SubscriptionQueryHookResult = ReturnType<typeof useSubscriptionQuery>;
export type SubscriptionLazyQueryHookResult = ReturnType<typeof useSubscriptionLazyQuery>;
export type SubscriptionSuspenseQueryHookResult = ReturnType<typeof useSubscriptionSuspenseQuery>;
