import { Select } from 'antd';
import { FC } from 'react';

import { Option } from '@okapi/types';
import { Optional } from '@okapi/utils/typescript';

import { useSchoolsQuery } from '../../graphql';
import { getOptions } from './helpers';

interface Props {
  name: string;
}

const SchoolSelect: FC<Props> = (props) => {
  const { data } = useSchoolsQuery({ fetchPolicy: 'cache-and-network' });

  const schoolOptions: Option[] = getOptions(data?.schools?.nodes || []);

  const handleFilter = (inputValue: string, option: Optional<Option>): boolean =>
    !!option?.label.toLowerCase().includes(inputValue?.toLowerCase());

  return (
    <Select {...props} allowClear={true} filterOption={handleFilter} options={schoolOptions} showSearch={true} size="large" />
  );
};

export default SchoolSelect;
