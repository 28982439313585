import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateSchoolMutationVariables = GraphQLTypes.Exact<{
  school: GraphQLTypes.CreateSchoolInput;
}>;

export type CreateSchoolMutationResult = { __typename?: 'Mutation' } & {
  createSchool: { __typename?: 'School' } & Pick<GraphQLTypes.School, 'id'>;
};

export const CreateSchoolDocument = /* #__PURE__ */ gql`
  mutation createSchool($school: CreateSchoolInput!) {
    createSchool(school: $school) {
      id
    }
  }
`;

/**
 * __useCreateSchoolMutation__
 *
 * To run a mutation, you first call `useCreateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolMutation, { data, loading, error }] = useCreateSchoolMutation({
 *   variables: {
 *      school: // value for 'school'
 *   },
 * });
 */
export function useCreateSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSchoolMutationResult, CreateSchoolMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateSchoolMutationResult, CreateSchoolMutationVariables>(CreateSchoolDocument, options);
}
export type CreateSchoolMutationHookResult = ReturnType<typeof useCreateSchoolMutation>;
