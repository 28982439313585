import React, { FC } from 'react';
import { Navigate } from 'react-router';

const DemoLifetimeAccess: FC = () => {
  localStorage.setItem('demo-lifetime-access', 'true');

  return <Navigate to="/" />;
};

export default DemoLifetimeAccess;
