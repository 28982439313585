import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateDistrictMutationVariables = GraphQLTypes.Exact<{
  district: GraphQLTypes.UpdateDistrictInput;
}>;

export type UpdateDistrictMutationResult = { __typename?: 'Mutation' } & {
  updateDistrict: { __typename?: 'District' } & Pick<GraphQLTypes.District, 'id' | 'name'>;
};

export const UpdateDistrictDocument = /* #__PURE__ */ gql`
  mutation updateDistrict($district: UpdateDistrictInput!) {
    updateDistrict(district: $district) {
      id
      name
    }
  }
`;

/**
 * __useUpdateDistrictMutation__
 *
 * To run a mutation, you first call `useUpdateDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistrictMutation, { data, loading, error }] = useUpdateDistrictMutation({
 *   variables: {
 *      district: // value for 'district'
 *   },
 * });
 */
export function useUpdateDistrictMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDistrictMutationResult, UpdateDistrictMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateDistrictMutationResult, UpdateDistrictMutationVariables>(UpdateDistrictDocument, options);
}
export type UpdateDistrictMutationHookResult = ReturnType<typeof useUpdateDistrictMutation>;
