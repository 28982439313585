import React, { FC } from 'react';

import { getVideoUrl } from '../../helpers';
import styles from './styles.module.less';

interface Props {
  videoId: string;
}

const VideoBook: FC<Props> = ({ videoId }) => (
  <div className={styles.videoWrapper}>
    <video autoPlay={true} controls={true} controlsList="nodownload noplaybackrate" disablePictureInPicture={true}>
      <track kind="captions" />
      <source src={getVideoUrl(videoId)} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
);

export default VideoBook;
