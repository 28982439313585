import { Typography } from 'antd';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

import useApp from '@okapi/App/hooks/useApp';
import BookViewer from '@okapi/components/BookViewer/BookViewer';
import NotAuthorized from '@okapi/components/NotAuthorized';
import ScrollToTop from '@okapi/components/ScrollToTop';
import Spinner from '@okapi/components/Spinner';
import useTracking from '@okapi/useTracking';

import RelatedLinks from '../components/RelatedLinks';
import RelatedLinksMenu from '../components/RelatedLinksMenu';
import { useBookQuery } from '../graphql';
import { getBookUrl } from '../helpers';
import { useShareBook } from '../hooks/useShareBook';
import styles from './styles.module.less';
import VideoBook from './VideoBook';

interface UrlParams {
  id: string;
  resourceType: string;
}

const BookDetails: FC = () => {
  const { id, resourceType } = useParams<{ id: string; resourceType: string }>() as UrlParams;
  const { isDemoVersion } = useApp();
  const { trackEvent } = useTracking();
  const { shareBook } = useShareBook();
  const { data, loading } = useBookQuery({ variables: { id } });
  const isVideoPage: boolean = resourceType === 'video';
  const book = data?.book;

  useEffect(() => {
    if (book) {
      trackEvent({
        category: 'Book',
        action: 'View',
        label: book.title,
        properties: {
          bookSeries: book.series.value
        }
      });
    }
  }, [book, trackEvent]);

  if (loading) {
    return <Spinner />;
  }

  if (!book || (isDemoVersion && !book.includedInDemo)) {
    return <NotAuthorized />;
  }

  return (
    <div className={styles.wrapper}>
      <ScrollToTop />
      <Helmet>
        <title>{book.title}</title>
      </Helmet>
      <div className={styles.header}>
        <Typography.Title level={3}>{book.title}</Typography.Title>
        <div className={styles.links}>
          <RelatedLinks book={book} onShareButtonClick={shareBook} />
        </div>
        <div className={styles.linksMenu}>
          <RelatedLinksMenu key={id} book={book} onShareButtonClick={shareBook} />
        </div>
      </div>
      {isVideoPage && <VideoBook videoId={book?.videoAsset || ''} />}
      {!isVideoPage && <BookViewer title={book.title} url={getBookUrl(book?.asset || '')} />}
    </div>
  );
};

export default BookDetails;
