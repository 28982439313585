import React, { FC } from 'react';
import CountUp from 'react-countup';

import { formatNumber } from '@okapi/utils/formatting';

import styles from './styles.module.less';

interface Props {
  label: string;
  value: number;
}

const LabelledValue: FC<Props> = ({ label, value = 0 }) => (
  <div>
    <div className={styles.value}>
      <CountUp duration={2} end={value} formattingFn={formatNumber} />
    </div>
    <div className={styles.label}>{label}</div>
  </div>
);

export default LabelledValue;
