import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type DistrictsQueryVariables = GraphQLTypes.Exact<{
  page?: GraphQLTypes.InputMaybe<GraphQLTypes.GetPage>;
  filters?: GraphQLTypes.InputMaybe<GraphQLTypes.DistrictFilters>;
}>;

export type DistrictsQueryResult = { __typename?: 'Query' } & {
  districts: { __typename?: 'PaginatedDistrict' } & Pick<GraphQLTypes.PaginatedDistrict, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<Array<{ __typename?: 'District' } & Pick<GraphQLTypes.District, 'id' | 'name' | 'active'>>>;
    };
};

export const DistrictsDocument = /* #__PURE__ */ gql`
  query districts($page: GetPage, $filters: DistrictFilters) {
    districts(page: $page, filters: $filters) {
      nodes {
        id
        name
        active
      }
      totalCount
    }
  }
`;

/**
 * __useDistrictsQuery__
 *
 * To run a query within a React component, call `useDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDistrictsQuery(baseOptions?: Apollo.QueryHookOptions<DistrictsQueryResult, DistrictsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<DistrictsQueryResult, DistrictsQueryVariables>(DistrictsDocument, options);
}
export function useDistrictsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistrictsQueryResult, DistrictsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<DistrictsQueryResult, DistrictsQueryVariables>(DistrictsDocument, options);
}
export function useDistrictsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DistrictsQueryResult, DistrictsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<DistrictsQueryResult, DistrictsQueryVariables>(DistrictsDocument, options);
}
export type DistrictsQueryHookResult = ReturnType<typeof useDistrictsQuery>;
export type DistrictsLazyQueryHookResult = ReturnType<typeof useDistrictsLazyQuery>;
export type DistrictsSuspenseQueryHookResult = ReturnType<typeof useDistrictsSuspenseQuery>;
