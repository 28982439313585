import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UserQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['ID']['input'];
}>;

export type UserQueryResult = { __typename?: 'Query' } & {
  user: { __typename?: 'User' } & Pick<
    GraphQLTypes.User,
    | 'id'
    | 'classroom'
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'loginPath'
    | 'gradeLevel'
    | 'schoolBuilding'
    | 'termsAccepted'
    | 'status'
  > & {
      assignedRole: { __typename?: 'AssignedRole' } & Pick<GraphQLTypes.AssignedRole, 'role' | 'unitId'>;
    };
};

export const UserDocument = /* #__PURE__ */ gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      classroom
      email
      firstName
      lastName
      loginPath
      gradeLevel
      schoolBuilding
      termsAccepted
      assignedRole {
        role
        unitId
      }
      status
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQueryResult, UserQueryVariables> &
    ({ variables: UserQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UserQueryResult, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQueryResult, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UserQueryResult, UserQueryVariables>(UserDocument, options);
}
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQueryResult, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UserQueryResult, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
