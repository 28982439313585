/* eslint-disable max-len */
import React, { FC } from 'react';

import LandingPage from '@okapi/components/LandingPage';

const PrivacyPolicy: FC = () => (
  <LandingPage title="Okapi Privacy Policy">
    <div>
      <p>
        <strong>Effective Date: December 2022</strong>
      </p>
      <p>&nbsp;</p>
      <p>
        <em>
          <strong>This Privacy Policy describes:</strong>
        </em>
      </p>
      <ul>
        <li>
          the information that Okapi Educational Publishing, Inc. (&ldquo;Okapi&rdquo; or &quot;we&quot;, &ldquo;us&rdquo; or
          &ldquo;our&rdquo;) collects from you in any form through our applications, online services or Okapi websites (including
          but not limited to <a href="https://myokapi.com">https://myokapi.com</a>, &nbsp;
          <a href="https://flyingstarttoliteracy.com/digital-activities/">
            https://flyingstarttoliteracy.com/digital-activities/
          </a>
          , <a href="https://despegando-digital-activities.com">https://despegando-digital-activities.com</a>;{' '}
          <a href="https://despegando-hacia-la-lectura.com">https://despegando-hacia-la-lectura.com</a>, &nbsp;
          <a href="https://okapi-bookrooms.com">https://okapi-bookrooms.com</a>, &nbsp;
          <a href="https://worldwise-reading.com">https://worldwise-reading.com</a>, &nbsp; &nbsp;
          <a href="https://exploramundos-reading.com/">https://exploramundos-reading.com/</a>,{' '}
          <a href="https://okapi-digital-literacy.com">https://okapi-digital-literacy.com</a>,{' '}
          <a href="https://biliteracy-para-todos.com">https://biliteracy-para-todos.com</a>&nbsp; (collectively
          &ldquo;Websites&rdquo;);
        </li>
        <li>
          how we collect, use, share, disclose and otherwise process Personal Information (as defined below) that we receive or
          collect including through our Websites; and
        </li>
        <li>how we store and secure Personal Information.</li>
      </ul>
      <p>
        <strong>Definition of Information</strong>
      </p>
      <p>
        As part of this Privacy Policy, the term &ldquo;Personal Information&rdquo; is used to describe information that can be
        used to directly or indirectly identify an individual person, such as a full name, home or other physical address, email
        address, and telephone number. &nbsp;Personal Information can also include anonymous information that is linked to
        information that can be used to directly or indirectly identify an individual person. &nbsp;Personal Information does not
        include information that has been made anonymous or aggregated so that it can no longer be used, whether in combination
        with other information or otherwise, to identify an individual person.&nbsp;
      </p>
      <p>
        The term &ldquo;Non-Personal Information&rdquo; is used to describe information that cannot be used to directly or
        indirectly to identify an individual person and that is not linked to information that can be used to directly or
        indirectly to identify an individual person. &nbsp;Non-Personal Information includes passively collected information about
        activities on our Websites, such as usage data, to the extent that information is not linked to any Personal Information.
      </p>
      <p>
        The term &ldquo;information&rdquo; is used in this Privacy Policy as a generic term and may include both types of
        information described above.
      </p>
      <p>
        <em>
          <strong>How to Contact Us</strong>
        </em>
      </p>
      <p>
        If you have any questions, concerns or complaints about our Privacy Policy or our data collection or processing practices,
        or if you want to report any suspected security violations to us, please contact us at the following address or email
        address:
      </p>
      <p>
        Privacy Officer
        <br />
        Okapi Educational Publishing, Inc.
        <br />
        42381 Rio Nedo, Temecula, CA 92590
        <br />
        <a href="mailto:privacy@myokapi.com">privacy@myokapi.com&nbsp;</a>
        <br />
        866-652-7436
      </p>
      <p>
        <strong>Services We Provide</strong>
      </p>
      <p>We offer the following services (&ldquo;Services&rdquo;) on our Websites:</p>
      <ul>
        <li>
          We provide products, services, tools and resources to educators to help support and facilitate the education process;
        </li>
        <li>Our Websites offer tips and practical guidance for literacy teachers;</li>
        <li>
          We offer on-line educational facilitation tools, including, Flying Start to Literacy Activities. &nbsp;Teachers may use
          these tools inside and outside the classroom to help students meet educational goals.
        </li>
        <li>We work with independent sales representative to make our tools and resources available to educators.</li>
      </ul>
      <p>
        <em>
          <strong>Information We Collect&nbsp;</strong>
        </em>
      </p>
      <p>
        Information You Provide to Us.&nbsp; We will collect certain Personal Information as well as Non-Personal Information from
        you that you voluntarily provide. &nbsp;
      </p>
      <ol>
        <li>
          <strong>Account Information.</strong>&nbsp; You can create an account with us to receive newsletters and communications
          from us and also to track orders, download products, access purchased services, and manage account information. &nbsp;If
          you choose to register and create a user account with us, we collect your first and last name, e-mail address,
          professional title, school or district information, street address, and send you a verification email.
        </li>
        <li>
          <strong>Newsletter/Communications/Sample Request</strong>. &nbsp;You can provide us with your email address, your title
          or position and school or district information to receive our newsletter and receive information from leading voices in
          literacy and gain access to tools for immediate use. &nbsp;To request samples of our products, you would also provide
          mailing address, telephone and appropriate grade level information.
        </li>
        <li>
          <strong>Purchase Products/Services</strong>. &nbsp;If you would like to purchase a product or service from our on-line
          store at https://myokapi.com/okapi-store, you will be asked to provide your name, address, email address, and
          school/district information. &nbsp;If you choose to make payment via credit card, you will be prompted to enter credit
          card information, which will be sent via a secure connection directly to our third-party payment processor. &nbsp;You
          also will have the option to store your payment information. &nbsp;Our third party payment processor provides the stored
          payment option. &nbsp;We do not have access to your payment information.
        </li>
        <li>
          <strong>Contact Us</strong>. &nbsp;If you provide us with feedback or contact us via e-mail, we will collect your name
          and e-mail address, as well as any other content included in the e-mail, in order to send you a reply. &nbsp;
        </li>
      </ol>
      <p>Information We Receive From Your Use of Our Website</p>
      <p>
        We may receive certain types of information automatically when you access the Website, such as your browser type and
        internet protocol (IP) address. While the system may collect this information, the IP addresses are obfuscated, and
        therefore, we do not identify you or match this information with your other Personal Information. This information is used
        to diagnose Website technical problems, facilitate your use of our Website, or track aggregate Website traffic
        patterns.&nbsp;
      </p>
      <p>
        Cookies, or text files, that are placed on your hard drive by Websites when you visit that Website, identify your computer
        and record your preferences and other data about your visit to that Website so when you return to the Website, the Website
        can personalize your return visit. We may use cookies to collect information about your visit to determine which of our
        areas, features and forums are most popular, personalize and improve your experience on our Website and to complete
        transactions you may request. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent.
        By prohibiting the use of cookies, however, you may restrict your access to certain types of content or features on the
        Websites.
      </p>
      <p>Location of Information&nbsp;</p>
      <p>
        Okapi is based in the United States, and the information we collect is hosted in the United States including, through our
        third party service provider, Amazon Web Services (&lsquo;AWS&rsquo;). &nbsp;Therefore, any information we collect is
        governed by and our Services are operated in accordance with United States laws. &nbsp;If you are using the Services
        outside the United States, you consent to having your information and data transferred to the United States. &nbsp;In
        addition, certain third party service providers from countries other than the United States may access certain of our
        Services and your Personal Information as part of performing those services. If you are from any jurisdiction outside of
        the United States with laws or regulations governing the use of the internet, including the collection, use and disclosure
        of Personal Information, that are different from those of the United States, you may only use the Services in a manner
        lawful in your jurisdiction. &nbsp;We cannot guarantee or provide any warrant that the Services will comply with any laws
        outside of the United States, and you should not access or use the Website if your use is not in compliance with the laws
        of your country.
      </p>
      <p>Personal Information We Receive From Others</p>
      <p>
        We may receive Personal Information about you from third parties providing credit and debit card authorization and fraud
        screening services as part of any purchase. &nbsp;Further, independent sales representatives may provide your Personal
        Information to Okapi to process product or service orders. &nbsp;We require these independent sales representatives to
        collect, maintain or use any Personal Information they collect from you directly in accordance with this Privacy Policy.
        &nbsp;
      </p>
      <p>Children&rsquo;s Personal Information</p>
      <p>
        We market and sell our educational materials to school districts who purchase or otherwise subscribe to our Services.
        &nbsp;These educational materials can be distributed by teachers to certain of their students, under the direction and
        supervision of their teachers. &nbsp;Okapi is unaware, however, of when an individual is under the age of 16 and who may
        be using the Websites or materials distributed by teachers, as no log in is required for any student. &nbsp;We do not
        knowingly collect any Personal Information of a minor under 16 years old, and if we learn that we have collected any
        Personal Information of a minor under 16 years old, we will comply with applicable legal requirements in the handling of
        that Personal Information, including deleting it where applicable.
      </p>
      <p>
        Furthermore, Okapi does not market its Websites to children. Okapi does not know when a user on the Website is either a
        teacher or student because no Personal Information is collected by virtue of using the Websites, and any internet protocol
        (IP) address is obfuscated. &nbsp;&nbsp;
      </p>
      <p>
        The Okapi system may automatically collect certain incidental usage information, however, we would not be able to identify
        or match any information with a child&rsquo;s Personal Information. This usage information may also be used to diagnose
        Website technical problems, facilitate use of our Websites, or track aggregate Website traffic patterns, but no effort is
        made to identify any other Personal Information in such cases.
      </p>
      <p>
        <em>
          <strong>Links to and from Third Party Websites</strong>
        </em>
      </p>
      <p>
        Our Websites may contain links to third party websites and services and some of those third-party websites may be
        co-branded with our logo (the &ldquo;Third Party Services&rdquo;). &nbsp;For example, our Website automatically directs
        you to a third party payment processor when you are paying for products or services on-line. &nbsp;If you decide to visit
        and submit any information to a Third Party Service, you are subject to the third party&rsquo;s privacy policy and
        practices and not this Privacy Policy. &nbsp;Similarly, you may be directed to our Websites from third party websites,
        including Clever, as part of a single sign on service through a school district that has contracted with us for the
        Services. &nbsp;To the extent you are directed to our Services or Websites via a third party interface or link, you
        acknowledge that you may also be subject to the terms of the applicable third party privacy policy and other contractual
        requirements. &nbsp;We encourage you to review carefully the legal and privacy policies of all other digital services that
        you are directed to or from as a result of the Services or our Websites. &nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        <em>
          <strong>How We Use and Share Your Personal Information</strong>
        </em>
      </p>
      <p>
        We do not sell your Personal Information to anyone, and more specifically, we will use or share your Personal Information
        as detailed below.
      </p>
      <p>
        With your consent. We may share your Personal Information with third parties if you have given us your consent to do
        so.&nbsp;
      </p>
      <p>
        To perform services. We may use or disclose your Personal Information to third parties in order to perform Services you
        request such as processing an order for the delivery of a Flying Start to Literacy product.
      </p>
      <p>
        To communicate with you.&nbsp; We use your Personal Information to send you information about our Services, to respond to
        you when you contact us, to call you as a part of secondary fraud protection, or to solicit your feedback. &nbsp;If you
        provided us with an email address, we may also send newsletters, surveys, offers, and other promotional materials related
        to our Services. You can control these marketing communications by using your notification preferences in account settings
        or via the &ldquo;Unsubscribe&rdquo; link in an email. &nbsp;
      </p>
      <p>
        With Independent Sales Representatives.&nbsp; If you provided us with your Personal Information and indicated an interest
        in learning more about our products or services, we may share your Personal Information with an independent sales
        representative who may contact you. &nbsp;We require independent sales representatives who receive Personal Information
        from us to use and maintain your Personal Information in accordance with this Privacy Policy. &nbsp;
      </p>
      <p>
        With third party service providers performing services on our behalf. We may share your Personal Information with our
        third party service providers to perform the functions for which we engage them. &nbsp;For example, a third party payment
        processor collects all credit card information for secure processing and storage. Further, we may share information as
        needed to operate other related services. &nbsp;For example, we may work with Google Analytics so that Google Analytics
        can provide us with information about Website usage, or we may work with MailChimp so that they can send out the
        newsletters and other marketing materials on our behalf. &nbsp;&nbsp;
      </p>
      <p>
        To improve, personalize, and develop Services.&nbsp; We use the information we collect to improve and personalize Services
        and to develop new services and benefits.
      </p>
      <p>
        Corporate Affiliates and Corporate Restructuring. &nbsp;We may share some or all of your Personal Information with our
        corporate affiliates, including in connection with or during negotiation of any merger, financing, acquisition or
        dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our
        business or assets. &nbsp;In the event of an insolvency, bankruptcy, or receivership, we may transfer your Personal
        Information as a business asset. If another company acquires our company, business, or assets, that company will possess
        the information we collected and will assume the rights and obligations regarding your Personal Information as described
        in this Privacy Policy.
      </p>
      <p>
        For legal purposes.&nbsp;We may preserve or disclose Personal Information about you to comply with a law, regulation,
        legal process, or governmental request; to assert legal rights, in response to a subpoena, or defend against legal claims;
        or to prevent, detect, or investigate illegal activity, fraud, abuse, violations of our terms, or threats to the security
        of the Services, violation of our Terms of Service, or the physical safety of any person.
      </p>
      <p>
        <em>
          <strong>Your Options Regarding Your Information&nbsp;</strong>
        </em>
      </p>
      <p>Opt-Out</p>
      <p>
        We may periodically send you newsletters and e-mails to the e-mail address that you provided that directly promote the use
        of our Websites or Services. &nbsp;You may indicate a preference to stop receiving further communications from us and you
        will have the opportunity to &ldquo;opt-out&rdquo; by following the unsubscribe instructions provided in the e-mail you
        receive or by contacting us directly (please see contact information below). &nbsp;Despite your indicated e-mail
        preferences, we may send you service-related communications, including notices of any updates to our Terms of Service or
        Privacy Policy. &nbsp;
      </p>
      <p>Change Your Personal Information</p>
      <p>
        All users may review, update, correct or delete the Personal Information in their user account by contacting us
        https://myokapi.com/contact-us. &nbsp;If you completely delete all of your Personal Information with us, then your user
        account will be deactivated. &nbsp;We may retain an archived copy of your records. &nbsp;
      </p>
      <p>
        <strong>
          <em>Data Retention</em>
        </strong>
      </p>
      <p>
        We retain Personal Information we collect only for as long as needed as part of the Services we provide. &nbsp;We will
        retain and use this Personal Information as necessary to comply with our legal obligations, resolve disputes, and enforce
        our agreements with users, and/or the period required by laws in applicable jurisdictions. &nbsp;
      </p>
      <p>
        <em>
          <strong>Information Security</strong>
        </em>
      </p>
      <p>
        We use a combination of technical, administrative, and physical controls designed to safeguard and help prevent the
        unauthorized access to your Personal Information. &nbsp;These controls are implemented in order to maintain the security
        of your Personal Information, including the use of encryption, physical access controls, information access controls,
        anti-virus and anti-malware software, along with managed service infrastructure support and cloud hosting from leading
        information technology and security providers, including AWS. If you have a security-related concern, please contact us.
        Always be sure to secure your login credentials for on-line access. In order to protect you and your Personal Information,
        we may suspend your use of any of the on-line service, without notice, pending an investigation, if we suspect any breach
        of security.
      </p>
      <p>
        Please be aware that, despite our best efforts, no security measures are perfect or impenetrable. &nbsp;Therefore, Okapi
        cannot guarantee or warrant the security of any Personal Information collected through the Services and cannot be
        responsible for the theft, destruction, loss or inadvertent disclosure of any Personal Information.&nbsp;
      </p>
      <p>
        <em>
          <strong>Do Not Track</strong>
        </em>
      </p>
      <p>
        The term &ldquo;Do Not Track&rdquo; refers to a HTTP header offered by certain web browsers to request that websites
        refrain from tracking the user. We take no action in response to Do Not Track requests. &nbsp;
      </p>
      <p>
        <em>
          <strong>CA Consumer Privacy Rights</strong>
        </em>
      </p>
      <p>
        California Civil Code Section 1798.83 (known as the &quot;Shine the Light&quot; law) requires certain businesses to
        respond to requests from California residents about Personal Information disclosed to third parties for the third
        parties&rsquo; direct marketing purposes. &nbsp;We do not disclose our customers&rsquo; Personal Information to third
        parties for third parties&rsquo; direct marketing purposes. &nbsp;In the event we do so in the future, we will not
        disclose any of your Personal Information to third parties for third parties&rsquo; direct marketing purposes unless you
        consent at the time that you provide your Personal Information. &nbsp;Please note that Okapi is not subject to the
        California Consumer Privacy Act or the California Privacy Rights Act.
      </p>
      <p>
        <em>
          <strong>Changes to this Privacy Policy</strong>
        </em>
      </p>
      <p>
        This Privacy Policy may be updated from time to time for any reason. &nbsp;Any changes to our Privacy Policy would be
        indicated by posting a newer version of the Privacy Policy and changing the &ldquo;Effective&rdquo; date above. &nbsp;You
        should consult this Privacy Policy regularly for any changes. &nbsp;
      </p>
      <p>&nbsp;</p>
    </div>
  </LandingPage>
);

export default PrivacyPolicy;
