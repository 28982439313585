import { Card } from 'antd';
import React, { FC, ReactNode } from 'react';

import Logo from '@okapi/components/Logo';

import styles from './styles.module.less';

interface Props {
  children: ReactNode;
}

const AuthWrapper: FC<Props> = ({ children }) => (
  <div className={styles.wrapper}>
    <Logo />
    <Card className={styles.card}>{children}</Card>
  </div>
);

export default AuthWrapper;
