import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import type { FC, ReactNode } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart as LineChartRecharts,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';

import { formatDate, formatNumber } from '@okapi/utils/formatting';

import { Row } from '../../types';

interface Props {
  data: Row[];
}

const UsersChart: FC<Props> = ({ data }) => {
  if (!data) {
    return null;
  }

  const formatXAxis = (date: string) => formatDate(date, 'MMM DD');

  const renderTooltip = ({ active, payload, label }: TooltipProps<number, string>): ReactNode => {
    if (active && payload && payload.length) {
      const comparedDate = dayjs(label).subtract(7, 'd').format('dddd MMM DD');
      const currentValue = payload[0].value || 0;
      const previousValue = payload[1].value || 0;
      const changeInPercent = (currentValue / previousValue) * 100 - 100;

      return (
        <div style={{ backgroundColor: 'white', border: 'solid 1px #ccc', padding: '1rem' }}>
          <div style={{ fontSize: '13px', marginBottom: '10px' }}>
            {label && formatDate(label, 'dddd MMM DD')} vs {comparedDate}
          </div>
          <div>
            Users: {formatNumber(currentValue)} vs {formatNumber(previousValue)}{' '}
            <span style={{ fontWeight: 'bold', color: changeInPercent > 0 ? 'green' : 'red' }}>
              {changeInPercent > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              {formatNumber(Math.abs(changeInPercent))}%
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ height: '250px' }}>
      <ResponsiveContainer height="100%" width="100%">
        <LineChartRecharts
          data={data}
          height={250}
          margin={{
            top: 20,
            right: 30,
            left: 10,
            bottom: 5
          }}
          width={500}
        >
          <CartesianGrid strokeDasharray="1 1" vertical={false} />
          <XAxis dataKey="name" stroke="transparent" tick={{ fill: '#666' }} tickFormatter={formatXAxis} />
          <YAxis stroke="transparent" tick={{ fill: '#666' }} width={30} />
          <Tooltip content={renderTooltip} />
          <Line activeDot={{ r: 8 }} dataKey="value" stroke="#2C69B3" strokeWidth={2} />
          <Line activeDot={{ r: 4 }} dataKey="previousValue" dot={false} stroke="#2C69B3" strokeDasharray="5 5" />
        </LineChartRecharts>
      </ResponsiveContainer>
    </div>
  );
};

export default UsersChart;
