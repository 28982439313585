import { Card } from 'antd';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import Users, { Role } from '@okapi/domains/Users';

import useAccount from '../Auth/hooks/useAccount';

const Teachers: FC = () => {
  const { unitId } = useAccount();

  return (
    <>
      <Helmet>
        <title>Teachers</title>
      </Helmet>
      <Card title="Teachers">
        <Users role={Role.TEACHER} unitId={unitId} />
      </Card>
    </>
  );
};

export default Teachers;
