import { Form, Input, Typography } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import useApp from '@okapi/App/hooks/useApp';
import FormButtons from '@okapi/components/Form/FormButtons';

import styles from './styles.module.less';
import { validationRules } from './validationRules';

interface Props {
  onSubmit: (values: Store) => void;
}

const LoginForm: FC<Props> = ({ onSubmit }) => {
  const { isDemoVersion } = useApp();

  const initialValues = isDemoVersion ? { email: 'preview@okapi-digital-literacy.com', password: 'demo' } : undefined;
  const ignoreAttribute = isDemoVersion ? { autocomplete: 'off', 'data-lpignore': true } : {};

  return (
    <Form initialValues={initialValues} onFinish={onSubmit}>
      <Typography.Title className={styles.headline} level={4}>
        {isDemoVersion ? 'Welcome to Okapi Digital Literacy®' : 'Log in'}
      </Typography.Title>
      {isDemoVersion && (
        <p>
          We have created an auto-login for you below. <br />
          Simply click “Login” to begin.
        </p>
      )}
      <Form.Item name="email" rules={validationRules.email} validateTrigger="onBlur">
        <Input placeholder="Email" size="large" {...ignoreAttribute} />
      </Form.Item>
      <Form.Item name="password" rules={validationRules.password}>
        <Input.Password placeholder="Password" size="large" {...ignoreAttribute} />
      </Form.Item>
      <FormButtons text="Log in">{!isDemoVersion && <Link to="/forgot-password">Forgot password?</Link>}</FormButtons>
    </Form>
  );
};

export default LoginForm;
