import { App, Card, Tabs } from 'antd';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';

import Alert from '@okapi/components/Alert';
import QueryHandler from '@okapi/components/QueryHandler';
import Subscriptions from '@okapi/domains/Subscriptions';
import Users from '@okapi/domains/Users';
import { Role, UpdateDistrictInput } from '@okapi/graphql/types.generated';

import DistrictForm from '../DistrictForm';
import { useDistrictQuery, useUpdateDistrictMutation } from '../graphql';

interface UrlParams {
  unitId: string;
}

const EditDistrict: FC = () => {
  const { notification } = App.useApp();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { unitId } = useParams<{ unitId: string }>() as UrlParams;
  const { data, error, loading } = useDistrictQuery({ variables: { id: unitId }, fetchPolicy: 'cache-and-network' });
  const [updateDistrict] = useUpdateDistrictMutation();
  const navigate = useNavigate();

  const district = data?.district;

  const handleSubmit = async (values: UpdateDistrictInput) => {
    try {
      setErrorMessage('');

      await updateDistrict({ variables: { district: values } });

      notification.success({ message: 'Success', description: 'District has been updated.' });

      navigate('/districts');
    } catch (err) {
      setErrorMessage('Something went wrong.');
    }
  };

  const handleCancel = () => navigate('/districts');

  const items = [
    {
      label: 'General information',
      key: 'general-information',
      children: (
        <>
          {errorMessage && <Alert message={errorMessage} type="error" />}
          <DistrictForm initialValues={district} onCancel={handleCancel} onSubmit={handleSubmit} />
        </>
      )
    },
    {
      key: 'subscriptions',
      label: 'Subscriptions',
      children: <Subscriptions unitId={unitId} />
    },
    {
      label: 'Admins',
      key: 'admins',
      children: <Users role={Role.DISTRICT_ADMIN} unitId={unitId} />
    }
  ];

  return (
    <>
      <Helmet>
        <title>Edit district</title>
      </Helmet>
      <QueryHandler data={data} error={!!error} isLoading={loading}>
        <Card title={`Edit district: ${district?.name}`}>
          <Tabs defaultActiveKey="general-information" items={items} />
          {errorMessage && <Alert message={errorMessage} type="error" />}
        </Card>
      </QueryHandler>
    </>
  );
};

export default EditDistrict;
