import { App, Modal } from 'antd';
import React, { FC, useState } from 'react';

import Alert from '@okapi/components/Alert';

import { useUpdateSubscription } from '../graphql';
import { prepareInitialValues, prepareRequestPayload } from '../helpers';
import SubscriptionForm from '../SubscriptionForm';
import { Subscription } from '../types';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  subscription: Subscription;
}

const EditSubscription: FC<Props> = ({ onClose, onSuccess, subscription }) => {
  const { notification } = App.useApp();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [updateSubscription] = useUpdateSubscription();

  const handleSubmit = async (values: Subscription) => {
    try {
      setErrorMessage('');

      await updateSubscription({
        variables: {
          subscription: {
            id: subscription.id,
            ...prepareRequestPayload(values)
          }
        },
        refetchQueries: ['subscription']
      });

      notification.success({ message: 'Success', description: 'Subscription has been updated.' });

      onSuccess();
    } catch (err: unknown) {
      setErrorMessage('Something went wrong. Please try again later.');
    }
  };

  return (
    <Modal footer={null} onCancel={onClose} open={true} title="Edit subscription">
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <SubscriptionForm initialValues={prepareInitialValues(subscription)} onCancel={onClose} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default EditSubscription;
