import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import ReportIssueLink from '../ReportIssueLink';
import styles from './styles.module.less';

const Footer: FC = () => {
  const currentYear: number = new Date().getFullYear();

  return (
    <div className={styles.footer}>
      <div>
        Copyright &copy; {currentYear}{' '}
        <a href="https://myokapi.com" target="_blank">
          Okapi Educational Publishing
        </a>
      </div>
      <div className={styles.legals}>
        <Link target="_blank" to="/privacy-policy">
          Privacy Policy
        </Link>
        <Link target="_blank" to="/terms-of-use">
          Terms of Use
        </Link>
      </div>
      <div className={styles.reportIssue}>
        <ReportIssueLink />
      </div>
    </div>
  );
};

export default Footer;
