import { Result, Typography } from 'antd';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

import BookViewer from '@okapi/components/BookViewer/BookViewer';
import DownloadLink from '@okapi/components/DownloadLink';
import ScrollToTop from '@okapi/components/ScrollToTop';
import useTracking from '@okapi/useTracking';

import { ResourcesTrackingCategory } from '../../types';
import { getRawResourceUrl, getResources, getResourceUrl } from '../Phonics/helpers';
import styles from './styles.module.less';

const PhonicsDetails: FC = () => {
  const { id } = useParams();
  const { trackEvent } = useTracking();

  const resource = getResources().find(({ isbn }) => isbn === id);

  useEffect(() => {
    if (resource) {
      trackEvent({
        category: ResourcesTrackingCategory,
        action: 'View Phonics',
        label: resource.title
      });
    }
  }, [resource, trackEvent]);

  if (!resource) {
    return <Result status={404} subTitle="Ups! Sorry, We can not find the page you're looking for." title="404" />;
  }

  return (
    <div className={styles.wrapper}>
      <ScrollToTop />
      <Helmet>
        <title>{resource.title}</title>
      </Helmet>
      <div className={styles.header}>
        <Typography.Title level={3}>{resource.title}</Typography.Title>
        <div className={styles.links}>
          <DownloadLink url={getRawResourceUrl(resource.asset)} />
        </div>
      </div>
      <BookViewer title={resource.title} url={getResourceUrl(resource.asset)} />
    </div>
  );
};

export default PhonicsDetails;
