import { Divider, Popconfirm, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { Link } from 'react-router-dom';

import { District } from '@okapi/graphql/types.generated';

interface Params {
  onActivate: (id: string) => void;
  onDeactivate: (id: string) => void;
}

export const getColumns = ({ onActivate, onDeactivate }: Params): ColumnsType<District> => [
  {
    title: 'District name',
    dataIndex: 'name'
  },
  {
    title: 'Status',
    dataIndex: 'active',
    render: (active) => {
      if (!active) {
        return <Tag color="volcano">inactive</Tag>;
      }

      return <Tag color="green">active</Tag>;
    }
  },
  {
    title: 'Actions',
    align: 'right',
    key: 'actions',
    fixed: 'right',
    width: 160,
    render: ({ active, id }) => {
      if (!active) {
        return (
          <Popconfirm
            cancelText="No"
            okText="Yes"
            onConfirm={() => onActivate(id)}
            placement="topLeft"
            title="Are you sure you want to activate this district?"
          >
            <Typography.Link>Activate</Typography.Link>
          </Popconfirm>
        );
      }

      return (
        <>
          <Link to={`/districts/${id}`}>Edit</Link>
          <Divider type="vertical" />
          <Popconfirm
            cancelText="No"
            okText="Yes"
            onConfirm={() => onDeactivate(id)}
            placement="topLeft"
            title="Are you sure you want to deactivate this district?"
          >
            <Typography.Link>Deactivate</Typography.Link>
          </Popconfirm>
        </>
      );
    }
  }
];
