import { Typography } from 'antd';
import React, { FC, useState } from 'react';

import ReportIssueModal from './ReportIssueModal';

const ReportIssueLink: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const toggleModal = (): void => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <>
      <Typography.Link onClick={toggleModal}>Report an Issue</Typography.Link>
      <ReportIssueModal isVisible={isModalVisible} onClose={toggleModal} />
    </>
  );
};

export default ReportIssueLink;
