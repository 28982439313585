import { Card, Table, Typography } from 'antd';
import { FC, useState } from 'react';
import { useSessionStorage } from 'react-use';

import Pagination from '@okapi/components/Pagination';
import QueryHandler from '@okapi/components/QueryHandler';
import { BooksFilters } from '@okapi/graphql/types.generated';
import { getPaginationParams } from '@okapi/utils/pagination';

import { useResourceFiltersQuery } from '../../graphql/resourceFilters.generated';
import { useResourcesQuery } from '../../graphql/resources.generated';
import Filters from '../Filters/Filters';
import { prepareFilters } from '../helpers';
import styles from './styles.module.less';
import { getColumns } from './tableConfig';

export const initialFilters: BooksFilters = {
  series: [],
  stage: []
};

const LessonPlans: FC = () => {
  const [page, setPage] = useSessionStorage<number>('lesson-plans-resource-page', 1);
  const [appliedFilters, setAppliedFilters] = useState<BooksFilters>(initialFilters);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { data, loading, error } = useResourcesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filters: { bookType: [2], ...appliedFilters, searchTerm },
      ...getPaginationParams(page),
      orderBy: ['series.id', 'stage.order', 'title']
    }
  });
  const { data: filtersData } = useResourceFiltersQuery();

  const resources = data?.books?.nodes || [];
  const totalCount = data?.books.totalCount || 0;
  const filterOptions = prepareFilters(filtersData);

  const handleChange = (value: BooksFilters): void => {
    setAppliedFilters({ ...appliedFilters, ...value });
    setPage(1);
  };

  const handleClearAll = (): void => {
    setAppliedFilters(initialFilters);
    setPage(1);
  };

  return (
    <div className={styles.wrapper} data-testid="lesson-plans">
      <div className={styles.headline}>
        <Typography.Title level={3}>Welcome to the Lesson Plans!</Typography.Title>
        <div>
          Instructional support available for downloading. Search by book title, or use the Filter tool on the right to sort by
          program and/or stage.
        </div>
      </div>

      <Card>
        <Filters
          filterOptions={filterOptions}
          initialFilters={initialFilters}
          onClearAll={handleClearAll}
          onFilterChange={handleChange}
          onSearch={setSearchTerm}
        />
        <QueryHandler data={resources} error={!!error} isLoading={loading}>
          <Table
            columns={getColumns()}
            dataSource={resources}
            pagination={false}
            rowKey="id"
            scroll={{ x: 700 }}
            size="middle"
            tableLayout="auto"
          />
          <Pagination currentPage={page} onChange={setPage} totalCount={totalCount} />
        </QueryHandler>
      </Card>
    </div>
  );
};

export default LessonPlans;
