import { App, Modal } from 'antd';
import { get } from 'lodash';
import { FC, useState } from 'react';

import Alert from '@okapi/components/Alert';
import useAccount from '@okapi/domains/Auth/hooks/useAccount';
import { useUpdateUserMutation } from '@okapi/domains/Users/graphql';
import { User } from '@okapi/graphql/types.generated';

import { prepareInitialValues } from '../helpers';
import UserForm from '../UserForm';
import { UserFormValues } from '../UserForm/types';
import { preparePayload } from './helpers';

interface Props {
  user: User;
  onClose: () => void;
  onSuccess: () => void;
}

const EditUser: FC<Props> = ({ user, onClose, onSuccess }) => {
  const { notification } = App.useApp();
  const { unitId: adminUnitId } = useAccount();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [updateUser] = useUpdateUserMutation();

  const handleSubmit = async (values: UserFormValues) => {
    try {
      setErrorMessage('');

      await updateUser({
        variables: { user: preparePayload(values, user?.id || '', adminUnitId) },
        refetchQueries: ['allUsers']
      });

      notification.success({ message: 'Success', description: 'User has been updated.' });

      onSuccess();
    } catch (err) {
      setErrorMessage(get(err, 'message', 'Something went wrong.'));
    }
  };

  return (
    <Modal footer={null} onCancel={onClose} open={true} title="Edit user">
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <UserForm initialValues={prepareInitialValues(user)} onCancel={onClose} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default EditUser;
