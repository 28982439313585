import { Card, Typography } from 'antd';
import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import Logo from '../Logo';
import styles from './styles.module.less';

interface Props {
  children: ReactNode;
  title: string;
}

const LandingPage: FC<Props> = ({ children, title }) => (
  <div className={styles.wrapper}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Logo className={styles.logo} />
    <Card className={styles.pageWrapper}>
      <Typography.Title level={1}>{title}</Typography.Title>
      {children}
    </Card>
  </div>
);

export default LandingPage;
