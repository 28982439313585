import React, { FC } from 'react';

import { ReactComponent as LogoComponent } from './logo.svg';

interface Props {
  className?: string;
  size?: 'small' | 'medium';
}

const Logo: FC<Props> = ({ className, size = 'medium' }) => {
  const width = size === 'medium' ? 140 : 110;

  return <LogoComponent className={className} style={{ width }} />;
};

export default Logo;
