import { App, Modal } from 'antd';
import { startCase } from 'lodash';
import React, { FC, useState } from 'react';

import Alert from '@okapi/components/Alert';
import QueryHandler from '@okapi/components/QueryHandler';
import { useUpdateUserMutation, useUserQuery } from '@okapi/domains/Users/graphql';
import { UpdateUserInput } from '@okapi/graphql/types.generated';

import { userRoleLabelMapping } from '../helpers';
import { Role } from '../types';
import UserForm from '../UserForm';

interface Props {
  role: Role;
  userId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const EditUser: FC<Props> = ({ userId, onClose, onSuccess, role }) => {
  const { notification } = App.useApp();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { data, error, loading } = useUserQuery({ variables: { id: userId }, fetchPolicy: 'cache-and-network' });
  const [updateUser] = useUpdateUserMutation();
  const userLabel: string = userRoleLabelMapping[role];

  const user = data?.user;

  const handleSubmit = async ({ loginPath, ...rest }: UpdateUserInput) => {
    try {
      setErrorMessage('');

      await updateUser({ variables: { user: { ...rest, loginPath: loginPath || null } }, refetchQueries: ['users'] });

      notification.success({ message: 'Success', description: `${startCase(userLabel)} has been updated.` });

      onSuccess();
    } catch (err) {
      setErrorMessage('Something went wrong.');
    }
  };

  return (
    <Modal footer={null} onCancel={onClose} open={true} title={`Edit ${userLabel}`}>
      <QueryHandler data={user} error={!!error} isLoading={loading}>
        {errorMessage && <Alert message={errorMessage} type="error" />}
        <UserForm initialValues={user} onCancel={onClose} onSubmit={handleSubmit} role={role} />
      </QueryHandler>
    </Modal>
  );
};

export default EditUser;
