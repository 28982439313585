import { Card, Table, Typography } from 'antd';
import type { FC } from 'react';

import { getResources } from './helpers';
import styles from './styles.module.less';
import { getColumns } from './tableConfig';

const Phonics: FC = () => (
  <div className={styles.wrapper} data-testid="phonics">
    <div className={styles.headline}>
      <Typography.Title level={3}>Welcome to the Flying Start to Literacy: PHONICS!</Typography.Title>
      <div>Printable rhymes, practice sentences, word-picture cards, and assessments to augment your phonics instruction.</div>
    </div>

    <Card>
      <Table
        columns={getColumns()}
        dataSource={getResources()}
        pagination={false}
        rowKey="isbn"
        scroll={{ x: 700 }}
        size="small"
        tableLayout="auto"
      />
    </Card>
  </div>
);

export default Phonics;
