import { Button, Col, DatePicker, Form, Row, Table } from 'antd';
import { Key } from 'antd/lib/table/interface';
import React, { FC, useEffect, useState } from 'react';

import { FormButtons } from '@okapi/components/Form';

import styles from './styles.module.less';
import { getColumns, getData } from './tableConfig';
import { validationRules } from './validationRules';

interface Props {
  initialValues?: any;
  onCancel: () => void;
  onSubmit: (values: any) => void;
}

const SubscriptionForm: FC<Props> = ({ initialValues, onCancel, onSubmit }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState(initialValues.items);

  useEffect(() => {
    setSelectedRowKeys(initialValues.items);
  }, [initialValues]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRows: Key[]): void => {
      setSelectedRowKeys(selectedRows);
    }
  };

  const handleSubmit = (values: any) => {
    onSubmit({ ...values, items: selectedRowKeys });
  };

  return (
    <Form className={styles.wrapper} initialValues={initialValues} layout="vertical" onFinish={handleSubmit}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="From" name="from" rules={validationRules.from}>
            <DatePicker size="large" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="To" name="to" rules={validationRules.to}>
            <DatePicker size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Table
        className={styles.table}
        columns={getColumns()}
        dataSource={getData()}
        pagination={{ hideOnSinglePage: true }}
        rowSelection={{ ...rowSelection, checkStrictly: false }}
        size="small"
      />
      <FormButtons>
        <Button onClick={onCancel}>Cancel</Button>
      </FormButtons>
    </Form>
  );
};

export default SubscriptionForm;
