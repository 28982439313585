import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ActivateDistrictMutationVariables = GraphQLTypes.Exact<{
  unitId: GraphQLTypes.Scalars['String']['input'];
}>;

export type ActivateDistrictMutationResult = { __typename?: 'Mutation' } & Pick<GraphQLTypes.Mutation, 'activateUnit'>;

export const ActivateDistrictDocument = /* #__PURE__ */ gql`
  mutation activateDistrict($unitId: String!) {
    activateUnit(unitId: $unitId)
  }
`;

/**
 * __useActivateDistrictMutation__
 *
 * To run a mutation, you first call `useActivateDistrictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateDistrictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateDistrictMutation, { data, loading, error }] = useActivateDistrictMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useActivateDistrictMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateDistrictMutationResult, ActivateDistrictMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ActivateDistrictMutationResult, ActivateDistrictMutationVariables>(ActivateDistrictDocument, options);
}
export type ActivateDistrictMutationHookResult = ReturnType<typeof useActivateDistrictMutation>;
