import { Filters } from '@okapi/graphql/types.generated';
import { Option } from '@okapi/types';

export interface FilterConfig {
  isMultiSelect?: boolean;
  name: string;
  label: string;
  options: any[];
  width?: number;
}

interface FilterOption {
  id: number;
  value: string;
}

interface GroupOption {
  label: string;
  options: Option & { id: string }[];
}

const hasAtLeastTwoOptions = (filter: FilterConfig): boolean => filter.options.length > 1;

const mapFilterOptions = (filterOptions: FilterOption[]): Option<number>[] =>
  filterOptions.map(({ id, value }) => ({ label: value, value: id }));

const mapLevelOptions = (filterOptions: FilterOption[]): Option<number>[] =>
  filterOptions.map(({ id, value }) => {
    if (value === 'PRE_K') {
      return {
        label: 'Shared',
        value: id,
        style: { width: '82px' }
      };
    }

    return { label: value, value: id };
  });

const mapCurriculumConnectionOptions = (filterOptions: FilterOption[]): GroupOption[] => {
  const groupOptions: any = {};

  filterOptions.forEach(({ id, value }) => {
    const [category, subCategory] = value.split(':');
    const option = { id, value: id, label: subCategory.trim() };
    const options = groupOptions[category];

    groupOptions[category] = options ? [...options, option] : [option];
  });

  return Object.keys(groupOptions).map((groupOption) => ({
    label: groupOption,
    options: groupOptions[groupOption]
  }));
};

export const prepareFilters = (filters: Filters): FilterConfig[] => {
  const { curriculumConnections, languages, levels, series, stages } = filters;

  const filtersConfig: FilterConfig[] = [
    {
      name: 'series',
      label: 'Series',
      options: mapFilterOptions(series)
    },
    {
      name: 'level',
      label: 'Guided Reading Level',
      options: mapLevelOptions(levels)
    },
    {
      isMultiSelect: true,
      name: 'stage',
      label: 'Stage',
      options: mapFilterOptions(stages)
    },
    {
      isMultiSelect: true,
      name: 'curriculumConnection',
      label: 'Curriculum Connection',
      options: mapCurriculumConnectionOptions(curriculumConnections),
      width: 300
    },
    {
      name: 'language',
      label: 'Language',
      options: mapFilterOptions(languages)
    }
  ];

  return filtersConfig.filter(hasAtLeastTwoOptions);
};
