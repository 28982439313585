import { App, Card } from 'antd';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';

import Alert from '@okapi/components/Alert';
import useAccount from '@okapi/domains/Auth/hooks/useAccount';
import { CreateSchoolInput, Role } from '@okapi/graphql/types.generated';

import { useCreateSchoolMutation } from '../graphql';
import SchoolForm from '../SchoolForm';

const AddSchool: FC = () => {
  const { notification } = App.useApp();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { role, unitId } = useAccount();
  const navigate = useNavigate();
  const [createSchool] = useCreateSchoolMutation();
  const initialValues = role === Role.DISTRICT_ADMIN ? { districtId: unitId } : undefined;

  const handleSubmit = async (school: CreateSchoolInput) => {
    try {
      setErrorMessage('');

      await createSchool({ variables: { school } });

      notification.success({ message: 'Success', description: 'New school has been added.' });

      navigate('/schools');
    } catch (err) {
      setErrorMessage('Something went wrong.');
    }
  };

  const handleClose = () => navigate('/schools');

  return (
    <>
      <Helmet>
        <title>Add school</title>
      </Helmet>
      <Card title="Add a new school">
        {errorMessage && <Alert message={errorMessage} type="error" />}
        <SchoolForm initialValues={initialValues} onCancel={handleClose} onSubmit={handleSubmit} />
      </Card>
    </>
  );
};

export default AddSchool;
