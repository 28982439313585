import { App } from 'antd';
import React, { FC, ReactNode, useMemo } from 'react';

import { staticConfig } from '@okapi/utils/staticConfig';

import { AppContext } from '../AppContext';

interface Props {
  children: ReactNode;
}

const AppProvider: FC<Props> = ({ children }) => {
  const config = staticConfig();

  const context = useMemo(
    () => ({
      isDemoVersion: JSON.parse(config.isDemoVersion)
    }),
    [config.isDemoVersion]
  );

  return (
    <App>
      <AppContext.Provider value={context}>{children}</AppContext.Provider>
    </App>
  );
};

export default AppProvider;
